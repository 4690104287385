// an error dialog with a dismiss button

import React from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { red } from "@mui/material/colors";

interface ErrorDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  message: string;
}

const ErrorDialog: React.FC<ErrorDialogProps> = (props: ErrorDialogProps) => {
  const { open, setOpen, title, message } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Alert severity="error">
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Dialog>
  );
};

export default ErrorDialog;

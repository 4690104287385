import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type StageProgressionAlertDialogProps = {
  open: boolean;
  handleClickProceed: () => void;
  handleClose: () => void;
  targetShip: string;
};

//TODO - this is pretty much a duplicate of AlertDialogSlide, consider refactoring in the future if
// the behaviours of the 2 components do not diverge.
export function StageProgressionAlertDialog(
  props: StageProgressionAlertDialogProps
) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            variant="h6"
            fontWeight={"bold"}
            sx={{ color: "warning.main" }}
          >
            {`Target (${props.targetShip}) has unsaved Report Details`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <p>
              You have unsaved changes to target {props.targetShip}'s report
              details..
            </p>
            <p>
              Do you want to save changes and proceed to the intentions portion
              of the answers?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Back</Button>
          <Button color="primary" onClick={props.handleClickProceed}>
            Save and Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Dialog, Paper, TableContainer, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const RootTable = styled('table')({
  backgroundColor: 'black',
  color: 'white',
  flex: 1,
  width: '100%',
});



const TableHeader = styled('th')({
  backgroundColor: '#black',
  color: '#white',
  fontWeight: 'bold',
  borderBottom: '1px solid #333',
});

const TableCell = styled('td')({
  backgroundColor: '#black',
  color: '#white',
  borderBottom: '1px solid #333',
});

const TransparentPaper = styled(Paper)  ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
});

const TransparentPaperWithShadow = styled(Paper)  ({
    backgroundColor: 'transparent',
    boxShadow: '10',
});

const CenteredDialog = styled(Dialog)(({ theme }) => ({
  top: '80px',
}));


export { RootTable, TableHeader, TableCell, TransparentPaper, TransparentPaperWithShadow, CenteredDialog};
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';

interface StreakScoreProps {
  score: number | null;
}

const StreakScoreDisplay: React.FC<StreakScoreProps> = ({ score }) => {
  if (score) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'flex-end', marginLeft: '10px' }}
      >
        <Tooltip title='Streak Score' placement='top'>
          <IconButton>
            <LocalFireDepartmentTwoToneIcon
              sx={{
                color: '#E35230ff',
                fontSize: '1em',
                lineHeight: '0px'
              }}
            />
          </IconButton>
        </Tooltip>
        <span
          style={{
            lineHeight: '40px',
            fontSize: '1em',
            textWrap: 'nowrap'
          }}
        >
          <strong>{score} Days</strong>
        </span>
      </div>
    );
  } else {
    return null;
  }
};

export default StreakScoreDisplay;

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import Classes from './Classes';
import Users from './Users';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function App() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className='main-content'>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} padding={2} >
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Classes" {...a11yProps(0)} />
            <Tab label="Users" {...a11yProps(1)} />
            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Classes />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Users />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel>
      </ThemeProvider>
    </div>
  );
}

import { Grid, IconButton, useMediaQuery, Box, Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { TransparentPaperWithShadow } from '../../styles';
import Quickstart from './quickstart';
import CreateScenario from './createScenario';
import { getScenario, sendScenarioAnswer } from '../../clients/rotr-client';
import { Answer } from './answer';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { bool } from 'aws-sdk/clients/signer';
import ErrorDialog from '../common/ErrorDialog';
import {
  Intentions,
  ReportDetails,
  SavedReport,
  ScenarioAnswer,
} from './common';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import * as amplitude from '@amplitude/analytics-browser';

type MessageData = {
  context?: string; // Define the type of 'context' if known, or leave it as 'any' for now
  [key: string]: any; // This allows for other properties on the event.data object
};

interface ParamsType {
  [key: string]: string | undefined;
  paramID?: string;
  trafficLvl?: string;
  env?: string;
}
const Practice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { paramID, trafficLvl, env } = useParams<ParamsType>();
  const [paramsUsed, setParamsUsed] = useState(false);
  const [message, setMessage] = useState<MessageData | null>(null);
  const [iframeClassname, setIframeClassname] = useState('iframe-hidden');
  const [showScenarioViewCloseButton, setShowScenarioViewCloseButton] =
    useState(false);
  const [gridDisplay, setGridDisplay] = useState('');
  const [iframeKey, setIframeKey] = useState(0); //used to force iframe reload
  const [selectedContext, setSelectedContext] = useState('');
  const [selectedTrafficDensity, setSelectedTrafficDensity] = useState(0);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [openAnswers, setOpenAnswers] = useState(false);
  const [scenarioId, setScenarioId] = useState('');
  const [scenarioTargets, setScenarioTargets] = useState<string[]>([]);
  const [errorDialogTitle, setErrorDialogTitle] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');
  const [disableUnloadWarning, setDisableUnloadWarning] = useState(false);
  const [resVis, setResVis] = useState(false);
  const [isParentReadySent, setIsParentReadySent] = useState(false);
  console.log("initialising Practice component...")
  // need second variable to track if the scenario viewer is ready because useState changes are batched and not immediate
  // but local variables may be initialised multiple times due to REACt debug hooks
  let isScenarioViewerReady = false; 


  const handleSetOpenErrorDialog = (open: boolean) => {
    setOpenErrorDialog(open);
  };

  const smallScreen = useMediaQuery('(max-width: 599px)');
  useEffect(() => {
    if (paramID && !paramsUsed) {
      setIsLoading(true);
    }
  }, []);
  // post a message to the iframe
  const iframeRef = useRef<HTMLIFrameElement>(null);
  let targetOrigin = process.env.REACT_APP_URL || 'localhost:3000';

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const StartRandomScenario = async () => {
    amplitude.track('Generate Random Scenario');
    await StartScenario({}, false);
  };

  const StartUserSelectedScenario = async () => {
    const contextOptionsAbbrev: Record<string, string> = {
      'Open water': 'OW',
      TSS: 'TSS',
      'Narrow Channel': 'NC',
    };
    const environmentOptionsAbbrev: Record<string, string> = {
      Day: 'day',
      Night: 'night',
      'Res Vis': 'resVis',
    };

    const scenarioSettings = {
      scenario_context: contextOptionsAbbrev[selectedContext],
      scenario_environment: environmentOptionsAbbrev[selectedEnvironment],
      traffic_level: selectedTrafficDensity,
    };
    amplitude.track('Generate Custom Scenario');
    await StartScenario(scenarioSettings, false);
  };

  const showScenarioViewer = (show: bool) => {
    if (show) {
      setIframeClassname('iframe-fullscreen');
      setGridDisplay('none');
      setShowScenarioViewCloseButton(true);
    } else {
      setIframeClassname('iframe-hidden');
      setGridDisplay('');
      setShowScenarioViewCloseButton(false);
    }
  };

  const StartScenario = async (scenarioSettings: any, parentReady: boolean) => {
    try {
      setIsLoading(true);
      const scenarioDetails = await getScenario(scenarioSettings);

      const targets = new Array<string>();
      scenarioDetails.data?.genData?.genShipsAfloat?.forEach(
        (ship: { name?: string }) => {
          if (ship.name) {
            targets.push(ship.name);
          }
        }
      );
      setScenarioTargets(targets.sort((a, b) => a.localeCompare(b)));
      console.log(scenarioDetails);
      console.log(
        'new scenario Id: ' + scenarioDetails.data.genData.scenario_id
      );
      setScenarioId(scenarioDetails.data.genData.scenario_id);
      if (scenarioDetails.data.genData.env === 'resVis') setResVis(true);
      else setResVis(false);

      let attempts = 0;
      const maxAttempts = 1000;
      const attemptInterval = 100; // milliseconds

      while ((!isScenarioViewerReady && !isParentReadySent) && !parentReady && attempts < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, attemptInterval));
        console.log(`waiting for parent ready, attempt ${attempts}, isParentReadySent: ${isParentReadySent}, isScenarioViewerReady: ${isScenarioViewerReady}, parentReady: ${parentReady}`);
        attempts++;
      }


      console.log(`waiting for parent ready, attempt ${attempts}, isParentReadySent: ${isParentReadySent}, parentReady: ${parentReady}`);
      if (attempts >= maxAttempts) {
        throw new Error(
          'Error starting scenario, please try again or contact us for support.'
        );
      }

      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        console.log(
          Date.now(),
          'posting scenarioDetails data to scenario viewer...'
        );
        iframe.contentWindow.postMessage(
          scenarioDetails.data.genData,
          targetOrigin
        );
        showScenarioViewer(true);
      } else {
        console.log("didn't send message");
      }
    } catch (error: any) {
      setErrorDialogTitle('Error starting scenario');
      setErrorDialogMessage(error.response?.data?.error || error.message);
      setOpenErrorDialog(true);
    }
    setIsLoading(false);
  };

  const submitAnswer = async (answer: ScenarioAnswer) => {
    try {
      amplitude.track('Submit Scenario Response');
      // convert each report to the correct format
      const preparedReports: SavedReport[] = [];
      answer.reports.forEach((report) => {
        // convert the report details to the correct format
        const newReport = prepareSavedReportForSubmission(report);
        preparedReports.push(newReport);
        console.log(newReport);
      });
      answer.reports = preparedReports;

      answer.intentions = prepareIntentionsForSubmission(answer.intentions);
      prepareNumberFieldForSubmission(answer.intentions, 'crseChange');
      prepareNumberFieldForSubmission(answer.intentions, 'speedChange');

      console.log(answer);
      const resp = await sendScenarioAnswer(answer, message);
      console.log(resp);
      const id = resp.data.scenarioResponseID;
      setDisableUnloadWarning(true);
      navigate(`/feedback/${id}`);
    } catch (error: any) {
      setErrorDialogTitle('Error submitting scenario answer, please try again');
      setErrorDialogMessage(error.response?.data?.error || error.message);
      setOpenErrorDialog(true);
    }
  };

  const prepareIntentionsForSubmission = (intentions: Intentions) => {
    let newIntentions = prepareNumberFieldForSubmission(
      intentions,
      'crseChange'
    );
    newIntentions = prepareNumberFieldForSubmission(
      newIntentions,
      'speedChange'
    );
    return {
      ...newIntentions,
    };
  };

  const prepareSavedReportForSubmission = (report: SavedReport) => {
    // convert the report details to the correct format
    let newDetails = prepareNumberFieldForSubmission(
      report.details,
      'relBearingCont'
    );
    newDetails = prepareNumberFieldForSubmission(newDetails, 'range');
    newDetails = prepareNumberFieldForSubmission(newDetails, 'aspectBearing');
    newDetails = prepareNumberFieldForSubmission(newDetails, 'cpa');
    newDetails = prepareNumberFieldForSubmission(newDetails, 'tcpa');
    return {
      ...report,
      details: newDetails,
    };
  };

  const prepareNumberFieldForSubmission = (
    someObject: any,
    field: keyof any
  ) => {
    // convert the report details to the correct format
    const numberValue = Number(someObject[field]);
    // console.log(
    //   `${field} has numberValue: ${numberValue} (raw value ${details[field]}))`
    // );
    if (isNaN(numberValue) || someObject[field] === '') {
      return {
        ...someObject,
        [field]: undefined,
      };
    } else {
      return {
        ...someObject,
      };
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent<MessageData>) => {
      if (event.origin !== targetOrigin) return;

      const data = event.data;
      if (
        typeof data == 'string' &&
        data === 'viewer-ready' &&
        !isParentReadySent
      ) {
        console.log('set parent ready true');
        setIsParentReadySent(true);
        isScenarioViewerReady = true;
        iframeRef.current?.contentWindow?.postMessage(
          'parent-ready',
          targetOrigin
        );
        if (paramID && !paramsUsed) {
          StartScenario(
            {
              id: paramID,
              traffic_level: trafficLvl,
              scenario_environment: env,
            },
            true
          );
          setParamsUsed(true);
        }
      } else if (
        typeof data === 'object' &&
        data !== null &&
        'context' in data
      ) {
        // Handle other messages as before
        setMessage(data);
        setOpenAnswers(true);
      }
      console.log("isParentReadySent: " + isParentReadySent);
      console.log("isScenarioViewerReady: " + isScenarioViewerReady);
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [isParentReadySent]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!disableUnloadWarning) {
        return;
      }
      e.preventDefault();
      e.returnValue = ''; // For Chrome compatibility
    };

    const handleUnload = () => {
      if (!disableUnloadWarning) {
        return;
      }
      const confirmationMessage = 'Are you sure you want to leave this page?';
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  return (
    <div className='main-content'>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <CircularProgress />{' '}
        </div>
      ) : (
        <Grid
          container
          spacing={1}
          padding={5}
          paddingLeft={smallScreen ? 5 : 10}
          paddingRight={smallScreen ? 5 : 15}
          marginRight={0}
          display={gridDisplay}
          // bgcolor={"red"}
        >
          <Grid item xs={12} textAlign={'left'}>
            <h1> Create a ROTR Scenario </h1>
          </Grid>
          <Grid item xs={12} md={4} bgcolor={'transparent'} textAlign={'left'}>
            <Quickstart buttonClickHandler={StartRandomScenario} />
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            component={TransparentPaperWithShadow}
            bgcolor={'#161A1Dff'}
            margin={0}
            paddingRight={smallScreen ? 2 : 5}
          >
            <CreateScenario
              buttonClickHandler={StartUserSelectedScenario}
              selectedContext={selectedContext}
              selectedTrafficDensity={selectedTrafficDensity}
              selectedEnvironment={selectedEnvironment}
              scenarioContextSetter={setSelectedContext}
              trafficDensitySetter={setSelectedTrafficDensity}
              environmentSetter={setSelectedEnvironment}
            />
          </Grid>
        </Grid>
      )}
      <iframe
        className={iframeClassname}
        ref={iframeRef}
        key={iframeKey}
        src='/scenario_viewer/index.html'
        title='scenario-viewer'
      />
      {showScenarioViewCloseButton ? (
        <Box
          sx={{
            position: 'absolute',
            padding: 0,
            top: { xs: 31, sm: -26 },
            left: -20,
          }}
        >
          <IconButton
            color='error'
            size='large'
            sx={{ position: 'absolute', top: 20, left: 10 }}
            onClick={() => {
              showScenarioViewer(false);
            }}
            hidden={true}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
        </Box>
      ) : null}

      <Answer
        openDialog={openAnswers}
        setOpenDialog={setOpenAnswers}
        targets={scenarioTargets}
        scenarioId={scenarioId}
        submitAnswer={submitAnswer}
        resVis={resVis}
      />

      <ErrorDialog
        open={openErrorDialog}
        setOpen={handleSetOpenErrorDialog}
        title={errorDialogTitle}
        message={errorDialogMessage}
      />
    </div>
  );
};

export default Practice;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, TextField, Typography, Tooltip, IconButton, Snackbar, AlertColor, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { AccountCircle, InfoOutlined } from '@mui/icons-material';
import { getJSDocImplementsTags } from 'typescript';
import { GetClassDetails, GetClassesByOrgId, GetOrganisationByAdmin, RegisterUserClass } from '../../clients/rotr-client';
import { useEffect, useState } from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

type RegisterClassDialogProps = {
  open: boolean;
  userId: string;
  handleClickProceed: (classCode: string) => void;
  handleClose: () => void;
};

interface ClassModel {
  id: number;
  code: string;
  student_count: number;
  teacher_count: number;
}

//TODO - this is pretty much a duplicate of AlertDialogSlide, consider refactoring in the future if
// the behaviours of the 2 components do not diverge.
export function RegisterClassDialog(props: RegisterClassDialogProps) {
  const [organisationId, setOrganisationId] = useState('');
  const [validated, setValidated] = React.useState(false);
  const [classCode, setClassCode] = React.useState('');
  const [selectedRole, setSelectedRole] = React.useState('');
  const [helperText, setHelperText] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');
  const [availableClasses, setAvailableClasses] = React.useState<ClassModel[]>([]);

  const handleCloseError = () => setOpenSnackbar(false);

  const handleRegisterClass = async () => {
    try {
      const response = await RegisterUserClass(props.userId, classCode, selectedRole);
      console.log(response);
      props.handleClickProceed(classCode);
      setValidated(false);
      setClassCode('');
      setHelperText('');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (classCode.length > 0 && selectedRole.length > 0) {
      setValidated(true);
    }
  }, [classCode, selectedRole]);

  useEffect(() => {
    // get classes for the user
    GetOrganisationByAdmin().then((response) => {
      console.log(response);
      if (response?.organisation_id) {
        setOrganisationId(response.organisation_id);
        // get classes by org_id
        GetClassesByOrgId(response.organisation_id).then((response: ClassModel[]) => {
          setAvailableClasses(response);
        }).catch((error) => {
          console.error('Error fetching classes:', error);
          setSnackbarMsg('Error fetching classes: ' + error.message);
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        });
      }
    }).catch((error) => {
      console.error('Error fetching organisation info:', error);
      setSnackbarMsg('Error fetching organisation info: ' + error.message);
      setOpenSnackbar(true);
    });
  }, []);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography variant='h6' fontWeight={'bold'}>
            {`Register for Class`}{' '}
            <span>
              <Tooltip title='Ask your Course Officer or Lead Instructor for your Class Code'>
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='class-registration-dialog'>
            <Box display="flex" flexDirection="column" gap={2} padding={1}>
              <FormControl fullWidth>
                <InputLabel id="select-class-label">Class</InputLabel>
                <Select
                  fullWidth
                  label="Class"
                  value={classCode}
                  onChange={(event) => setClassCode(event.target.value as string)}
                >
                  {availableClasses.map((classItem) => (
                    <MenuItem key={classItem.id} value={classItem.code}>{classItem.code}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="select-role-label">Role</InputLabel>
                <Select
                  fullWidth
                  label="Role"
                  value={selectedRole}
                  onChange={(event) => setSelectedRole(event.target.value as string)}
                >
                  <MenuItem key={"student"} value={"student"}>Student</MenuItem>
                  <MenuItem key={"instructor"} value={"teacher"}>Instructor</MenuItem>
                </Select>
              </FormControl>
              {/* Select for either */}
              {/* <Button
                variant='contained'
                color='secondary'
                onClick={validateClassCode}
              >
                Validate
              </Button> */}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button
            color='primary'
            variant='contained'
            onClick={handleRegisterClass}
            disabled={!validated}
          >
            Register Class
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

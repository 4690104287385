import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Alert } from '@mui/material';
import NextQuestion from './NextQuestion';
import { postAnswer } from '../../../clients/rotr-client';
import { UUID } from 'crypto';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { max } from 'lodash';

interface Option {
  optionId: string;
  optionContent: string;
}

interface Question {
  attemptID: UUID;
  content: string;
  instruction: string;
  mediaURL: string;
  options: Option[];
  questionID: string;
  questionNumber: number;
  rules: string[];
  token: string;
  totalQuestions: number;
  type: string;
  selectLimit: number;
}

interface RenderMultipleChoiceQuestionProps {
  attemptId: UUID;
  questionId: UUID;
  currentQuestion: Question;
  currentQuestionIndex: number;
  submitted: boolean;
  setSubmitted: (value: boolean) => void;
  handleNextQuestion: () => void;
  updateScore: (value: number) => void;
  setTimerRunning: (value: boolean) => void;
}

const RenderMultipleChoiceQuestion: React.FC<
  RenderMultipleChoiceQuestionProps
> = ({
  attemptId,
  questionId,
  currentQuestion,
  currentQuestionIndex,
  submitted,
  setSubmitted,
  handleNextQuestion,
  updateScore,
  setTimerRunning
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [correctOptions, setCorrectOptions] = useState<string[]>([]);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [correctOptionsLoaded, setCorrectOptionsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setTimerRunning(true);
    setShowNextButton(false);
    setSelectedOptions([]);
    setCorrectOptions([]);
  }, [currentQuestion]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        handleSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedOptions, attemptId, questionId]);

  const handleOptionSelect = (optionId: string) => {
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
      setShowLimitAlert(false);
    } else {
      if (selectedOptions.length < currentQuestion.selectLimit) {
        setSelectedOptions([...selectedOptions, optionId]);
        setShowLimitAlert(false);
      } else {
        setShowLimitAlert(true);
      }
    }
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    setTimerRunning(false);
    const answers = currentQuestion.options.map((option) => {
      return {
        id: option.optionId,
        answer: selectedOptions.includes(option.optionId)
      };
    });
    try {
      const APIResponse = await postAnswer(attemptId, questionId, answers);
      APIResponse.responseAnswers.forEach((option: any) => {
        if (option.is_correct) {
          setCorrectOptions((prevCorrectOptions) => [
            ...prevCorrectOptions,
            option.id
          ]);
        }
      });
      setCorrectOptionsLoaded(true);
      updateScore(APIResponse.score);
      setShowNextButton(true);
    } catch (error) {
      console.error('Error submitting answer:', error);
    }
  };

  const RenderMultipleChoiceOptions = () => {
    if (!currentQuestion.options || currentQuestion.options.length === 0) {
      return (
        <Typography variant='h6' sx={{ color: 'red' }}>
          No options available for this question.
        </Typography>
      );
    }
    return (
      <Box
        sx={{
          width: '100%',
          marginTop: currentQuestion.mediaURL ? '10px' : '50px',
          textAlign: 'center',
          color: 'white'
        }}
      >
        <div>
          {showLimitAlert && (
            <Alert severity='warning' style={{ marginBottom: '20px' }}>
              You can only select {currentQuestion.selectLimit} options.
            </Alert>
          )}
          {error && <Alert severity='error'>{error}</Alert>}
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {currentQuestion.options.map((option) => (
            <Button
              key={option.optionId}
              variant='contained'
              onClick={() => handleOptionSelect(option.optionId)}
              sx={{
                backgroundColor: getButtonColor(option.optionId),
                color: 'white',
                textAlign: 'left',
                justifyContent: 'flex-start',
                padding: '12px 24px',
                borderRadius: '5px',
                marginBottom: '10px',
                width: '100%',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: getButtonHoverColor(option.optionId)
                },
                display: 'flex'
              }}
            >
              <Typography variant='body1' align='left'>
                {option.optionContent}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
    );
  };

  const getButtonColor = (optionId: string) => {
    if (submitted && correctOptionsLoaded) {
      if (correctOptions?.includes(optionId)) {
        return 'green';
      } else if (
        correctOptions.length > 0 &&
        selectedOptions.includes(optionId) &&
        !correctOptions?.includes(optionId)
      ) {
        return 'red';
      } else if (
        correctOptions.length > 0 &&
        !selectedOptions.includes(optionId) &&
        !correctOptions?.includes(optionId)
      ) {
        return '#1E1E1E';
      }
    } else {
      if (selectedOptions.includes(optionId)) {
        return {
          border: '2px solid purple',
          backgroundColor: 'transparent'
        };
      }
    }
    return '#1286A6';
  };

  const getButtonHoverColor = (optionId: string) => {
    if (selectedOptions.includes(optionId) && !submitted) {
      return {
        border: '2px solid purple',
        backgroundColor: 'transparent'
      };
    }
    return '#1286A6';
  };

  const isCorrect =
    correctOptions.length > 0 &&
    correctOptions.every((optionId) => selectedOptions.includes(optionId)) &&
    selectedOptions.length === correctOptions.length;

  return (
    <Box
      sx={{
        width: '100%',
        margin: '4px 0',
        padding: '0 12px',
        textAlign: 'left',
        color: 'white',
        position: 'relative', // Ensure relative positioning
        zIndex: 2 // High z-index to ensure it is on top
      }}
    >
      <Box
        style={{
          backgroundColor:
            isCorrect && submitted
              ? 'green'
              : submitted
              ? 'transparent'
              : 'purple',
          padding: '20px',
          borderRadius: '10px',
          marginBottom: currentQuestion.mediaURL ? '10px' : '20px' // Ensure proper spacing when there's no image
        }}
        sx={{
          zIndex: 3 // Ensure this box is on top
        }}
      >
        <Typography variant='h6' sx={{ fontSize: '1.0rem' }}>
          <strong>{currentQuestion.content}</strong>
          <span
            style={{
              color: 'lightgray',
              marginLeft: '10px',
              fontSize: '0.875rem'
            }}
          >
            Rule {currentQuestion.rules.join(', ')}
          </span>
          {isCorrect && submitted && (
            <Typography
              variant='h4'
              sx={{ color: 'white', marginTop: '10px', fontWeight: 'bold' }}
            >
              Correct! <CheckCircleTwoToneIcon />
            </Typography>
          )}
        </Typography>
      </Box>

      {currentQuestion.mediaURL && (
        <Box
          style={{
            flexGrow: 1,
            flexShrink: 1,
            minHeight: '100px',
            maxHeight: '300px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            zIndex: 1,
            overflow: 'hidden'
          }}
        >
          <img
            src={`${process.env.REACT_APP_URL}/${currentQuestion.mediaURL}`}
            alt='Question Image'
            style={{
              width: '80%',
              height: '30%',
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </Box>
      )}

      <Box
        display='flex'
        justifyContent='flex-end'
        sx={{ marginBottom: '10px', position: 'relative', zIndex: 1 }}
      >
        {!submitted && (
          <Button
            variant='contained'
            sx={{
              backgroundColor: 'purple',
              '&:hover': {
                backgroundColor: '#800080'
              },
              width: '150px',
              position: 'absolute',
              top: currentQuestion.mediaURL ? '-40px' : '0', // Adjust position based on whether there's an image
              right: '0',
              zIndex: 2
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
        {showNextButton && (
          <Box
            sx={{
              position: 'absolute',
              top: currentQuestion.mediaURL ? '-20px' : '20px', // Adjust position based on whether there's an image
              right: '0',
              zIndex: 2
            }}
          >
            <NextQuestion onClick={handleNextQuestion} />
          </Box>
        )}
      </Box>
      {RenderMultipleChoiceOptions()}
    </Box>
  );
};

export default RenderMultipleChoiceQuestion;

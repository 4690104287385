import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Agreement,
  Intentions,
  SavedReport,
  Rereport,
  alterCrseValues,
  alterSpeedValues,
} from "./common";
import DeleteIcon from "@mui/icons-material/Delete";
import NumberField from "./NumberField";

// IntentionsPanelProps with displayedIntentions, setIntentionsNumberfieldChanged, setDisplayedIntentions, setIntentions,
type IntentionsPanelProps = {
  displayedIntentions: Intentions;
  savedReports: SavedReport[];
  targets: string[];
  handleIntentionsSelectionChange: (event: any) => void;
  handleIntentionsNumberFieldChange: (event: any) => void;
  handleIntentionsNumberFieldBlur: (value: any, field: any) => void;
  // rereport handlers
  handleRereportContactSelectionChange: (event: any, index: number) => void;
  handleIntentionsRereportNumberFieldChange: (
    event: any,
    index: number
  ) => void;
  handleIntentionsRereportNumberFieldBlur: (value: any, index: number) => void;
  handleAddRereport: () => void;
  handleDeleteRereport: (index: number) => () => void;
  // pass no closer than handlers
  handleAgreementContactSelectionChange: (
    event: any,
    agreement: Agreement
  ) => void;
  handleIntentionsAgreementNumberFieldChange: (
    event: any,
    index: number
  ) => void;
  handleIntentionsAgreementNumberFieldBlur: (value: any, index: number) => void;
  handleAddAgreement: () => void;
  handleDeleteAgreement: (index: number) => () => void;
  handleSubmit: () => void;
};

export const IntentionsPanel = ({
  displayedIntentions,
  savedReports,
  targets,
  handleIntentionsSelectionChange,
  handleIntentionsNumberFieldChange,
  handleIntentionsNumberFieldBlur,
  handleRereportContactSelectionChange,
  handleIntentionsRereportNumberFieldChange,
  handleIntentionsRereportNumberFieldBlur,
  handleAddRereport,
  handleDeleteRereport,
  handleAgreementContactSelectionChange,
  handleIntentionsAgreementNumberFieldChange,
  handleIntentionsAgreementNumberFieldBlur,
  handleAddAgreement,
  handleDeleteAgreement,
  handleSubmit,
}: IntentionsPanelProps) => {
  const smallScreen = useMediaQuery("(max-width: 899px)");

  return (
    <>
      <Box
        width="100%"
        display="flex"
        // flexDirection="column"
        // justifyContent="center"
        // alignItems="center"
        // bgcolor={"lightsteelblue"} //debug colour
        overflow={"auto"}
        padding={0} // contents take up full box
        // sx={{ height: "calc((80vh - 10px)/2)" }}
        sx={{ height: "calc(97vh - 200px)" }} // 250px is the height of the dialog title and the stepper
        hidden={true}
      >
        <Grid
          container
          spacing={0}
          alignContent={smallScreen ? "flex-start" : "normal"}
        >
          {/* outer most grid container*/}
          <Grid //left panel
            item
            xs={12}
            md={4}
            textAlign={"left"}
            padding={0.5}
            // bgcolor={"green"} //debug colour
          >
            <Box
              overflow={"auto"}
              maxHeight={"100%"}
              sx={{ display: "flex", flexDirection: "column" }}
              gap={1}
              paddingBottom={2}
              // bgcolor={"lightsteelblue"} //debug colour
            >
              <h3>My intention is to...</h3>
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                gap={1}
                alignItems="center"
              >
                <Typography variant="subtitle1" gutterBottom>
                  Turn
                </Typography>
                <Box minWidth={120} maxWidth={120}>
                  <FormControl fullWidth>
                    <Select
                      value={displayedIntentions.alterCrse || ""}
                      name="alterCrse"
                      onChange={handleIntentionsSelectionChange}
                      displayEmpty
                      style={{
                        height: "40px",
                        flex: 1,
                        maxHeight: "40px",
                      }}
                    >
                      {" "}
                      <MenuItem value="" disabled>
                        <em>Select direction</em>
                      </MenuItem>
                      {alterCrseValues.map((value) => {
                        return (
                          <MenuItem value={value} dense>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Typography variant="subtitle1" gutterBottom>
                  by
                </Typography>
                <NumberField
                  id="crseChange"
                  placeholder="0"
                  value={displayedIntentions.crseChange}
                  inputProps={{ min: 0, step: 1 }}
                  onChange={handleIntentionsNumberFieldChange}
                  onBlurCapture={() =>
                    handleIntentionsNumberFieldBlur(
                      displayedIntentions.crseChange,
                      "crseChange"
                    )
                  }
                />
                &#176;
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                gap={1}
                alignItems="center"
                // bgcolor={"cyan"}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Speed
                </Typography>
                <Box minWidth={120} maxWidth={120}>
                  <FormControl fullWidth>
                    <Select
                      value={displayedIntentions.alterSpeed || ""}
                      name="alterSpeed"
                      onChange={handleIntentionsSelectionChange}
                      displayEmpty
                      style={{
                        height: "40px",
                        flex: 1,
                        maxHeight: "40px",
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Select direction</em>
                      </MenuItem>
                      {alterSpeedValues.map((value) => {
                        return (
                          <MenuItem value={value} dense>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Typography variant="subtitle1" gutterBottom>
                  by
                </Typography>
                <NumberField
                  id="speedChange" // this is used to identify the field in the event handler
                  value={displayedIntentions.speedChange}
                  placeholder="0"
                  inputProps={{ min: 0, step: 1 }}
                  onChange={handleIntentionsNumberFieldChange}
                  onBlurCapture={() =>
                    handleIntentionsNumberFieldBlur(
                      displayedIntentions.speedChange,
                      "speedChange"
                    )
                  }
                />
                Kts
              </Box>
            </Box>
          </Grid>
          <Grid // right panel
            item
            xs={12}
            md={8}
            textAlign={"left"}
            // bgcolor={"blue"} //debug colour
            display={"flex"}
            flexDirection={"row"}
            margin={0}
            padding={0}
            sx={{ flex: 1 }}
          >
            {smallScreen ? null : (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ bgcolor: "primary.light" }}
              />
            )}
            {/* box takes up rest of horizontal space */}
            <Box
              marginLeft={0}
              marginTop={0}
              padding={0}
              paddingLeft={smallScreen ? 0 : 5}
              width={"100%"}
              // bgcolor={"lightseagreen"} //debug colour
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems={"center"}
                marginTop={0}
                height={50}
                // bgcolor={"lightgoldenrodyellow"} //debug colour
              >
                <h3>Rereport</h3>
                {/* icon button using AddCircleIcon */}
                <IconButton
                  color="primary"
                  // size="large"
                  sx={{ marginLeft: "auto" }}
                  onClick={handleAddRereport}
                >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </Box>
              <Box
                paddingRight={2}
                paddingTop={0}
                overflow={"auto"}
                maxHeight={"100%"}
                //   bgcolor={"red"} //debug colour
              >
                <List>
                  {displayedIntentions.rereports.map((rereport, index) => {
                    return (
                      <>
                        <ListItem
                          style={
                            index === 0
                              ? {
                                  borderBottom: "1px solid gray",
                                  borderTop: "1px solid gray",
                                  paddingLeft: "0px",
                                }
                              : {
                                  borderBottom: "1px solid gray",
                                  // backgroundColor: "red", //debug colour
                                  paddingLeft: "0px",
                                }
                          }
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={handleDeleteRereport(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            flexWrap={"wrap"}
                            gap={1}
                            alignItems="center"
                            // bgcolor={"red"}
                          >
                            Tgt No.
                            <Box minWidth={100} maxWidth={100}>
                              <FormControl fullWidth>
                                <Select
                                  value={rereport.contact || ""}
                                  name="contactNo."
                                  onChange={(event) => {
                                    handleRereportContactSelectionChange(
                                      event,
                                      index
                                    );
                                  }}
                                  displayEmpty
                                  style={{
                                    height: "40px",
                                    flex: 1,
                                    maxHeight: "40px",
                                  }}
                                >
                                  {" "}
                                  <MenuItem value="" disabled>
                                    <em>Select Tgt</em>
                                  </MenuItem>
                                  {targets.map((value) => {
                                    // only display target if it is not already in the list
                                    if (
                                      value != rereport.contact &&
                                      (displayedIntentions.rereports.some(
                                        (rereport) => rereport.contact === value
                                      ) ||
                                        !savedReports.some(
                                          (report) => report.target === value
                                        ))
                                    ) {
                                      return null;
                                    }
                                    return (
                                      <MenuItem value={value} dense>
                                        {value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                            at
                            <NumberField
                              value={rereport.at}
                              sx={{ width: "70px", minWidth: "70px" }}
                              onChange={(event) =>
                                handleIntentionsRereportNumberFieldChange(
                                  event,
                                  index
                                )
                              }
                              onBlur={() => {
                                handleIntentionsRereportNumberFieldBlur(
                                  rereport.at,
                                  index
                                );
                              }}
                              inputProps={{ min: 0 }}
                              placeholder="0"
                            />{" "}
                            NM
                          </Box>
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems={"center"}
                marginTop={0}
                height={50}
                // bgcolor={"lightgoldenrodyellow"} //debug colour
              >
                <h3>Pass no closer than ...</h3>
                {/* icon button using AddCircleIcon */}
                <IconButton
                  color="primary"
                  size="large"
                  sx={{ marginLeft: "auto" }}
                  onClick={handleAddAgreement}
                >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </Box>
              <Box
                paddingRight={2}
                paddingTop={0}
                overflow={"auto"}
                maxHeight={"100%"}
                //   bgcolor={"red"} //debug colour
              >
                <List>
                  {displayedIntentions.agreements.map((agreement, index) => {
                    return (
                      <>
                        <ListItem
                          style={
                            index === 0
                              ? {
                                  borderBottom: "1px solid gray",
                                  borderTop: "1px solid gray",
                                  paddingLeft: "0px",
                                }
                              : {
                                  borderBottom: "1px solid gray",
                                  // backgroundColor: "red", //debug colour
                                  paddingLeft: "0px",
                                }
                          }
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={handleDeleteAgreement(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            flexWrap={"wrap"}
                            gap={1}
                            alignItems="center"
                            // bgcolor={"red"}
                          >
                            Tgt No.
                            <Box minWidth={100} maxWidth={100}>
                              <FormControl fullWidth>
                                <Select
                                  value={agreement.contact || ""}
                                  name="contactNo."
                                  onChange={(event) => {
                                    handleAgreementContactSelectionChange(
                                      event,
                                      agreement
                                    );
                                  }}
                                  displayEmpty
                                  style={{
                                    height: "40px",
                                    flex: 1,
                                    maxHeight: "40px",
                                  }}
                                >
                                  {" "}
                                  <MenuItem value="" disabled>
                                    <em>Select Tgt</em>
                                  </MenuItem>
                                  {targets.map((value) => {
                                    // only display target if it is not already in the list
                                    if (
                                      value != agreement.contact &&
                                      (displayedIntentions.agreements.some(
                                        (agreement) =>
                                          agreement.contact === value
                                      ) ||
                                        !savedReports.some(
                                          (report) => report.target === value
                                        ))
                                    ) {
                                      return null;
                                    }
                                    return (
                                      <MenuItem value={value} dense>
                                        {value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                            no closer than{" "}
                            <NumberField
                              value={agreement.noCloserThan}
                              sx={{ width: "70px", minWidth: "70px" }}
                              onChange={(event) =>
                                handleIntentionsAgreementNumberFieldChange(
                                  event,
                                  index
                                )
                              }
                              onBlur={() =>
                                handleIntentionsAgreementNumberFieldBlur(
                                  agreement.noCloserThan,
                                  index
                                )
                              }
                              inputProps={{ min: 0 }}
                              placeholder="0"
                            />{" "}
                            NM
                          </Box>
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                marginTop={2}
                marginBottom={2}
              >
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createRoot } from 'react-dom/client';
import App from './App';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1286A6',
      contrastText: '#f5f5f5',
    },
    text: {
      secondary: '#1286A6',
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          color: 'whitesmoke',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
          color: 'whitesmoke',
        },
        root: {
          color: 'whitesmoke',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

const container = document.getElementById('root') as HTMLElement;

createRoot(container).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

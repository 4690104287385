import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';

interface Ship {
  name: string;
  cpaMiles: number;
  tcpa: number;
}

interface Props {
  final_ships_afloat: Ship[];
  resultant_ships_afloat?: Ship[];
}

const ResultantsTable: React.FC<Props> = ({
  final_ships_afloat,
  resultant_ships_afloat,
}) => {
  const shipsToDisplay = resultant_ships_afloat || final_ships_afloat;
  // Sort ships in ascending order based on the target names
  const sortedShips = shipsToDisplay.sort((a, b) => {
    return parseInt(a.name) - parseInt(b.name);
  });
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tgt No.</TableCell>
            <TableCell> Resultant CPA (NM)</TableCell>
            <TableCell>Resultant TCPA (Mins)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedShips.map((ship, index) => (
            <TableRow key={index}>
              <TableCell>{ship.name}</TableCell>
              <TableCell>{ship.cpaMiles.toFixed(1)}</TableCell>
              <TableCell>{ship.tcpa.toFixed(1)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ResultantsTable;

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Typography,
  Paper,
  Box,
  IconButton,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { getBeginStep } from '../../../clients/rotr-client';
import { UUID } from 'crypto';

const BeginStep: React.FC = () => {
  const { stepId } = useParams<{ stepId?: UUID }>();
  const [stepTitle, setStepTitle] = useState('');
  const [stepDescription, setStepDescription] = useState('');
  const [instructions, setInstructions] = useState('');
  const [points, setPoints] = useState('');
  const [dateTime, setDateTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [stepCompleted, setStepCompleted] = useState(false);
  // State to hold the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Dynamic styles based on window width
  const dynamicStyles = {
    iconSize: windowWidth < 768 ? 50 : 100, // Smaller icon size for smaller screens
    boxPadding: windowWidth < 768 ? '10px' : '20px', // Smaller padding for smaller screens
    boxMarginLeft: windowWidth < 768 ? '50px' : '100px', // Adjust left margin for smaller screens
    boxMarginRight: windowWidth < 768 ? '10px' : '20px' // Adjust right margin for smaller screens
  };
  const [error, setError] = useState<string | null>(null);

  const fetchBeginStep = async (token: string | null = null, stepId?: UUID) => {
    if (stepId) {
      try {
        const response = await getBeginStep(stepId, token);
        console.log('API response:', response);
        // Assuming response contains the step details
        setStepTitle(response.step.name);
        setStepDescription(response.step.description);
        setInstructions(response.step.instructions);
        setPoints(response.highestScore);
        if (response.completionStatus.completed) {
          setStepCompleted(true);
          const localDateTime = new Date(
            response.completionStatus.completed_at
          ).toLocaleString();
          setDateTime(localDateTime);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch step details:', error);
        setError('Failed to fetch step details. Please try again.');
      }
    }
  };

  useEffect(() => {
    if (stepId) {
      fetchBeginStep(null, stepId);
    }
  }, [stepId]);

  const linkPath = `/games/shake-down/quiz/${stepId}`;
  if (isLoading) {
    return (
      <Paper
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: 'rgb(22, 26, 29)',
          padding: '0',
          overflow: 'auto',
          minHeight: '100vh'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            maxWidth: '800px',
            padding: '0 24px',
            color: 'whitesmoke',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Align items to the top
            alignItems: 'center',
            minHeight: '400px',
            backgroundColor: '#1d2125'
          }}
        >
          <CircularProgress color='primary' />
        </div>
      </Paper>
    );
  }
  return (
    <Paper
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'rgb(22, 26, 29)',
        padding: '0',
        overflow: 'auto',
        borderRadius: '0',
        flexGrow: 1
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          padding: '0 24px',
          color: 'whitesmoke',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 'auto',
          minHeight: '400px',
          backgroundColor: '#1d2125'
        }}
      >
        <Box
          className='gameHeader'
          flexGrow={0}
          flexShrink={0}
          style={{
            padding: '6px 12px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            zIndex: 1
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i style={{ verticalAlign: 'middle', marginLeft: '20px' }}></i>
            </div>
            <Link to='/games/shake-down'>
              <IconButton>
                <MenuOpenOutlinedIcon
                  style={{
                    color: 'whitesmoke',
                    fontSize: '2rem'
                  }}
                />
              </IconButton>
            </Link>
          </Box>
        </Box>
        <Box
          style={{
            flexGrow: 1,
            flexShrink: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: '20px'
          }}
        >
          {error && <Alert severity='error'>{error}</Alert>}
          {stepCompleted && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '50px',
                color: 'white'
              }}
            >
              <Typography variant='h4'>
                You have completed this step! ✅
              </Typography>
              <Box
                style={{
                  display: 'grid',
                  gridTemplateRows: 'auto auto', // Maintain rows definition
                  gap: '10px', // Maintain gap between items
                  marginTop: '10px'
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row', // Maintain row for inline elements
                    alignItems: 'center',
                    justifyContent: 'center' // Center items horizontally
                  }}
                >
                  <i
                    className='fa-solid fa-circle-bolt fa-xl'
                    style={{ marginRight: '5px' }}
                  ></i>
                  <Typography variant='body2' style={{ marginRight: '5px' }}>
                    Highest Score:
                  </Typography>
                  <Typography variant='body1'>{points}</Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row', // Maintain row for inline elements
                    alignItems: 'center',
                    justifyContent: 'center' // Center items horizontally
                  }}
                >
                  <CalendarMonthOutlinedIcon style={{ marginRight: '5px' }} />
                  <Typography variant='body2' style={{ marginRight: '5px' }}>
                    Last Attempt:
                  </Typography>
                  <Typography variant='body1'>{dateTime}</Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Box
              style={{
                backgroundColor: 'purple',
                borderRadius: '50%',
                padding: dynamicStyles.boxPadding,
                marginLeft: dynamicStyles.boxMarginLeft,
                marginRight: dynamicStyles.boxMarginRight
              }}
            >
              <SailingOutlinedIcon
                style={{ fontSize: dynamicStyles.iconSize, color: 'white' }}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Typography
                variant='body1'
                style={{
                  textAlign: 'left'
                }}
              >
                <strong>{stepTitle} </strong> -{' '}
                <span style={{ color: 'gray' }}>{stepDescription}</span>
              </Typography>
            </Box>
          </Box>
          <Typography
            variant='body1'
            style={{
              marginTop: '20px',
              textAlign: 'left',
              marginLeft: '40px',
              marginRight: '40px'
            }}
          >
            <strong>Instructions -</strong> {instructions}
          </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: '20px'
            }}
          >
            <Link
              to={linkPath}
              style={{
                textDecoration: 'none'
              }}
            >
              <Button
                variant='contained'
                style={{
                  backgroundColor: 'purple',
                  color: 'white'
                }}
              >
                START
              </Button>
            </Link>
          </Box>
        </Box>
      </div>
    </Paper>
  );
};

export default BeginStep;

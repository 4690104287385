import { Button, Grid } from '@mui/material';
import { TransparentPaper } from '../../styles';

const Quickstart = (props: any) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} md={7} padding={2} paddingBottom={0}>
          <h2 className='normal-text'>Quick Start</h2>
          <p className='normal-text'>
            {' '}
            Generate a random scenario from any rule and environment{' '}
          </p>
        </Grid>
        <Grid
          item
          xs={2}
          md={4}
          margin={1}
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          <TransparentPaper>
            <img src='/ship_white.png' width={'100%'} />
          </TransparentPaper>
        </Grid>
      </Grid>
      <Button
        variant='contained'
        onClick={props.buttonClickHandler}
        sx={{ marginBottom: 5 }}
      >
        Random Quick Start
      </Button>
    </>
  );
};

export default Quickstart;

import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

// a styled Select component with white text
const WhiteSelect = styled(Select)(({ theme }) => ({
  [`&.MuiSelect-root`]: {
    color: "white",
  },
}));

export default WhiteSelect;

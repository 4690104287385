import { PaperClassKey } from '@mui/material';
import * as paper from 'paper';

interface Ship {
  course: number;
  speed: number;
  relposXnm: number;
  relposYnm: number;
  vecEnd: paper.Point;
  position: paper.Point;
  type: string;
  vector: paper.Point;
  arrowVector: paper.Point;
  vectorItem: paper.Group;
  data?: paper.Group;
  relVecItem: paper.Group;
  targetIndicator: paper.Group;
  editIndicator: paper.Group;
  relVec: paper.Point;
  relVecEnd: paper.Point;
  relArrowVector: paper.Point;
  name: string;
  labelPos: [number, number];
  containerPos: [number, number];
}
const newOneMile: number = 10; // in pixels, generates a 12nm range scale
const ShipVctrLngth: number = 24; // in mins
let centX: number = 120;
let centY: number = 120;
const newCent: paper.Point = new paper.Point(centX, centY);

function calcVecToNewCent(oldCent: paper.Point, newCent: paper.Point) {
  const vecToNewCent = newCent.subtract(oldCent);
  return vecToNewCent;
}

function buildShips(importShips: Ship[]): Ship[] {
  const exportShips: Ship[] = [];

  importShips.forEach((importedShip, i) => {
    let newShip: Ship = { ...importedShip }; // Spread to create a shallow copy of the ship object

    if (i === 0) {
      const endX =
        Math.cos(newShip.course) * calcvecLength(newShip.speed) + centX;
      const endY =
        Math.sin(newShip.course) * calcvecLength(newShip.speed) + centY;
      newShip.vecEnd = new paper.Point(endX, endY);
      newShip.position = new paper.Point(centX, centY);
    } else {
      // Convert distance to canvas position
      const posX = newCent.x + MilesTopixels(newShip.relposXnm);
      const posY = newCent.y + MilesTopixels(newShip.relposYnm);
      newShip.position = new paper.Point(posX, posY);
      // Update vecEnd
      const endX =
        Math.cos(newShip.course) * calcvecLength(newShip.speed) + posX;
      const endY =
        Math.sin(newShip.course) * calcvecLength(newShip.speed) + posY;
      newShip.vecEnd = new paper.Point(endX, endY);
    }

    exportShips.push(newShip);
  });
  return exportShips;
}

function drawShip(ship: Ship, ownship: Ship): void {
  ship.vector = ship.vecEnd.subtract(ship.position);
  ship.arrowVector = ship.vector.normalize(10);
  // Clear previous elements
  if (ship.vectorItem) {
    ship.vectorItem.remove();
    //ship.data.remove();
    //ship.rectangle.remove();
  }
  if (ship.data) {
    ship.data.remove();
  }
  if (ship.relVecItem) {
    ship.relVecItem.remove();
  }
  if (ship.targetIndicator) {
    ship.targetIndicator.remove();
  }
  if (ship.editIndicator) {
    ship.editIndicator.remove();
  }

  // Draw elements for target
  if (ship.type != 'Own Ship') {
    // Calculate range & bearing
    const vecOwnShip = ship.position.subtract(ownship.position);
    const textAngle =
      Math.abs(vecOwnShip.angle) > 90
        ? 180 + vecOwnShip.angle
        : vecOwnShip.angle;
    // Calculate a movement relative to ownShip
    ship.relVec = ship.vector.subtract(ownship.vector);
    ship.relVecEnd = ship.position.add(ship.relVec);
    // Make dimensions for arrow elements
    ship.relArrowVector = ship.relVec.normalize(10);
    // Ship information
    ship.relVecItem = new paper.Group([
      //new Path.Circle(ownShip.position subtract( ship.vecToCPA,3),
      /* new Path([
                ship.relVecEnd.add(ship.relArrowVector.rotate(135)),
                ship.relVecEnd,
                ship.relVecEnd.add(ship.relArrowVector.rotate(-135))
            ]), */
      new paper.Path({
        segments: [[ship.position], [ship.relVecEnd]],
      }),
    ]);
    // Place tgt info
    if (ship.vector.angle < 0 && ship.vector.angle > -180)
      ship.labelPos = [ship.position.x - 10, ship.position.y + 20];
    else ship.labelPos = [ship.position.x - 10, ship.position.y - 10];

    ship.data = new paper.Group([
      new paper.PointText({
        point: ship.labelPos,
        content: ship.name, //+ ' (' + ship.type + ')',
        fillColor: 'white',
        justification: 'left',
        fontSize: 10,
      }),
    ]);
    ship.relVecItem.strokeColor = new paper.Color('grey');
    ship.relVecItem.strokeWidth = 1;
    ship.relVecItem.children[0].dashArray = [3, 2];
  }
  ship.vectorItem = new paper.Group([
    new paper.Path.Circle(ship.position, 6),
    /*         new Path([
                ship.vecEnd.add(ship.arrowVector.rotate(135)),
                ship.vecEnd,
                ship.vecEnd.add(ship.arrowVector.rotate(-135))
            ]), */
    new paper.Path.Circle({
      center: ship.position,
      radius: 3,
      fillColor: 'white',
    }),
    new paper.Path({
      segments: [[ship.position], [ship.vecEnd]],
    }),
  ]);
  //Style
  ship.vectorItem.strokeWidth = 1;
  if (ship.type == 'Own Ship') {
    ship.containerPos = [ship.position.x - 35, ship.position.y - 20];
    ship.vectorItem.strokeColor = new paper.Color('#1a9cbf');
    ship.vectorItem.children[1].fillColor = new paper.Color('#1a9cbf');
  } else ship.vectorItem.strokeColor = new paper.Color('white');
}

function calcvecLength(speed: number): number {
  const miles = (speed / 60) * ShipVctrLngth;
  const distanceInPixels = MilesTopixels(miles);
  //const roundedLength = Math.round(distanceInPixels * 100) / 100;
  return distanceInPixels;
}

function MilesTopixels(miles: number): number {
  const pixelsInMiles = Math.round(miles * newOneMile * 100) / 100;
  return pixelsInMiles;
}

function drawRR(centX: number, centY: number): void {
  const rrScale = 3;
  const centre = new paper.Point(centX, centY);
  const rangeRings = new paper.Group([
    new paper.Path.Circle(centre, newOneMile * rrScale),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 2)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 3)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 4)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 5)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 6)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 7)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 8)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 9)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 10)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 11)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 12)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 13)),
    new paper.Path.Circle(centre, newOneMile * (rrScale * 14)),
  ]);
  rangeRings.strokeWidth = 1;
  rangeRings.strokeColor = new paper.Color('#282828');
}

type Corners = paper.Point[];

interface TrafficLane {
  position: paper.Point;
  corners: Corners;
}

interface SepZone {
  position: paper.Point;
  corners: paper.Point[];
  width: number;
}

interface TSSObjectType {
  trafficLanes: {
    occupied: TrafficLane;
    other: TrafficLane;
    width: number;
  };
  sepZone: SepZone;
  paths: paper.Group;
  length: number;
  orientation: number;
}

const updateTSSPositionsDelta = (
  moveVector: paper.Point,
  TSSObject: TSSObjectType
): void => {
  // Update the positions of the occupied traffic lane
  TSSObject.trafficLanes.occupied.position =
    TSSObject.trafficLanes.occupied.position.add(moveVector);

  if (TSSObject.trafficLanes.occupied.corners) {
    TSSObject.trafficLanes.occupied.corners =
      TSSObject.trafficLanes.occupied.corners.map((corner) =>
        corner.add(moveVector)
      );
  }

  // Update the positions of the other traffic lane
  TSSObject.trafficLanes.other.position =
    TSSObject.trafficLanes.other.position.add(moveVector);
  TSSObject.trafficLanes.other.corners =
    TSSObject.trafficLanes.other.corners.map((el: paper.Point) =>
      el.add(moveVector)
    );

  // Update the positions of the separation zone
  TSSObject.sepZone.position = TSSObject.sepZone.position.add(moveVector);
  TSSObject.sepZone.corners = TSSObject.sepZone.corners.map((corner) =>
    corner.add(moveVector)
  );
};
const updateCorners = (
  newLength: number,
  newWidth: number,
  TSSObject: TSSObjectType
): void => {
  // Update the corners of the occupied traffic lane
  TSSObject.trafficLanes.occupied.corners = getRectangleCorners(
    TSSObject.trafficLanes.occupied.position,
    newLength,
    newWidth,
    TSSObject.orientation
  );

  // Update the corners of the other traffic lane
  TSSObject.trafficLanes.other.corners = getRectangleCorners(
    TSSObject.trafficLanes.other.position,
    newLength,
    newWidth,
    TSSObject.orientation
  );

  // Update the corners of the separation zone
  TSSObject.sepZone.corners = getRectangleCorners(
    TSSObject.sepZone.position,
    newLength,
    newWidth,
    TSSObject.orientation
  );
};
const updatePositionOccupied = (
  newPosition: paper.Point,
  TSSObject: TSSObjectType
): void => {
  // This function updates the position of all elements of the TSS based on a new position for the occupied traffic lane.
  TSSObject.trafficLanes.occupied.position = newPosition;
  TSSObject.trafficLanes.occupied.corners = getRectangleCorners(
    TSSObject.trafficLanes.occupied.position,
    TSSObject.length,
    TSSObject.trafficLanes.width!,
    TSSObject.orientation
  );

  // Update the position of the other traffic lane
  const deltaOtherTs = new paper.Point(0, 0);
  deltaOtherTs.angle = TSSObject.orientation - 90; // PaperJS uses degrees
  deltaOtherTs.length = TSSObject.trafficLanes.width! + TSSObject.sepZone.width;
  TSSObject.trafficLanes.other.position = newPosition.add(deltaOtherTs);
  TSSObject.trafficLanes.other.corners = getRectangleCorners(
    TSSObject.trafficLanes.other.position,
    TSSObject.length,
    TSSObject.trafficLanes.width!,
    TSSObject.orientation
  );

  // Update the position of the separation zone
  const deltaSepZone = new paper.Point(0, 0);
  deltaSepZone.angle = TSSObject.orientation - 90; // PaperJS uses degrees
  deltaSepZone.length =
    TSSObject.trafficLanes.width! / 2 + TSSObject.sepZone.width / 2;
  TSSObject.sepZone.position = newPosition.add(deltaSepZone);
  TSSObject.sepZone.corners = getRectangleCorners(
    TSSObject.sepZone.position,
    TSSObject.length,
    TSSObject.sepZone.width,
    TSSObject.orientation
  );
};
function getRectangleCorners(
  center: paper.Point,
  length: number,
  width: number,
  angle: number
): paper.Point[] {
  const halfLength = length / 2;
  const halfWidth = width / 2;

  // Calculate the position vectors for half-length and half-width
  const halfLengthVector = new paper.Point({
    length: halfLength,
    angle: angle,
  });

  const halfWidthVector = new paper.Point({
    length: halfWidth,
    angle: angle + 90,
  });

  // Calculate the positions of the four corners
  const corner1 = center.add(halfLengthVector).add(halfWidthVector);
  const corner2 = center.subtract(halfLengthVector).add(halfWidthVector);
  const corner3 = center.subtract(halfLengthVector).subtract(halfWidthVector);
  const corner4 = center.add(halfLengthVector).subtract(halfWidthVector);

  return [corner1, corner2, corner3, corner4];
}
function transformDimension(
  oldDimension: number,
  oldOneMile: number,
  newOneMile: number
): number {
  return oldDimension * (newOneMile / oldOneMile);
}

function buildTSS(
  oldCent: paper.Point,
  tss: TSSObjectType,
  oldOneMile: number
): TSSObjectType {
  const newTSS: TSSObjectType = JSON.parse(JSON.stringify(tss)); // Deep copy
  oldCent = new paper.Point(oldCent.x, oldCent.y);

  // Create new paper.Point objects for TSS positions
  newTSS.trafficLanes.occupied.position = new paper.Point(
    tss.trafficLanes.occupied.position.x,
    tss.trafficLanes.occupied.position.y
  );
  newTSS.trafficLanes.other.position = new paper.Point(
    tss.trafficLanes.other.position.x,
    tss.trafficLanes.other.position.y
  );
  newTSS.sepZone.position = new paper.Point(
    tss.sepZone.position.x,
    tss.sepZone.position.y
  );

  // Create new paper.Point objects for TSS corners
  newTSS.trafficLanes.occupied.corners = tss.trafficLanes.occupied.corners.map(
    (corner) => new paper.Point(corner.x, corner.y)
  );
  newTSS.trafficLanes.other.corners = tss.trafficLanes.other.corners.map(
    (corner) => new paper.Point(corner.x, corner.y)
  );
  newTSS.sepZone.corners = tss.sepZone.corners.map(
    (corner) => new paper.Point(corner.x, corner.y)
  );

  // Find new position for occupied lane
  const oldOccTLPos = new paper.Point(
    tss.trafficLanes.occupied.position.x,
    tss.trafficLanes.occupied.position.y
  );
  const occTlToCent = oldOccTLPos.subtract(oldCent);
  const scaledOccTlToCent = occTlToCent.multiply(newOneMile / oldOneMile);
  newTSS.trafficLanes.occupied.position = newCent.add(scaledOccTlToCent);
  // Transform dimensions of TSS
  newTSS.length = transformDimension(tss.length, oldOneMile, newOneMile);
  newTSS.sepZone.width = transformDimension(
    tss.sepZone.width,
    oldOneMile,
    newOneMile
  );
  newTSS.trafficLanes.width = transformDimension(
    tss.trafficLanes.width,
    oldOneMile,
    newOneMile
  );

  // Update position of all other elements based on the position of the occupied lane
  updatePositionOccupied(newTSS.trafficLanes.occupied.position, newTSS);
  return newTSS;
}

function drawTSS(tss: TSSObjectType) {
  // Remove the old TSS paths before drawing new ones
  if (tss.paths) {
    tss.paths.remove();
  }
  tss.paths = new paper.Group();

  const occupiedTrafficLaneBoundary = new paper.Path.Line({
    from: tss.trafficLanes.occupied.corners[0],
    to: tss.trafficLanes.occupied.corners[1],
  });

  const otherTrafficLaneBoundary = new paper.Path.Line({
    from: tss.trafficLanes.other.corners[2],
    to: tss.trafficLanes.other.corners[3],
  });

  const sepZonePath = new paper.Path({
    segments: tss.sepZone.corners,
    closed: true,
    fillColor: '#bf1a80',
    opacity: 0.75,
  });

  tss.paths.addChildren([
    occupiedTrafficLaneBoundary,
    otherTrafficLaneBoundary,
    sepZonePath,
  ]);

  occupiedTrafficLaneBoundary.strokeWidth = 1;
  occupiedTrafficLaneBoundary.dashArray = [10, 5];
  occupiedTrafficLaneBoundary.strokeColor = new paper.Color('#bf1a80');

  otherTrafficLaneBoundary.strokeWidth = 1;
  otherTrafficLaneBoundary.dashArray = [10, 5];
  otherTrafficLaneBoundary.strokeColor = new paper.Color('#bf1a80');
}

export { drawShip, buildShips, buildTSS, drawRR, drawTSS };

import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
} from '@mui/material';
import {
  GetOrganisationByAdmin,
  ListStudentsInClass,
  ListUserClasses,
} from '../../clients/rotr-client';
import FormControl from '@mui/material/FormControl';
import FeedbackSummary from '../feedbacks/feedbackSummary';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import Tasks from '../tasks/Tasks';
import { useParams } from 'react-router-dom';

const dataGridRenderCell = (params: any) => (
  <div
    style={{
      cursor: 'pointer', // Set pointer cursor for clickable behavior
      // textDecoration: "underline", // Optional: Add underline for visual indication
    }}
    onClick={() => {
      // Handle click event here
      console.log(`Clicked on ID: ${params.row.id}`);
    }}
  >
    {params.value}
  </div>
);
const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 50,
    editable: false,
    headerAlign: 'center',
  },
  {
    field: 'firstName',
    headerName: 'First name',
    flex: 0.8,
    minWidth: 70,
    renderCell: dataGridRenderCell,
    sortable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    flex: 0.8,
    minWidth: 70,
    renderCell: dataGridRenderCell,
    sortable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // editable: true,
    renderCell: dataGridRenderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'email',
    headerName: 'Email',
    description: "User's email.",
    sortable: false,
    // editable: true,
    renderCell: dataGridRenderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'responseCounts',
    headerName: 'Completed Scenarios',
    description: 'Number of completed scenarios in the last 6 months.',
    type: 'number',
    flex: 1,
    minWidth: 90,
    renderCell: dataGridRenderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'errorRate',
    headerName: 'Error Rate %',
    description:
      'The proportion of instances where the student incorrectly applied the rules',
    type: 'number',
    flex: 1,
    minWidth: 70,
    renderCell: dataGridRenderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'scenarioAttempts',
    headerName: 'Scenario Attempts',
    type: 'number',
    flex: 1,
    // editable: true,
    renderCell: dataGridRenderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'taskCompletionRate',
    headerName: 'Task Completions',
    flex: 1,
    type: 'number',
    minWidth: 70,
    renderCell: dataGridRenderCell,
    headerAlign: 'center',
    align: 'center',
  },
];

const selectStyle = {
  color: 'white', // Set Select text color to white
  // "&:before": {
  //   borderBottomColor: "white", // Set underline color to white
  // },
  // "&:after": {
  //   borderBottomColor: "white", // Set underline color when focused to white
  //   borderColor: "white", // Set triangle icon color to white
  // },
  // icon: {
  //   fill: "white", // Set icon color to white
  // },
  // root: {
  //   color: "white", // Set root color to white
  // },
  '& fieldset': {
    borderColor: 'white', // Set the border color
  },
  '&::before': {
    borderColor: 'white', // Set underline color to white
  },
  '&::after': {
    borderBottomColor: 'white', // Set underline color when focused to white
    borderColor: 'white', // Set the triangle icon color
  },
  '&:not(.Mui-disabled):hover::before': {
    borderColor: 'white',
  },
  '.MuiOutlinedInput-notchedOutline': {
    //   borderColor: "rgba(228, 219, 233, 0.25)", // border colour override
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)', // colour after selecting a value
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)', // colour while selecting from the dropdown
  },
  '.MuiSvgIcon-root ': {
    fill: 'white !important', // the triangle dropdown icon colour
  },
};

interface ClassModel {
  code: string; // human-friendly class code
  class_id: string; // UUID
}

interface StudentModel {
  id: string; //UUID
  lastName: string;
  firstName: string;
  email: string;
  responseCounts: number | null | undefined;
  scenarioAttempts: number | null | undefined;
  taskCompletionRate: string | null | undefined;
}

export default function Instructor({ classCode }: { classCode?: string }) {
  console.log("classId: ", classCode)
  const [classFilter, setClassFilter] = useState('Select a class');
  const [selectedClass, setSelectedClass] = useState<ClassModel | undefined>(undefined); // the class that is selected
  const [classList, setClassList] = useState<ClassModel[]>([]);
  const [dataRows, setDataRows] = useState<StudentModel[]>([]);
  const [open, setOpen] = useState(false);
  const [openTasks, setOpenTasks] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [selectedUserID, setSelectedUserID] = useState<string>(''); // cognito username which is a UUID
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenTasks = () => setOpenTasks(true);
  const handleCloseTasks = () => setOpenTasks(false);
  const handleCloseError = () => setOpenError(false);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedRow, setSelectedRow] = useState<StudentModel | null>(null); // the row that is selected

  const fetchInstructorClasses = async () => {
    // // check if user is admin
    let role = 'teacher'
    // go into admin mode if classId is provided and user is admin
    if (classCode) {
      const adminOrg = await GetOrganisationByAdmin();
      if (adminOrg?.organisation_id) {
        role = 'admin'
      }
    }

    console.log("fetching classes for role: ", role)
    ListUserClasses(role)
      .then((response) => {
        console.log(response)
        setClassList(response);
        response.sort((a: any, b: any) => (a > b ? 1 : -1));
        if (!classCode) {
          // sort responses and set the first class as the default
          setClassFilter(response[0].code);
          setSelectedClass(response[0]);
        } else {
          // set the class filter to the provided classCode
          setClassFilter(classCode);
          console.log("find class in response: ", response.find((c: any) => c.code === classCode))
          setSelectedClass(response.find((c: any) => c.code === classCode));
        }
      })
      .catch((err) => {
        setErrMsg(err.message);
        setOpenError(true);
      });
  };

  const fetchStudentsInClass = async () => {
    // get all students in the class
    const selectedClass = classList.find((c) => c.code === classFilter);
    if (selectedClass) {
      setDataRows([]);
      setLoadingData(true);
      ListStudentsInClass(selectedClass.class_id)
        .then((response) => {
          // map response to array of StudentModel
          const students = response.map((student: any) => {
            console.log(student)
            return {
              id: student.Username, // cognito username which is a UUID
              lastName: student.UserAttributes.find(
                (attr: { Name: string; Value: string }) =>
                  attr.Name === 'family_name'
              )?.Value,
              firstName: student.UserAttributes.find(
                (attr: { Name: string; Value: string }) =>
                  attr.Name === 'given_name'
              )?.Value,
              email: student.UserAttributes.find(
                (attr: { Name: string; Value: string }) => attr.Name === 'email'
              )?.Value,
              responseCounts: student.ResponseCounts,
              errorRate: student.ErrorRate,
              scenarioAttempts: undefined, // TODO: get this from the backend
              taskCompletionRate: `${student.TaskCompletions?.completed}/${student.TaskCompletions?.total}`
            };
          });

          setDataRows(students);
        })
        .catch((err) => {
          setErrMsg(err.message);
          setOpenError(true);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  };

  //   useEffect call getFeedbackSummary
  useEffect(() => {
    fetchInstructorClasses();
  }, []);

  useEffect(() => {
    fetchStudentsInClass();
  }, [classFilter]);

  const NoRowsOverlay = () => (
    <GridOverlay
      style={{
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: '20px',
          fontSize: '1.2em',
          color: 'gray',
        }}
      >
        No data available
      </div>
    </GridOverlay>
  );

  return (
    <div className='main-content'>
      {/* <AggStats></AggStats> */}
      <Box padding={2}>
        {/* filter section that allows users to choose start and end dates, context and environment */}
        {/* horizontal oriented box */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // justifyContent: "space-evenly",
            alignItems: 'center',
            marginBottom: '20px',
            flexWrap: 'wrap',
            padding: '20px', // prevent text on left getting clipped in mobile view
          }}
          gap={2}
        >
          Select Class
          <DoubleArrowIcon />
          <FormControl sx={{ minWidth: '150px' }}>
            <InputLabel
              id='demo-simple-select-label'
              sx={{
                color: 'white', // Set label text color to white
                '&.MuiInputLabel-shrink': {
                  color: 'white', // Set active label color
                },
              }}
            >
              Class
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={classFilter}
              label='Class'
              onChange={(e) => {
                setClassFilter(e.target.value as string);
                setSelectedClass(classList.find((c) => c.code === e.target.value));
              }}
              fullWidth
              sx={selectStyle}
            >
              <MenuItem value='' disabled>
                Select a class
              </MenuItem>
              {classList.map((classItem) => (
                <MenuItem value={classItem.code}>{classItem.code}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {loadingData
            ? 'Loading class data...'
            : dataRows.length == 0
              ? 'No class data available'
              : 'Select a student to view feedback summary'}
        </Box>

        <Paper sx={{ overflow: 'hidden', backgroundColor: '#333' }}>
          {/* show a loading skeleton when loadingData */}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingData}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          {
            // if no dataRows, show NoRowsOverlay
            dataRows.length === 0 && !loadingData ? null : (
              <DataGrid
                disableColumnMenu
                columnVisibilityModel={{
                  // Hide id, scenarioAttemps and email columns
                  id: false,
                  scenarioAttempts: false,
                  email: false,
                  fullName: false,
                }}
                rows={dataRows}
                columns={columns}
                slots={{
                  noRowsOverlay: NoRowsOverlay, //these no data available overlays get squashed to zero height for some reason
                  noResultsOverlay: NoRowsOverlay,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                loading={loadingData}
                pageSizeOptions={[100]}
                disableRowSelectionOnClick
                sx={{
                  color: 'white',
                  '.MuiTablePagination-displayedRows': {
                    color: 'white',
                  },
                  '.MuiTablePagination-selectLabel': {
                    color: 'white',
                  },
                  '& .MuiTablePagination-select': {
                    color: 'white', // Customize text color
                  },
                  '& .MuiTablePagination-actions': {
                    color: 'white', // Customize text color
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none', // Remove focus outline
                  },
                  '& .MuiDataGrid-cell:hover': {
                    backgroundColor: 'transparent', // Remove hover background
                  },
                  '& .MuiDataGrid-sortIcon': {
                    color: 'white',
                  },
                  '& .MuiDataGrid-menuIcon': {
                    //this doesn't seem to work
                    color: 'white',
                  },
                  // next 3 lines are to force the column titles to wrap instead of shrink to ... when the column is too narrow
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal"
                  },
                  "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                    height: "unset !important",
                    paddingRight: "0px" // remove right padding
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    // Forced to use important since overriding inline styles
                    maxHeight: "168px !important",
                  },
                }}
                onCellClick={(cell) => {
                  setSelectedUserID(cell.row.id);
                  setSelectedRow(cell.row);
                  if (cell.field === 'taskCompletionRate') {
                    handleOpenTasks();
                  }
                  else {
                    handleOpen();
                  }
                }}
              />
            )
          }

        </Paper>
      </Box>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Paper
          style={{
            width: '95%',
            height: '90%', // 90% height
            overflowY: 'auto',
            color: 'white',
            backgroundColor: '#414141',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton
            size='large'
            sx={{
              color: 'white',
              position: 'absolute',
              top: '3%',
              left: '1%',
            }}
            onClick={handleClose}
            hidden={true}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
          <FeedbackSummary userID={selectedUserID} />
        </Paper>
      </Modal>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openTasks}
        onClose={handleCloseTasks}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Paper
          style={{
            width: '95%',
            height: '90%', // 90% height
            overflowY: 'auto',
            color: 'white',
            backgroundColor: '#414141',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton
            size='large'
            sx={{
              color: 'white',
              position: 'absolute',
              top: '3%',
              left: '1%',
            }}
            onClick={handleCloseTasks}
            hidden={true}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
          <Tasks userID={selectedRow?.id} classID={selectedClass?.class_id} name={selectedRow?.firstName} />
        </Paper>
      </Modal>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity='error'
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

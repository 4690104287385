// component that contains the report details panel of the answer component

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  ReportDetails,
  aspectSideValues,
  bearingDirectionValues,
  cpaPosValues,
  relColourValues,
  vesselTypeResVisMappings,
  vesselTypeMappings,
} from './common';
import NumberField from './NumberField';
import SpeechToText from './SpeechToText';

// props for component
interface ReportDetailsPanelProps {
  reportDetails: ReportDetails;
  numberFieldChangeHandler: (event: any) => void;
  selectionChangeHandler: (event: any) => void;
  checkboxChangeHandler: (event: any) => void;
  numberFieldBlurHandler: (value: any, field: any) => void;
  resVis: boolean;
}

// component
export const ReportDetailsPanel = ({
  reportDetails,
  numberFieldChangeHandler,
  numberFieldBlurHandler,
  selectionChangeHandler,
  checkboxChangeHandler,
  resVis,
}: ReportDetailsPanelProps) => {
  const currentVesselTypeMapping = resVis
    ? vesselTypeResVisMappings
    : vesselTypeMappings;
  const displayTrans = function (text: String) {
    return <Typography>{text}</Typography>;
  };
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={1}
          alignItems='center'
        >
          <Box
            display={'flex'}
            gap={1}
            flexDirection={'row'}
            alignItems='center'
            marginBottom={1}
          >
            <Box>At</Box>
            <Box minWidth={150} maxWidth={150}>
              <FormControl fullWidth>
                <Select
                  value={reportDetails.relColour || ''}
                  name='relColour'
                  onChange={selectionChangeHandler}
                  displayEmpty
                  style={{
                    height: '40px',
                    flex: 1,
                    maxHeight: '40px',
                  }}
                >
                  <MenuItem value='' disabled>
                    <em>Select colour</em>
                  </MenuItem>
                  {relColourValues.map((relColour) => {
                    return (
                      <MenuItem value={relColour} dense>
                        {relColour}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              display={'flex'}
              gap={1}
              flexDirection={'row'}
              alignItems='center'
            >
              <NumberField // relBrg from 0 to 180
                id='relBearingCont'
                placeholder='0'
                value={reportDetails.relBearingCont}
                onChange={numberFieldChangeHandler}
                onBlur={() =>
                  numberFieldBlurHandler(
                    reportDetails.relBearingCont,
                    'relBearingCont'
                  )
                }
                inputProps={{
                  min: 0,
                  max: 180,
                  step: 1,
                }}
              />
              &#176;{' '}
            </Box>
          </Box>
          <Box
            display={'flex'}
            gap={1}
            flexDirection={'row'}
            alignItems='center'
            marginBottom={1}
          >
            range
            <NumberField
              id='range'
              placeholder='0.0'
              value={reportDetails.range}
              onChange={numberFieldChangeHandler}
              onBlur={() =>
                numberFieldBlurHandler(reportDetails.range, 'range')
              }
              inputProps={{
                min: 0,
                step: 0.1,
                // inputmode: 'decimal',
                // type: 'number',
                // pattern: '\\d*',
              }}
            />
            NM
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={1}
          alignItems='center'
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            I have a
            <Box minWidth={200} maxWidth={200}>
              <FormControl fullWidth>
                <Select
                  value={reportDetails.typeVis || ''}
                  name='typeVis'
                  onChange={selectionChangeHandler}
                  displayEmpty
                  style={{
                    height: '40px',
                    flex: 1,
                    maxHeight: '40px',
                  }}
                >
                  <MenuItem value='' disabled>
                    <em>Select vessel</em>
                  </MenuItem>
                  {Object.keys(currentVesselTypeMapping).map((key) => (
                    <MenuItem key={key} value={key} dense>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            held by
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reportDetails.visual}
                    id='visual'
                    onClick={checkboxChangeHandler}
                  />
                }
                label='visual'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reportDetails.radar}
                    id='radar'
                    onClick={checkboxChangeHandler}
                  />
                }
                label='radar'
              />
            </FormGroup>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={1}
          alignItems='center'
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            I am
            <NumberField
              id='aspectBearing'
              placeholder='0'
              value={reportDetails.aspectBearing}
              onChange={numberFieldChangeHandler}
              onBlur={() =>
                numberFieldBlurHandler(
                  reportDetails.aspectBearing,
                  'aspectBearing'
                )
              }
              inputProps={{
                min: 0,
                max: 180,
                step: 1,
              }}
            />
            &#176;{' '}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            on her
            <Box minWidth={150} maxWidth={150}>
              <FormControl fullWidth id='thing'>
                <Select
                  value={reportDetails.aspectSide || ''}
                  name='aspectSide'
                  onChange={selectionChangeHandler}
                  displayEmpty
                  style={{
                    height: '40px',
                    flex: 1,
                    maxHeight: '40px',
                  }}
                >
                  <MenuItem value='' disabled>
                    <em>Select side</em>
                  </MenuItem>
                  {aspectSideValues.map((value) => {
                    return (
                      <MenuItem value={value} dense>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            bow
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={1}
          alignItems='center'
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            Her bearing is drawing
            <Box minWidth={150} maxWidth={150}>
              <FormControl fullWidth id='bearing-direction-form'>
                <Select
                  value={reportDetails.bearingDirection || ''}
                  name='bearingDirection'
                  onChange={selectionChangeHandler}
                  displayEmpty
                  style={{
                    height: '40px',
                    flex: 1,
                    maxHeight: '40px',
                  }}
                >
                  <MenuItem value='' disabled>
                    <em>Select direction</em>
                  </MenuItem>
                  {bearingDirectionValues.map((value) => {
                    return (
                      <MenuItem value={value} dense>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={1}
          alignItems='center'
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            She has a CPA of
            <NumberField
              id='cpa'
              placeholder='0.0'
              value={reportDetails.cpa}
              onChange={numberFieldChangeHandler}
              onBlur={() => numberFieldBlurHandler(reportDetails.cpa, 'cpa')}
              sx={{ width: '80px', minWidth: '80px' }}
              inputProps={{
                min: 0,
                step: 0.1,
              }}
            />
            NM{' '}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            on my
            <Box minWidth={150} maxWidth={150}>
              <FormControl fullWidth id='cpa-pos-form'>
                <Select
                  value={reportDetails.cpaPos || ''}
                  name='cpaPos'
                  onChange={selectionChangeHandler}
                  displayEmpty
                  style={{
                    height: '40px',
                    flex: 1,
                    maxHeight: '40px',
                  }}
                >
                  {' '}
                  <MenuItem value='' disabled>
                    <em>Select position</em>
                  </MenuItem>
                  {cpaPosValues.map((value) => {
                    return (
                      <MenuItem value={value} dense>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            alignItems='center'
            marginBottom={1}
          >
            in
            <NumberField
              id='tcpa'
              placeholder='0.0'
              value={reportDetails.tcpa}
              onChange={numberFieldChangeHandler}
              onBlur={() => numberFieldBlurHandler(reportDetails.tcpa, 'tcpa')}
              sx={{ width: '80px', minWidth: '80px' }}
              inputProps={{
                min: 0,
                step: 0.1,
              }}
            />
            mins
          </Box>
        </Box>
      </Box>
    </>
  );
};

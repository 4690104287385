import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';
import TimerIcon from '@mui/icons-material/Timer';
import PauseIcon from '@mui/icons-material/Pause';

type Mode = 'practice' | 'timed';
interface StepHeaderProps {
  score: number;
  totalQuestions: number;
  currentQuestionIndex: number;
  mode: Mode;
  startTimeSecs: number | null;
  isBouncing: boolean;
  timerRunning: boolean;
  onTimeUpdate: (time: number) => void;
  setGameOver: (value: boolean) => void;
  completeQuiz: (timerComplete: boolean) => void;
}

const StepHeader: React.FC<StepHeaderProps> = ({
  score,
  currentQuestionIndex,
  totalQuestions,
  isBouncing,
  mode,
  startTimeSecs,
  timerRunning,
  onTimeUpdate,
  setGameOver,
  completeQuiz
}) => {
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  useEffect(() => {
    let timer: any;
    if (mode === 'timed' && startTimeSecs && timerRunning) {
      if (remainingTime === null) {
        // Initialize timer only once
        setRemainingTime(startTimeSecs);
      }
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          const newTime = prevTime !== null ? prevTime - 1 : 0;
          if (newTime <= 0) {
            clearInterval(timer); // Stop the timer if time runs out
            completeQuiz(true);
          }
          // Defer the execution of onTimeUpdate to avoid state update during render
          setTimeout(() => onTimeUpdate(newTime), 0);
          return newTime;
        });
      }, 1000);
    } else if (!timerRunning && timer) {
      clearInterval(timer);
    }
    return () => {
      if (timer) clearInterval(timer); // Cleanup on component unmount or when effect re-runs
    };
  }, [mode, timerRunning]);

  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  return (
    <Box
      className='gameHeader'
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '6px 12px',
        position: 'relative',
        zIndex: 1
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <i
          className={`fa-solid fa-circle-bolt fa-xl ${
            isBouncing ? 'fa-bounce' : ''
          }`}
          style={{ verticalAlign: 'middle' }}
        />
        <Typography sx={{ marginLeft: '10px' }} variant='h6'>
          {score}
        </Typography>
      </Box>
      {mode === 'timed' && remainingTime !== null && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '20px'
          }}
        >
          {timerRunning ? (
            <TimerIcon
              sx={{
                marginRight: '10px',
                fontSize: '30px'
              }}
            />
          ) : (
            <PauseIcon
              sx={{
                marginRight: '10px',
                fontSize: '30px'
              }}
            />
          )}
          <Typography variant='h6'>
            {Math.floor(remainingTime / 60)
              .toString()
              .padStart(2, '0')}
            m {(remainingTime % 60).toString().padStart(2, '0')}s
          </Typography>
        </Box>
      )}{' '}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginLeft: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <LinearProgress
            variant='determinate'
            value={progress}
            color='secondary'
            className={progress <= 80 ? 'glow-effect' : ''}
            sx={{
              height: '12px',
              borderRadius: '5px',
              flexGrow: 1
            }}
          />
          <Typography
            sx={{
              marginLeft: '10px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap'
            }}
          >
            {currentQuestionIndex + 1} / {totalQuestions}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginLeft: '20px' }}>
        <Link to='/games/shake-down'>
          <IconButton sx={{ color: 'whitesmoke', padding: '0' }}>
            <CloseIcon />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );
};

export default StepHeader;

// StatementsTable.tsx
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

interface Props {
  statements: any[]; // You can refine this type further based on your data structure
}

function getEmojiForFeedback(feedback: boolean | null): string | undefined {
  if (feedback == true) return '✅';
  if (feedback == false) return '❌';
  if (feedback === null) return '🤷‍♂️';
}

function statementTypeToReadableString(statement_type: string | undefined) {
  switch (statement_type) {
    case 'RR':
      return 'Rereport';
    case 'PNCT':
      return 'Pass no closer than';
  }
  return statement_type;
}
const StatementsTable: React.FC<Props> = ({ statements }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Tgt No.</TableCell>
          <TableCell>Achieved</TableCell>
          <TableCell>Agreement Type</TableCell>
          <TableCell>Range (NM)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {statements.map((statement, index) => (
          <TableRow key={index}>
            <TableCell>{statement.tgt_name}</TableCell>
            <TableCell>{getEmojiForFeedback(statement.feedback)}</TableCell>
            <TableCell>
              {statementTypeToReadableString(statement.statement_type)}
            </TableCell>
            <TableCell>{statement.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default StatementsTable;

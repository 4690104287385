import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

type AlertDialogSlideProps = {
  open: boolean;
  handleClickProceed: () => void;
  handleClose: () => void;
  targetShip: string;
  newTargetShip: string;
};

export function AlertDialogSlide(props: AlertDialogSlideProps) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography
            variant='h6'
            fontWeight={'bold'}
            sx={{ color: 'warning.main' }}
          >
            {'Unsaved Changes for Target ' + props.targetShip}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <p>You have unsaved changes on target {props.targetShip}.</p>
            <p>
              Are you sure you want to discard your changes and load target{' '}
              {props.newTargetShip} instead?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Back</Button>
          <Button color='warning' onClick={props.handleClickProceed}>
            Proceed without saving
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const GameFormatPage = () => {
  return (
    <div className='main-content'>
      <Box
        p={8}
        maxWidth='800px'
        display='flex'
        flexDirection='column'
        alignItems='center'
        margin='auto'
      >
        <Typography variant='h4' gutterBottom>
          IRPCS Training Game Format
        </Typography>

        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            textAlign: 'left'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Overview</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The game covers the full breadth of the IRPCS through 32
              individual activities:
              <ul>
                <li>
                  23 Training Sessions (covering 1-2 rules or one aspect of the
                  Rules each)
                </li>
                <li>
                  8 Section Assessments (covering discrete sections of the
                  Rules)
                </li>
                <li>
                  1 Final Assessment (covering the full breadth of the Rules)
                </li>
              </ul>
              The first 4 sections available immediately include Lights & Shapes
              - Basic, Any Condition of Visibility, Restricted Visibility, and
              Sound & Light Signals. More sections will be released in the
              second part of the trial.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            textAlign: 'left'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Training Sessions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Training Sessions are designed to complement and build on other
              learning, attemptable in any order:
              <ul>
                <li>You will receive a score but there is no pass mark.</li>
                <li>The score is not visible to anyone apart from you.</li>
                <li>
                  No limit to the number of times you can attempt these training
                  sessions.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            textAlign: 'left'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Section Assessments and Final Assessment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Section Assessments unlock by completing all Training Sessions in
              a Section, consisting of mixed questions from training and new
              ones. The Final Assessment is unlocked after passing all Section
              Assessments and draws from across the question bank. Results are
              shared via leaderboards, ranked by score and time taken.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            textAlign: 'left'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Progress Rate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Aim to complete 1-2 activities on a typical working day during the
              4-week trial to finish the Final Assessment before your final
              exam. It's important to note that progress cannot be saved partway
              through an activity; it is either completed in one sitting or
              restarted.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            textAlign: 'left'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Hints and Tips</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              For fill-in-the-blank style questions, using specific wording from
              the Rules is crucial. Some acronyms or synonyms are recognized,
              but the full terms ensure correctness. Punctuation and
              capitalisation are ignored, and use the built-in spell checker to
              ensure answers adhere to the official spelling (e.g.,
              "Organization", "manoeuvrability").
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default GameFormatPage;

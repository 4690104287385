import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logoImage from '../../images/logo_dark.png';
import { Auth } from 'aws-amplify';
import {
  GetOrganisationByAdmin,
  ListUserClasses,
  getGame
} from '../../clients/rotr-client';
import { Experiment } from '@amplitude/experiment-js-client';
import { set } from 'lodash';
interface NavProps {
  reciteFeatureFlag: boolean;
}
function Nav({ reciteFeatureFlag }: NavProps) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElPracticeMenu, setAnchorElPracticeMenu] =
    React.useState<null | HTMLElement>(null);
  const isPracticeMenuOpen = Boolean(anchorElPracticeMenu);
  const [anchorElPracticeMenuSmall, setAnchorElPracticeMenuSmall] =
    React.useState<null | HTMLElement>(null);
  const isPracticeMenuOpenSmall = Boolean(anchorElPracticeMenuSmall);

  const [pages, setPages] = React.useState(['Home']);
  const [settings, setSettings] = React.useState([
    'Profile',
    /*"Account", "Dashboard", */
    'Logout'
  ]);

  const [user, setUser] = React.useState(null);
  const [userEmail, setUserEmail] = React.useState(null);
  const [displayShakeDown, setDisplayShakeDown] = React.useState(false);
  const authorizeUrl = process.env.REACT_APP_AUTHORIZER_URL;
  const appClientId = process.env.REACT_APP_CLIENT_ID;
  const signInRedirectUrl = process.env.REACT_APP_SIGNIN_CALLBACK;
  const signInLink = `${authorizeUrl}?client_id=${appClientId}&response_type=code&scope=email+openid+phone&redirect_uri=${signInRedirectUrl}`;

  //navBarColour is darkmagenta for development, darkorchid for staging and black for production
  const navBarColour =
    process.env.REACT_APP_ENV === 'production'
      ? '#161A1Dff'
      : process.env.REACT_APP_ENV === 'staging'
      ? '#161A1Dff'
      : '#161A1Dff';

  React.useEffect(() => {
    const checkTeacher = async () => {
      try {
        const teacherClasses = await ListUserClasses('teacher');
        if (teacherClasses.length > 0) {
          setPages([
            'Home',
            'Practice',
            'Tasks',
            'Feedback',
            'Instructor',
            'Help'
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const checkAdministrator = async () => {
      try {
        const adminOrg = await GetOrganisationByAdmin();
        if (adminOrg?.organisation_id) {
          setPages([
            'Home',
            'Practice',
            'Tasks',
            'Feedback',
            'Instructor',
            'Administrator',
            'Help'
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const checkUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('User:', user);
        setUser(user);
        setPages(['Home', 'Practice', 'Tasks', 'Feedback', 'Help']);
        await checkTeacher();
        await checkAdministrator();
        setSettings(['Profile', 'Logout']);
      } catch (error) {
        console.error(error);
        setUser(null);
        setPages([]);
        setSettings([]);
      }
    };
    checkUser();
    const checkGame = async () => {
      const gameResponse = await getGame();
      if (gameResponse?.id) setDisplayShakeDown(true);
    };
    checkGame();
    Auth.currentSession()
      .then((session) => {
        setUserEmail(session.getIdToken().payload.email);
      })
      .catch((err) => console.error(err));
  }, []);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenPracticeMenuSmall = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElPracticeMenuSmall(event.currentTarget);
  };

  const handleClosePracticeMenuSmall = () => {
    setAnchorElPracticeMenuSmall(null);
  };

  const handlePracticeMenuClickSmall = (option: string) => {
    navigate(`/${option.toLowerCase()}`);
    handleClosePracticeMenuSmall();
    handleCloseNavMenu();
  };

  const handleClickAndCloseNavMenu = (page: string) => {
    //  determine the button key
    navigate(`/${page.toLowerCase()}`);
    handleCloseNavMenu();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickAndCloseUserMenu = (setting: string) => {
    switch (setting) {
      case 'Profile':
        navigate('/profile');
        break;
      case 'Logout':
        Auth.signOut();
        break;
    }
    handleCloseUserMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenPracticeMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPracticeMenu(event.currentTarget);
  };

  const handleClosePracticeMenu = () => {
    setAnchorElPracticeMenu(null);
  };
  const handlePracticeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPracticeMenu(event.currentTarget); // Always set the anchor for the practice menu
  };
  const handlePracticeMenuClick = (option: string) => {
    navigate(`/${option.toLowerCase()}`);
    handleClosePracticeMenu();
    handleCloseNavMenu();
  };
  const renderPracticeMenuItems = () => {
    const items = [];

    items.push(
      <MenuItem
        key='scenarios'
        onClick={() => handlePracticeMenuClick('practice')}
      >
        Scenarios
      </MenuItem>
    );

    items.push(
      <Tooltip
        key='recite'
        title='practice reciting the rules'
        placement='right'
      >
        <MenuItem onClick={() => handlePracticeMenuClick('practice/recite')}>
          Recite
        </MenuItem>
      </Tooltip>
    );
    if (displayShakeDown) {
      items.push(
        <Tooltip
          key='Shake Down Game'
          title='Play along and learn the rules'
          placement='right'
        >
          <MenuItem onClick={() => handlePracticeMenuClick('games/shake-down')}>
            Shake Down Game
          </MenuItem>
        </Tooltip>
      );
    }

    return items;
  };

  return (
    <AppBar position='static' style={{ backgroundColor: navBarColour }}>
      <Container maxWidth='xl' color='green'>
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          {/* image that is not displayed on xs size */}
          {/* <img src={logoImage} alt="Logo" className="logo" /> */}
          <Box
            component='img'
            sx={{
              maxHeight: { xs: 0, md: 50 },
              maxWidth: { xs: 0, md: 128 },
              marginRight: { xs: 0, md: 1 },
              marginBottom: { xs: 0, md: 0.6 }
            }}
            alt=''
            src={logoImage}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page) => {
                if (page === 'Practice') {
                  return (
                    <MenuItem key={page} onClick={handlePracticeClick}>
                      <Typography color='#161A1Dff' textAlign='center'>
                        {page}
                      </Typography>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem
                      key={page}
                      onClick={() => handleClickAndCloseNavMenu(page)}
                    >
                      <Typography color='#161A1Dff' textAlign='center'>
                        {page}
                      </Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>
          <Box
            component='img'
            sx={{
              maxHeight: { xs: 50, md: 0 },
              maxWidth: { xs: 200, md: 0 }
            }}
            alt='logo'
            src={logoImage}
          />
          {/* following is a workaround to make the logo centered in xs size */}
          <Typography
            variant='h5'
            noWrap
            component='a'
            href='/'
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            {' '}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => {
              if (page === 'Practice') {
                return (
                  <div key={page}>
                    <Button
                      sx={{ my: 2, color: 'white', display: 'block' }}
                      onClick={handleOpenPracticeMenu}
                    >
                      {page}
                    </Button>
                    <Menu
                      id='practice-menu'
                      anchorEl={anchorElPracticeMenu}
                      open={isPracticeMenuOpen}
                      onClose={handleClosePracticeMenu}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                    >
                      {renderPracticeMenuItems()}
                    </Menu>
                  </div>
                );
              } else {
                return (
                  <Button
                    key={page}
                    onClick={() => handleClickAndCloseNavMenu(page)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>

          {user ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title='Open settings'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ bgcolor: '#E35230ff' }}>
                    {userEmail ? userEmail[0] : ''}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleClickAndCloseUserMenu(setting)}
                  >
                    <Typography textAlign='center'>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            /* sign in button */
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                window.location.replace(signInLink);
              }}
              sx={{ minWidth: { xs: '36px', sm: 'auto' } }}
            >
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Sign in</Box>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <i className='fa-solid fa-right-to-bracket'></i>
              </Box>
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Nav;

import React from 'react';
import { IconButton } from '@mui/material';
import StartIcon from '@mui/icons-material/Start';

interface NextQuestionProps {
  onClick: () => void;
}

const NextQuestion: React.FC<NextQuestionProps> = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      style={{
        backgroundColor: '#87CEEB',
        padding: '10px',
      }}
    >
      <StartIcon style={{ fontSize: '48px', color: 'white' }} />
    </IconButton>
  );
};

export default NextQuestion;
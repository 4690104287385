import React from 'react';
import { Modal, Typography, IconButton, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

interface StarExplanationModalProps {
  open: boolean;
  onClose: () => void;
}

const StarExplanationModal: React.FC<StarExplanationModalProps> = ({
  open,
  onClose
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'white',
          boxShadow: undefined,
          padding: '10px',
          color: 'whitesmoke',
          background: '#1d2125'
        }}
      >
        {' '}
        <IconButton
          aria-label='close'
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'whitesmoke'
          }}
        >
          <CancelTwoToneIcon />
        </IconButton>
        <Typography variant='h6' gutterBottom>
          Star Achievements
        </Typography>
        <Typography variant='body1'>
          Stars are awarded based on the following scores:
        </Typography>
        <ol style={{ listStyleType: 'none' }}>
          <li>
            {' '}
            <StarIcon
              key='star1'
              style={{
                filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6))',
                color: '#FCE56F'
              }}
            />{' '}
            Gold - score 90% or above
          </li>
          <li>
            {' '}
            <StarIcon
              key='star2'
              style={{
                filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6))',
                color: '#D9D9D9'
              }}
            />{' '}
            Silver - score 80% or above
          </li>
          <li>
            {' '}
            <StarIcon
              key='star3'
              style={{
                filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6))',
                color: '#E09663'
              }}
            />{' '}
            Bronze - score 70% or above
          </li>
        </ol>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row', // Default to side by side
            flexWrap: 'wrap', // Allow items to wrap
            alignItems: 'center', // Vertically align items in the middle
            justifyContent: 'flex-start', // Start alignment for larger screens
            gap: 2, // Space between items
            '& > .MuiSvgIcon-root': {
              // Target the icon specifically for additional styling
              textAlign: 'center', // Center the icon when wrapped
              flexBasis: '100%' // Make the icon take full width when wrapped to ensure it's centered
            }
          }}
        >
          <Typography variant='body1'>
            Stars are only awarded for section assessments and the final
            assessment.
          </Typography>
          <EmojiEventsTwoToneIcon
            sx={{
              fontSize: 60,
              color: '#1bc8f8bc',
              flexShrink: 0
            }}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default StarExplanationModal;

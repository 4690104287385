import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

const NumberField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <TextField
      type="text"
      variant="outlined"
      size="small"
      sx={{ width: "100px", minWidth: "100px" }}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
};

export default NumberField;

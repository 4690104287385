// customized TableCell for FeedbackSummaryTable with white text
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";

const FeedbackSummaryTableCell = styled(TableCell)(({ theme }) => ({
  [`&.MuiTableCell-root`]: {
    color: "white",
  },
}));

export default FeedbackSummaryTableCell;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
const oauthDomain = process.env.REACT_APP_OATH_DOMAIN;
const authorizeUrl = process.env.REACT_APP_AUTHORIZER_URL;

const appClientId = process.env.REACT_APP_CLIENT_ID;
const signInRedirectUrl = process.env.REACT_APP_SIGNIN_CALLBACK;
const signInLink = `${authorizeUrl}?client_id=${appClientId}&response_type=code&scope=email+openid+phone&redirect_uri=${signInRedirectUrl}`;
const SignUpImage = styled('img')({
  maxWidth: '100%',
  '@media (max-width:600px)': {
    // Hiding the image for screens smaller than 600px
    display: 'none'
  }
});

const SignInButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: '1rem',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}));

const LargeTypography = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '1rem',
  '@media (max-width:600px)': {
    // Center aligning the text for screens smaller than 600px
    textAlign: 'center'
  }
});

const NotSignedInContent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className='main-content'>
      <Grid container justifyContent='center' alignItems='center' spacing={3}>
        <Grid item xs={12} sm={6}>
          <LargeTypography variant='h2'>
            ROTR training just got smarter
          </LargeTypography>
          <Typography variant='h6' gutterBottom>
            Learn through interactive ROTR scenarios, practice reporting and
            applying the rules.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SignUpImage src='smart_trainer.png' alt='Smart ROTR Training' />
        </Grid>
      </Grid>
      <SignInButton
        variant='contained'
        size='large'
        onClick={() => {
          window.location.replace(signInLink || '');
        }}
      >
        Sign In
      </SignInButton>
    </div>
  );
};

export default NotSignedInContent;

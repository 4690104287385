// user profile component that displays their list of classes and allow users
// to register for classes
//

import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Collapse,
  Container,
  Dialog,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {
  DeleteUserClass,
  ListUserClasses,
  RegisterUserClass,
  SetUserCurrentClass,
} from "../../clients/rotr-client";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { idText } from "typescript";
import { RegisterClassDialog } from "./RegisterClassDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClassDeleteDialog } from "./ClassDeleteDialog";
import { error } from "console";
import { set } from "lodash";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

interface ClassViewModel {
  class_id: string;
  code: string;
  role: string;
  is_current_class: boolean;
}

const Profile = ({ userId, userFirstname }: { userId?: string, userFirstname?: string, }) => {
  // get user's classes
  const [classData, setClassData] = useState<ClassViewModel[]>([]);
  const [hoverId, setHoverId] = useState("");
  const [openRegisterClassDialog, setOpenRegisterClassDialog] = useState(false);
  const [classCodeToRemove, setClassCodeToRemove] = useState("");
  const [openDeleteClassDialog, setOpenDeleteClassDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');

  const handleMouseEnter = (id: string) => {
    setHoverId(id);
  };

  const handleMouseLeave = () => {
    setHoverId("");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  }

  const handleSetCurrentClass = async (classId: string) => {
    try {
      const response = await SetUserCurrentClass(userId || "", classId);
      fetchClassData();
      setSnackbarMsg("current class set successfully");
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error: any) {
      console.error(error);
      setSnackbarMsg("failed to set current class: " + error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
    }
  };

  const handleOpenRegisterClassDialog = () => {
    setOpenRegisterClassDialog(true);
  };

  const handleCloseRegisterClassDialog = () => {
    setOpenRegisterClassDialog(false);
  };

  const handleRegisterClass = (classCode: string) => {
    try {
      fetchClassData();
      setSnackbarMsg("class registered successfully");
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

    } catch (error: any) {
      console.error(error);
      setSnackbarMsg("failed to register class: " + error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setOpenRegisterClassDialog(false);
    }
  };

  const fetchClassData = async () => {
    try {
      const userClasses = await ListUserClasses('all', userId);
      setClassData(userClasses);
      console.log(userClasses);
    } catch (error: any) {
      console.error(error);
      setSnackbarMsg("failed to fetch class data: " + error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleDeleteClassClick = async (classCode: string) => {
    setClassCodeToRemove(classCode);
    setOpenDeleteClassDialog(true);
  };

  const deleteClassByCode = async () => {
    try {
      console.log("deleting class: ", classCodeToRemove);
      const response = await DeleteUserClass(userId || "", classCodeToRemove);
      fetchClassData();
      setSnackbarMsg("class removed successfully");
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error: any) {
      console.error(error);
      setSnackbarMsg("failed to remove class: " + error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setOpenDeleteClassDialog(false);
    }
  };

  useEffect(() => {
    fetchClassData();
  }, []);

  return (
    <div className="main-content">
      <Box
        p={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "350px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {userFirstname ? userFirstname + "'s" : 'My'} Classes
          </Typography>
          {(userId || "").length == 0 ? null : (
            <Tooltip title="Add Class">
              <IconButton
                disabled={(userId || "").length == 0}
                onClick={handleOpenRegisterClassDialog}
              >
                <AddBoxIcon sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#333", width: "350px" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Class Code</TableCell>
                <TableCell align="center">Role</TableCell>
                <TableCell align="center">Current Class</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {classData.map((data) => (
                <TableRow
                  key={data.class_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {data.is_current_class || (userId || "").length == 0 ? null : (
                      <IconButton disabled={(userId || "").length == 0}>
                        <DeleteIcon
                          sx={{
                            color: "red",
                          }}
                          onClick={() => {
                            handleDeleteClassClick(data.code);
                          }}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {data.code}
                  </TableCell>
                  <TableCell align="center">{data.role}</TableCell>
                  <TableCell align="center">
                    {data.is_current_class ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      <Tooltip
                        title="Set as current class"
                        enterDelay={0}
                        leaveDelay={0}
                        onMouseEnter={() => handleMouseEnter(data.class_id)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <IconButton disabled={(userId || "").length == 0}>
                          <CheckCircleIcon
                            sx={{
                              color:
                                hoverId == data.class_id
                                  ? "white"
                                  : "transparent",
                            }}
                            onClick={() => {
                              handleSetCurrentClass(data.class_id);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <RegisterClassDialog
        userId={userId || ""}
        handleClickProceed={handleRegisterClass}
        handleClose={handleCloseRegisterClassDialog}
        open={openRegisterClassDialog}
      />
      <ClassDeleteDialog
        open={openDeleteClassDialog}
        handleClickProceed={deleteClassByCode}
        handleClose={() => {
          setOpenDeleteClassDialog(false);
        }}
        classCode={classCodeToRemove}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Profile;

import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { TransparentPaper, TransparentPaperWithShadow } from '../../styles';
import { useState } from 'react';

const CreateScenario = (props: any) => {
  const contextOptions = ['Open water', 'TSS', 'Narrow Channel'];
  const disabledContextOptions = ['Narrow Channel'];
  const environmentOptions = ['Day', 'Night', 'Res Vis'];
  const trafficDensityOptions = [1, 2, 3, 4, 5];

  // variable that tracks whether user is on a small screen
  const smallScreen = useMediaQuery('(max-width: 599px)');

  const handleContextClick = (context: string) => {
    props.scenarioContextSetter(context);
  };
  const handleEnvironmentClick = (environment: string) => {
    props.environmentSetter(environment);
  };
  const handleTrafficDensityClick = (trafficDensity: number) => {
    props.trafficDensitySetter(trafficDensity);
  };

  return (
    <Box paddingLeft={smallScreen ? 2 : 5}>
      <Grid
        container
        spacing={0}
        paddingRight={0}
        paddingBottom={5}
        width={'100%'}
      >
        <Grid item xs={12} textAlign={'left'}>
          <h2 className='normal-text'> Scenario Settings </h2>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          textAlign={'left'}
          paddingTop={0}
          alignItems={'flex-start'}
        >
          <Box padding={0} marginTop={-2} paddingRight={smallScreen ? 0 : 5}>
            <h4 className='normal-text'> Context </h4>
            <Box flexDirection={'row'} marginTop={-1} marginBottom={1}>
              {contextOptions.map((str, index) => (
                // tooltip to indicate disabled chips
                <Tooltip
                  title={
                    disabledContextOptions.includes(str)
                      ? `${str} will be coming soon`
                      : str
                  }
                >
                  {/* force tooltip display for disabled chips */}
                  <div style={{ display: 'inline-block' }}>
                    <Chip
                      key={index}
                      label={str}
                      sx={{ color: 'white' }}
                      color={
                        props.selectedContext === str ? 'primary' : 'default'
                      }
                      variant={
                        props.selectedContext === str ? 'filled' : 'outlined'
                      }
                      onClick={() => handleContextClick(str)}
                      style={{ margin: 2 }}
                      // disable if in disabledContextOptions
                      disabled={disabledContextOptions.includes(str)}
                    />
                  </div>
                </Tooltip>
              ))}
            </Box>
            {}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} textAlign={'left'}>
          <Box padding={0} marginTop={-2} paddingRight={smallScreen ? 0 : 5}>
            <h4 className='normal-text'> Traffic Density </h4>
            <Box flexDirection={'row'} marginTop={-1} marginBottom={1}>
              {trafficDensityOptions.map((num, index) => (
                <Chip
                  key={index}
                  label={num}
                  sx={{ color: 'white' }}
                  // color={selectedTrafficDensity === num ? "warning" : "primary"}
                  color={
                    props.selectedTrafficDensity === num ? 'primary' : 'default'
                  }
                  variant={
                    props.selectedTrafficDensity === num ? 'filled' : 'outlined'
                  }
                  onClick={() => handleTrafficDensityClick(num)}
                  style={{ margin: 2, marginRight: 5 }}
                />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} textAlign={'left'}>
          <Box padding={0} marginTop={-2} paddingRight={smallScreen ? 0 : 5}>
            <h4 className='normal-text'> Environment </h4>
            <Box flexDirection={'row'} marginTop={-1} marginBottom={1}>
              {environmentOptions.map((str, index) => (
                <Chip
                  key={index}
                  label={str}
                  // color={selectedEnvironment === str ? "warning" : "primary"}
                  sx={{ color: 'white' }}
                  color={
                    props.selectedEnvironment === str ? 'primary' : 'default'
                  }
                  variant={
                    props.selectedEnvironment === str ? 'filled' : 'outlined'
                  }
                  onClick={() => handleEnvironmentClick(str)}
                  style={{ margin: 2 }}
                />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} textAlign={'left'} paddingTop={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              onClick={props.buttonClickHandler}
              size='large'
              disabled={
                props.selectedContext === '' ||
                props.selectedEnvironment === '' ||
                props.selectedTrafficDensity === 0
              }
              sx={{
                '&.Mui-disabled': {
                  bgcolor: '#f2f2f2',
                  color: '#a6a6a6',
                },
              }}
            >
              Generate Scenario
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateScenario;

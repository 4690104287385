import React, { useEffect, useState } from 'react';
import { Typography, Box, IconButton, Modal } from '@mui/material';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { diffStrings } from './stringHelper';

import { Rule } from '../ruleTypes';

interface ShowRuleProps {
  ruleId: string;
  ruleString: string;
  input: string;
  part: string;
  section: string;
  fullTitle: string;
  shortTitle: string;
  indexRemovedWords: number[];
  open: boolean;
  onClose: () => void;
}

const RuleGuidanceModal: React.FC<ShowRuleProps> = ({
  ruleId,
  ruleString,
  part,
  section,
  fullTitle,
  shortTitle,
  indexRemovedWords,
  input,
  open,
  onClose
}) => {
  const [diffArray, setDiffArray] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const inputLength = input.split(' ').length;
    // trim the rule to the same length as the input
    const ruleStringArray = ruleString.split(' ');
    const trimmedRule = ruleStringArray.slice(0, inputLength).join(' ');
    setDiffArray(diffStrings(trimmedRule, input));
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '4px',
          p: 6
        }}
      >
        <IconButton
          size='large'
          sx={{
            color: 'black',
            position: 'absolute',
            top: -8,
            left: -8
          }}
          onClick={onClose}
        >
          <CancelTwoToneIcon fontSize='large' />
        </IconButton>
        <Box>
          <Typography sx={{ color: 'grey' }} gutterBottom variant='subtitle2'>
            {part}
            {section ? ' > ' + section : ''}
          </Typography>
          <Typography color='primary' variant='h6' gutterBottom>
            {fullTitle}
          </Typography>
          <Typography variant='body1'>
            {ruleString.split(' ').map((word, index) => {
              const isRemoved = indexRemovedWords.includes(index);
              return (
                <span
                  key={index}
                  style={{ textDecoration: isRemoved ? 'underline' : 'none' }}
                >
                  {word}{' '}
                </span>
              );
            })}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default RuleGuidanceModal;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ClassDeleteDialogProps = {
  open: boolean;
  handleClickProceed: () => void;
  handleClose: () => void;
  classCode: string;
};

export function ClassDeleteDialog(props: ClassDeleteDialogProps) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            variant="h6"
            fontWeight={"bold"}
            sx={{ color: "warning.main" }}
          >
            {"Removing Class " + props.classCode}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <p>
              The class will no longer appear in your class list, but
              instructors for {props.classCode} will still be able to view your
              scenarios in that class.
            </p>
            <p>Are you sure you want to remove {props.classCode}?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Back</Button>
          <Button color="warning" onClick={props.handleClickProceed}>
            Proceed with removal
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';

interface Ship {
  name: string;
  bearingsTaken: [any];
  selectCount: number;
}

interface Props {
  final_ships_afloat: Ship[];
  resultant_ships_afloat?: Ship[];
}

const ContactMonTable: React.FC<Props> = ({ final_ships_afloat }) => {
  // Sort ships in ascending order based on the target names
  const sortedShips = final_ships_afloat.sort((a, b) => {
    return parseInt(a.name) - parseInt(b.name);
  });
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tgt No.</TableCell>
            <TableCell>No. Bearings</TableCell>
            <TableCell>Selected on Radar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedShips.map((ship, index) => (
            <TableRow key={index}>
              <TableCell>{ship.name}</TableCell>
              <TableCell>{ship.bearingsTaken.length}</TableCell>
              <TableCell>{ship.selectCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ContactMonTable;

import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Box, CircularProgress, Alert, Typography } from '@mui/material';
import { getProgress } from '../../clients/rotr-client';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { set } from 'lodash';

const ProgressTracker: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<any | null>(null);

  useEffect(() => {
    getProgress()
      .then((response) => {
        setProgress(response);
      })
      .catch((error) => {
        console.error('Failed to fetch game progress:', error);
        setError('Failed to fetch game progress. Please try again later.');
      });
  }, []);
  return (
    <Paper style={{}}>
      <Box
        style={{
          width: '100%',
          padding: '0 24px',
          color: 'whitesmoke',
          height: '100%',
          backgroundColor: '#1d2125'
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ backgroundColor: 'black' }}>
            <TableHead>
              <TableRow>
                <TableCell>Student Name</TableCell>
                <TableCell>1_Lights_Shapes_Basic</TableCell>
                <TableCell>2_Restricted_Visibility</TableCell>
                <TableCell>3_Sound_Light_Signals</TableCell>
                <TableCell>4_Any_Condition_of_Visibility</TableCell>
                <TableCell>
                  5_Special_Manoeuvring_Rules_Distress_signals
                </TableCell>
                <TableCell>6_Lights_Shapes_Intermediate</TableCell>
                <TableCell>7_General_Vessels_Insight</TableCell>
                <TableCell>8_Lights_Shapes_Advanced</TableCell>
                <TableCell>9_Final_Quiz</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {progress && progress.length > 0 ? (
                progress.map((row: any) => (
                  <TableRow key={row.user_id}>
                    <TableCell>{row.student_name}</TableCell>
                    <TableCell>
                      {row['1_Lights_Shapes_Basic_completed_percentage']}
                    </TableCell>
                    <TableCell>
                      {row['2_Restricted_Visibility_completed_percentage']}
                    </TableCell>
                    <TableCell>
                      {row['3_Sound_Light_Signals_completed_percentage']}
                    </TableCell>
                    <TableCell>
                      {
                        row[
                          '4_Any_Condition_of_Visibility_completed_percentage'
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      {
                        row[
                          '5_Special_Manoeuvring_Rules_Distress_signals_completed_percenta'
                        ]
                      }
                    </TableCell>
                    <TableCell>
                      {row['6_Lights_Shapes_Intermediate_completed_percentage']}
                    </TableCell>
                    <TableCell>
                      {row['7_General_Vessels_Insight_completed_percentage']}
                    </TableCell>
                    <TableCell>
                      {row['8_Lights_Shapes_Advanced_completed_percentage']}
                    </TableCell>
                    <TableCell>
                      {row['9_Final_Quiz_completed_percentage']}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align='center'>
                    No progress available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default ProgressTracker;

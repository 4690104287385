// home page

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Container,
  IconButton,
  Card,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Alert,
  AlertTitle,
  Collapse,
  Grid,
  Paper,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemIcon,
  Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NotSignedInContent from './not-signed';
import { ListUserClasses, ListUserTasks } from '../../clients/rotr-client';
import RadarIcon from '@mui/icons-material/Radar';
import ListIcon from '@mui/icons-material/List';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import * as amplitude from '@amplitude/analytics-browser';

const StyledButton = styled(Button)({
  fontWeight: 'bold'
});
const StyledContainer = styled(Container)({
  marginTop: '5rem',
  textAlign: 'center'
});

const AnimatedIcon = styled('i')({
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.2)'
  }
});

const LargeTypography = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '1rem'
});
const UserGuideCard = styled(Card)({
  marginTop: '2rem',
  padding: '2rem',
  backgroundColor: '#161A1Dff',
  color: 'whitesmoke'
});

const StepContainer = styled('div')({
  padding: '2rem 0'
});
const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepLabel-iconContainer': {
    transform: 'scale(1.8)'
  },
  '&:hover': {
    backgroundColor: '#5243AAff'
  }
});

interface ClassTaskData {
  taskID: string;
  taskType: string;
  taskName: string;
  taskPracticeLink: string;
  completed: boolean;
}

const Home = () => {
  const steps = [
    'Create a scenario in "Practice".',
    'Analyse contacts using "Radar" and "Lookout" tabs.',
    'Report to the Captain via the "Report" form.',
    'Review feedback to enhance your next report.'
  ];
  const GuideContainer = styled('div')({
    maxWidth: '80%', // Adjust as needed
    margin: '0 auto',
    textAlign: 'left'
  });

  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentUserEmail, setCurrentUserEmail] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jsonData, setJsonData] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [announcement, setAnnouncement] = useState(false);
  const [currentClass, setCurrentClass] = useState<string | undefined>(
    undefined
  );
  const [userTasks, setUserTasks] = useState<ClassTaskData[]>([]);
  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleCloseError = () => setOpenError(false);

  const GuideSection = () => {
    return (
      <UserGuideCard>
        <Typography
          variant='h5'
          align='center'
          gutterBottom
          fontWeight={'bold'}
          style={{ color: 'whitesmoke' }}
        >
          How does it work?
        </Typography>
        <GuideContainer>
          <Stepper orientation='vertical'>
            {steps.map((step, index) => (
              <Step key={index} active={true} style={{ padding: '1rem 0' }}>
                <StyledStepLabel>
                  <Typography
                    variant='body1'
                    color={'whitesmoke'}
                    marginLeft={'1rem'}
                  >
                    {step}
                  </Typography>
                </StyledStepLabel>
              </Step>
            ))}
          </Stepper>
        </GuideContainer>
      </UserGuideCard>
    );
  };

  const navigate = useNavigate();

  const navigateToPractice = () => {
    navigate('/practice');
  };

  const navigateToProfile = () => {
    navigate('/profile');
  };
  const navigateToPracticeRecite = () => {
    navigate('/practice/recite');
  };

  useEffect(() => {
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user);
        console.log(`currentAuthenticatedUser: ${user.getUsername()}`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));

    Auth.currentSession()
      .then((session) => {
        setCurrentUserEmail(session.getIdToken().payload.email);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const showNotSignedIn = () => {
    return (
      <div>
        {isLoading && <CircularProgress />}
        {currentUser === null && !isLoading && <NotSignedInContent />}
      </div>
    );
  };

  // function to get the access token from the current session
  // if the current session is null, return null
  // else return the access token
  const getAccessToken = async () => {
    const session = await Auth.currentSession();
    if (session === null) {
      return null;
    } else {
      return session.getAccessToken().getJwtToken();
    }
  };

  // call GET https://1vsx2gqh9g.execute-api.us-east-1.amazonaws.com/with_auth
  // with Authorization header
  // and log the response
  const getUserDetails = () => {
    getAccessToken().then((token) => {
      if (token !== null) {
        fetch(
          // "http://localhost:5000/api/whoami", // when working locally
          // "https://rotr-api.jmctech.com.au/api/whoami", // EBS ALB domain name
          // "https://ipxl2upwfk.execute-api.ap-southeast-2.amazonaws.com/whoami", // raw api gateway url
          // "https://rotr-api-test.jmctech.com.au/whoami", // api gateway custom domain name
          `${process.env.REACT_APP_API_URL}/whoami`,
          {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data['email']);
            setJsonData(data);
          })
          .catch((err) => {
            console.log(err);
            setErrMsg(err.message);
            setOpenError(true);
          });
      }
    });
  };

  const getUserClasses = async () => {
    try {
      const classes = await ListUserClasses();
      // see if user has a class has is_current_class set to true
      // if not, show alert

      if (
        classes.some(
          (c: { is_current_class: boolean }) => c.is_current_class === true
        )
      ) {
        setOpen(false);
        setCurrentClass(
          classes.find(
            (c: { is_current_class: boolean }) => c.is_current_class === true
          ).code
        );
      } else {
        setCurrentClass(undefined);
        setOpen(true);
      }
    } catch (error: any) {
      console.error(error);
    }
  };
  const getUserTasks = async () => {
    try {
      const tasks = await ListUserTasks();
      console.log(tasks);

      // Assuming tasks array has a 'completed' property
      const classTasks: ClassTaskData[] = tasks.map((t: any) => ({
        taskID: t.id,
        taskType: t.task_type,
        taskName: t.task_title,
        taskPracticeLink:
          '/practice/' +
          (t.task_type === 'recite' ? 'recite/' : '') +
          t.task_url,
        completed: !!t.completed_at
      }));

      // Sort tasks: Incomplete first, then complete. Limit to 5 items
      const sortedTasks = classTasks
        .sort((a, b) => Number(a.completed) - Number(b.completed))
        .slice(0, 5);

      console.log(sortedTasks);
      setUserTasks(sortedTasks);
    } catch (error) {
      console.error(error);
    }
  };

  const getTaskIcon = (taskType: string) => {
    switch (taskType) {
      case 'recite':
        return <ListIcon fontSize='large' />;
      case 'scenario':
        return <RadarIcon fontSize='large' />;
      default:
        return <RadarIcon fontSize='large' />;
    }
  };

  const handleTaskClick = (task: any) => {
    switch (task.taskType) {
      case 'recite':
        amplitude.logEvent('Started Recite Task');
        break;
      case 'scenario':
        amplitude.logEvent('Generated Task Scenario');
        break;
      default:
    }

    navigate(task.taskPracticeLink);
  };

  useEffect(() => {
    getUserClasses();
    getUserTasks();
  }, []);

  // log the user email

  return (
    <div className='main-content'>
      <Collapse in={open}>
        <Alert
          severity='warning'
          onClose={() => {
            setOpen(false);
          }}
        >
          You don't have a registered class. Please <b>register for a class</b>{' '}
          on{' '}
          <Button
            size={'small'}
            sx={{ m: 0, p: 0, minWidth: 0 }}
            onClick={navigateToProfile}
          >
            {' '}
            your profile page{' '}
          </Button>
        </Alert>
      </Collapse>
      <Collapse in={announcement}>
        <Alert
          severity='info'
          onClose={() => {
            setAnnouncement(false);
          }}
        >
          We have just upgraded our recite feature.{' '}
          <Button
            size={'small'}
            sx={{ m: 0, p: 0, minWidth: 0 }}
            onClick={navigateToPracticeRecite}
          >
            {' '}
            check it out{' '}
          </Button>
        </Alert>
      </Collapse>
      {/* grid component with the styled container taking 2/3 of screen and a table that lists the user's tasks on the remaining 1/3.
      on small screens, show them 1 on top of another */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={currentClass ? 8 : 12}>
          <StyledContainer>
            {currentUser === null ? (
              showNotSignedIn()
            ) : (
              <>
                <LargeTypography variant='h2'>
                  Let's get started
                </LargeTypography>
                <Typography variant='h6'>
                  Test your knowledge of the rules with interactive scenarios.
                </Typography>
                <Box mt={3}>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    size='large'
                    startIcon={
                      <AnimatedIcon className='fa-solid fa-radar fa-xl'></AnimatedIcon>
                    }
                    onClick={navigateToPractice}
                  >
                    Start
                  </StyledButton>
                </Box>
                <GuideSection />
              </>
            )}
          </StyledContainer>
        </Grid>
        {currentClass ? (
          <Grid item xs={12} md={4}>
            <StyledContainer>
              <LargeTypography sx={{ lineHeight: '3' }} variant='h4'>
                Your Tasks
              </LargeTypography>
              {userTasks.length > 0 ? (
                <>
                  {userTasks.map((task: ClassTaskData) => (
                    <React.Fragment key={task.taskID}>
                      {task.completed ? (
                        <>
                          <ListItemButton disableRipple>
                            <ListItemIcon style={{ color: 'grey' }}>
                              {getTaskIcon(task.taskType)}
                            </ListItemIcon>
                            <ListItemText
                              primary={task.taskName}
                              style={{ color: 'grey', marginRight: '24px' }}
                            />
                            {task.completed && (
                              <TaskAltTwoToneIcon sx={{ color: '#17ABD4' }} />
                            )}
                          </ListItemButton>
                          <Divider style={{ backgroundColor: 'white' }} />
                        </>
                      ) : (
                        <>
                          <ListItemButton onClick={() => handleTaskClick(task)}>
                            <ListItemIcon style={{ color: 'white' }}>
                              {getTaskIcon(task.taskType)}
                            </ListItemIcon>
                            <ListItemText
                              primary={task.taskName}
                              style={{ marginRight: '24px' }}
                            />
                            <Brightness1OutlinedIcon />
                          </ListItemButton>
                          <Divider style={{ backgroundColor: 'white' }} />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                'No tasks to complete.'
              )}
            </StyledContainer>
          </Grid>
        ) : null}
      </Grid>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity='error'
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Home;

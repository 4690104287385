import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

interface Detail {
  field_name: string;
  reported: string | null;
  answer: string;
  feedback_type: string;
  feedback: string;
}

interface OrganizedDetail {
  tgt_name: string;
  details: Detail[] | ReportRule[];
}

interface ReportRule {
  tgt_name?: string;
  field_name?: string;
  reported: string | null;
  answer: string;
  feedback_type: string;
  feedback: string | null;
}

interface Props {
  organizedDetails: OrganizedDetail[];
  reportRules: ReportRule[];
}

const TargetsTable: React.FC<Props> = ({ organizedDetails, reportRules }) => {
  const [combinedDetails, setCombinedDetails] = useState<OrganizedDetail[]>([]);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  useEffect(() => {
    const mergedDetails = organizedDetails.map((detail) => {
      const rule = reportRules.find((r) => r.tgt_name === detail.tgt_name);
      if (rule) {
        tidyUpRules(rule);
        return {
          ...detail,
          details: [...detail.details, rule],
        };
      }
      return detail;
    });

    setCombinedDetails(mergedDetails);
  }, [organizedDetails, reportRules]);

  function tidyUpRules(rule: ReportRule) {
    rule.field_name = 'Rules';
    if (rule.reported) rule.reported = rule.reported.replace(/{|}|"/g, '');
    rule.answer = rule.answer
      ? rule.answer.replace(/{|}|"/g, '')
      : 'no rules apply';
    rule.feedback = rulesFeedback(rule.feedback);
    delete rule.tgt_name;
  }
  function isNumeric(str: string | null) {
    if (str != null)
      return !isNaN(parseFloat(str)) && isFinite(parseFloat(str));
    else return;
  }

  function rulesFeedback(rulesFeedback: string | null) {
    interface Feedback {
      match: string[];
      notMatch: string[];
    }
    if (rulesFeedback) {
      const feedback: Feedback = JSON.parse(rulesFeedback);
      let correct: string = '';
      let incorrect: string = '';
      if (feedback.match.length > 0) {
        correct = `✅ : ${feedback.match.join(', ')}`;
      }
      if (feedback.notMatch.length > 0) {
        incorrect = `❌ : ${feedback.notMatch.join(', ')}`;
      }
      return `${correct} ${incorrect}`;
    } else return '🤷‍♂️';
  }
  function getEmojiForFeedback(
    feedback: string | null,
    field_name?: string
  ): string | undefined {
    if (field_name) {
      if (feedback === 'true') return '✅';
      if (feedback === 'false') return '❌';
      if (feedback === null) return '🤷‍♂️';
      if (isNumeric(feedback) || feedback !== null) {
        switch (field_name) {
          case 'relBearingCont':
          case 'aspectBearing': // Bearings
            return `𝚫 ${feedback} °`;
          case 'range':
          case 'cpa': //Range
            return `𝚫 ${feedback} NM`;
          case 'tcpa': //Time
            return `𝚫 ${feedback} Mins`;
        }
      }
      return feedback;
    }
  }

  function fieldNameToReportElmnt(field_name: string | undefined) {
    switch (field_name) {
      case 'relColour':
        return 'Relative Colour';
      case 'relBearingCont':
        return 'Relative Bearing';
      case 'range':
        return 'Range';
      case 'typeVis':
        return 'Vessel Type';
      case 'visual':
        return 'Held Visually';
      case 'radar':
        return 'Held by Radar';
      case 'aspectBearing':
        return 'Aspect Bearing';
      case 'aspectSide':
        return 'Aspect Side';
      case 'cpa':
        return 'CPA';
      case 'cpaPos':
        return 'CPA Position';
      case 'tcpa':
        return 'TCPA';
    }
    return field_name;
  }
  return (
    <Box>
      <Table>
        <TableBody>
          {combinedDetails.map((tgtDetail, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  <IconButton
                    color='primary'
                    onClick={() =>
                      setExpandedRow(expandedRow === index ? null : index)
                    }
                  >
                    {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                  Target No. {tgtDetail.tgt_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse
                    in={expandedRow === index}
                    timeout='auto'
                    unmountOnExit
                  >
                    <Box margin={1}>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Report Element</TableCell>
                            <TableCell>Feedback</TableCell>
                            <TableCell>Reported</TableCell>
                            <TableCell>Correct Answer</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tgtDetail.details.map((detail, dIndex) => (
                            <TableRow key={dIndex}>
                              <TableCell>
                                {fieldNameToReportElmnt(detail.field_name)}
                              </TableCell>
                              <TableCell>
                                {getEmojiForFeedback(
                                  detail.feedback,
                                  detail.field_name
                                )}
                              </TableCell>
                              <TableCell>{detail.reported}</TableCell>
                              <TableCell>{detail.answer}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TargetsTable;

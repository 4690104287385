import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import {
  DndContext,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
  DragOverlay,
  UniqueIdentifier
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import NextQuestion from './NextQuestion';
import CloseIcon from '@mui/icons-material/Close';
import { postAnswer } from '../../../clients/rotr-client';
import { UUID } from 'crypto';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

interface Option {
  optionId: string | number;
  optionContent: string;
}
interface CorrectOption {
  id: string;
  score: number;
  position: number;
  content: string;
}

interface Question {
  attemptID: UUID;
  content: string;
  instruction: string;
  mediaURL: string;
  options: Option[];
  questionID: UUID;
  questionNumber: number;
  rules: string[];
  token: string;
  totalQuestions: number;
  type: string;
}

interface RenderOrderingQuestionProps {
  currentQuestion: Question;
  currentQuestionIndex: number;
  handleNextQuestion: () => void;
  updateScore: (value: number) => void;
  setTimerRunning: (value: boolean) => void;
}

const Draggable: React.FC<{ option: Option }> = ({ option }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: option.optionId.toString()
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    backgroundColor: '#1E90FF',
    color: 'white',
    padding: '8px 16px',
    borderRadius: '5px',
    marginBottom: '10px',
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    width: '100%',
    whiteSpace: 'normal',
    textAlign: 'center',
    overflow: 'hidden'
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {option.optionContent}
    </div>
  );
};

const Droppable: React.FC<{
  id: string;
  index: number;
  children: React.ReactNode;
}> = ({ id, index, children }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data: {
      index
    }
  });

  const style: React.CSSProperties = {
    border: '2px dashed #1E90FF',
    borderRadius: '5px',
    marginBottom: '10px',
    padding: '8px',
    textAlign: 'center',
    lineHeight: '20px',
    color: 'white',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50px',
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    backgroundColor: isOver ? '#f0f0f0' : 'transparent'
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

const RenderOrderingQuestion: React.FC<RenderOrderingQuestionProps> = ({
  currentQuestion,
  currentQuestionIndex,
  handleNextQuestion,
  updateScore,
  setTimerRunning
}) => {
  const initialOptions = currentQuestion?.options || [];
  const [droppedOptions, setDroppedOptions] = useState<(Option | null)[]>(
    Array(initialOptions.length).fill(null)
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [correctAnswer, setCorrectAnswer] = useState<CorrectOption[]>([]);
  const [availableOptions, setAvailableOptions] =
    useState<Option[]>(initialOptions);
  const [showNextButton, setShowNextButton] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 50, tolerance: 5 }
    })
  );

  useEffect(() => {
    setTimerRunning(false);
    if (initialOptions.length > 0) {
      setDroppedOptions(Array(initialOptions.length).fill(null));
      setAvailableOptions(initialOptions);
    }
    setTimerRunning(true);
    setShowNextButton(false);
  }, [initialOptions]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [droppedOptions, currentQuestion.attemptID, currentQuestion.questionID]);

  if (!currentQuestion || !currentQuestion.options) return null;

  const handleDrop = (id: string, index: number) => {
    const option = availableOptions.find(
      (opt) => opt.optionId.toString() === id
    );
    if (option) {
      const alreadyDropped = droppedOptions.some(
        (opt) => opt && opt.optionId === option.optionId
      );
      if (!alreadyDropped && !droppedOptions[index]) {
        const newDroppedOptions = [...droppedOptions];
        newDroppedOptions[index] = option;
        setDroppedOptions(newDroppedOptions);
        setAvailableOptions(
          availableOptions.filter((opt) => opt.optionId !== option.optionId)
        );
      }
    }
  };

  const handleRemoveOption = (index: number) => {
    const option = droppedOptions[index];
    if (option) {
      setDroppedOptions(
        droppedOptions.map((opt, i) => (i === index ? null : opt))
      );
      setAvailableOptions([...availableOptions, option]);
    }
  };

  const handleSubmit = async () => {
    setTimerRunning(false);
    const answers = droppedOptions
      .map((opt) => {
        if (opt?.optionId && droppedOptions.indexOf(opt) !== -1) {
          return {
            id: opt?.optionId,
            position: droppedOptions.indexOf(opt) + 1
          };
        } else {
          return null;
        }
      })
      .filter((answer) => answer !== null);

    if (answers.length === 0) {
      const incorrectAnswers = currentQuestion.options.map((option, index) => ({
        id: option.optionId.toString(),
        position: index + 1,
        content: option.optionContent,
        score: 0
      }));
      setCorrectAnswer(incorrectAnswers);
      updateScore(0);
      setSubmitted(true);
      return;
    }

    const APIResponse = await postAnswer(
      currentQuestion.attemptID,
      currentQuestion.questionID,
      answers
    );
    const reorderedResponseAnswers = APIResponse.responseAnswers.sort(
      (a: any, b: any) => a.position - b.position
    );

    updateScore(APIResponse.score);
    setCorrectAnswer(reorderedResponseAnswers);
    setSubmitted(true);
    setShowNextButton(true);
  };

  const isCorrect = (
    droppedOption: Option | null,
    correctOption: CorrectOption
  ) => {
    return (
      droppedOption &&
      correctOption &&
      droppedOption.optionId === correctOption.id
    );
  };

  const allCorrect =
    submitted &&
    droppedOptions.every((option, index) =>
      isCorrect(option, correctAnswer[index])
    );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => setActiveId(active.id.toString())}
      onDragEnd={(event) => {
        const { active, over } = event;
        setActiveId(null);
        if (over && over.data && over.data.current) {
          const index = over.data.current.index;
          handleDrop(active.id.toString(), index);
        }
      }}
    >
      <Box
        sx={{
          width: '100%',
          margin: '4px 0',
          padding: '0 12px',
          textAlign: 'left',
          color: 'white',
          userSelect: 'none',
          overflowX: 'hidden',
          overflowY: 'auto',
          maxHeight: '100vh'
        }}
      >
        <Box
          sx={{
            backgroundColor: allCorrect
              ? 'green'
              : submitted
              ? 'transparent'
              : 'purple',
            padding: '20px',
            borderRadius: '10px',
            marginBottom: '10px'
          }}
        >
          <Typography variant='h6' sx={{ fontSize: '1.0rem' }}>
            <strong>{currentQuestion.content}</strong>
            <span
              style={{
                color: 'lightgray',
                marginLeft: '10px',
                fontSize: '0.875rem'
              }}
            >
              Rule {currentQuestion.rules.join(', ')}
            </span>
            {allCorrect && (
              <Typography
                variant='h4'
                sx={{ color: 'white', marginTop: '10px', fontWeight: 'bold' }}
              >
                Correct! <CheckCircleTwoToneIcon />
              </Typography>
            )}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'stretch', md: 'center' },
            marginBottom: '20px'
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', sm: '70%', md: '45%' },
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {currentQuestion.options.map((_, index) => (
              <Droppable key={index} id={`droppable-${index}`} index={index}>
                {droppedOptions[index] ? (
                  <Box
                    sx={{
                      backgroundColor: submitted
                        ? isCorrect(droppedOptions[index], correctAnswer[index])
                          ? '#32CD32'
                          : '#FF6347'
                        : '#1E90FF',
                      padding: '8px 16px',
                      borderRadius: '5px',
                      marginBottom: '10px',
                      userSelect: 'none',
                      position: 'relative',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '50px',
                      width: '100%',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                      overflow: 'hidden'
                    }}
                  >
                    {droppedOptions[index]?.optionContent}
                    {!submitted && (
                      <IconButton
                        onClick={() => handleRemoveOption(index)}
                        sx={{
                          position: 'absolute',
                          right: '5px',
                          top: '5px',
                          color: 'white'
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                ) : null}
              </Droppable>
            ))}
          </Box>

          <Box
            sx={{
              width: { xs: '100%', sm: '70%', md: '45%' },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {!submitted && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                {availableOptions.map((option) => (
                  <Draggable key={option.optionId} option={option} />
                ))}
              </Box>
            )}
            {submitted && (
              <Box sx={{ marginTop: '20px', width: '100%' }}>
                <Typography
                  variant='h6'
                  sx={{ color: 'white', marginBottom: '10px' }}
                >
                  Correct Answers:
                </Typography>
                {correctAnswer.map((answer, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: '#32CD32',
                      padding: '8px 16px',
                      borderRadius: '5px',
                      marginBottom: '10px',
                      userSelect: 'none',
                      position: 'relative',
                      textAlign: 'left',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '50px',
                      width: '100%',
                      whiteSpace: 'normal',
                      overflow: 'hidden'
                    }}
                  >
                    {answer.content}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>

        {!submitted && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}
          >
            <Button
              variant='contained'
              sx={{
                backgroundColor: 'purple',
                '&:hover': {
                  backgroundColor: '#800080'
                }
              }}
              onClick={handleSubmit}
              style={{ userSelect: 'none' }}
            >
              Submit
            </Button>
          </Box>
        )}

        {submitted && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}
          >
            <NextQuestion onClick={handleNextQuestion} />
          </Box>
        )}
      </Box>
      <DragOverlay>
        {activeId ? (
          <div
            style={{
              backgroundColor: '#1E90FF',
              color: 'white',
              padding: '8px 16px',
              borderRadius: '5px',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '50px',
              width: '100%',
              whiteSpace: 'normal',
              textAlign: 'center',
              overflow: 'hidden'
            }}
          >
            {
              availableOptions.find(
                (opt) => opt.optionId.toString() === activeId
              )?.optionContent
            }
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default RenderOrderingQuestion;

import React from 'react';
import { Card, CardContent, Typography, Icon, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

interface Feedback {
  feedback_nature: 'correct' | 'warning' | 'error';
  text: string;
}

interface FeedbackCardProps {
  feedback: Feedback;
}

function FeedbackCard({ feedback }: FeedbackCardProps) {
  // Determine the card color based on feedback type
  const getCardColor = (type: Feedback['feedback_nature']): string => {
    switch (type) {
      case 'correct':
        return '#035c7471';
      case 'warning':
        return '#ba8300';
      case 'error':
        return '#ba1900';
      default:
        return 'white';
    }
  };

  const getCardIcon = (type: Feedback['feedback_nature']) => {
    switch (type) {
      case 'correct':
        return <CheckIcon fontSize='large' />;
      case 'warning':
        return <WarningIcon fontSize='large' />;
      case 'error':
        return <ErrorIcon fontSize='large' />;
      default:
        return null;
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: getCardColor(feedback.feedback_nature),
        margin: 2,
        maxWidth: 500, // max width set to 500px
        width: '100%', // fill container width
        color: 'whitesmoke',
      }}
    >
      <CardContent sx={{ padding: '16px 24px' }}>
        <Stack direction='row' spacing={2} alignItems='flex-start'>
          <Icon style={{ marginRight: 10, overflow: 'visible' }}>
            {getCardIcon(feedback.feedback_nature)}
          </Icon>
          <Typography variant='body1' textAlign='left'>
            {feedback.text}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

interface FeedbackListProps {
  feedbacks: Feedback[];
}

function AppFeedbackList({ feedbacks }: FeedbackListProps) {
  return (
    <>
      {feedbacks.map((feedback, index) => (
        <FeedbackCard key={index} feedback={feedback} />
      ))}
    </>
  );
}

export default AppFeedbackList;

import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
const ProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const authorizeUrl = process.env.REACT_APP_AUTHORIZER_URL;
    const appClientId = process.env.REACT_APP_CLIENT_ID;
    const signInRedirectUrl = process.env.REACT_APP_SIGNIN_CALLBACK;
    const signInLink = `${authorizeUrl}?client_id=${appClientId}&response_type=code&scope=email+openid+phone&redirect_uri=${signInRedirectUrl}`;

    const checkUserToken = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsLoggedIn(true);
        } catch (error) {
            console.error("User is not authenticated", error);
            // navigate('/login');
            window.location.replace(signInLink);
        }
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;
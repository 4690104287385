import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';

interface StreakScoreProps {
  completed: number | null;
  total: number | null;
}

const SectionCount: React.FC<StreakScoreProps> = ({ completed, total }) => {
  if (completed && total) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'flex-end', marginLeft: '10px' }}
      >
        <Tooltip title='Sections Completed' placement='top'>
          <IconButton>
            <LibraryBooksTwoToneIcon
              sx={{
                color: '#1286A6',
                fontSize: '1em',
                lineHeight: '0px'
              }}
            />
          </IconButton>
        </Tooltip>
        <span
          style={{
            lineHeight: '40px',
            fontSize: '1em',
            textWrap: 'nowrap'
          }}
        >
          <strong>
            {completed} / {total}
          </strong>
        </span>
      </div>
    );
  } else {
    return null;
  }
};

export default SectionCount;

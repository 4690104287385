import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContactFormState {
  email: string;
  message: string;
}

const Help: React.FC = () => {
  const [formData, setFormData] = useState<ContactFormState>({
    email: '',
    message: '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    // TODO: Implement email sending logic here.
    console.log('Sending email:', formData);
  };

  return (
    <div className='main-content'>
      <Box p={8} display="flex" flexDirection="column" alignItems="center">
        <Typography variant='h4' gutterBottom>
          Help & FAQ
        </Typography>
        <Typography variant='h6' gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#00FFFF', fontSize: '2rem', stroke: '#00FFFF', strokeWidth: 1.5 }} />}
          >
            <Typography>Why can't I hear fog signals?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              You need to select the "lookout" tab to start to hear fog signals.
              If this still doesn't work make sure that the page in unmuted in
              your browsers and you volume is turned up.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#00FFFF', fontSize: '2rem', stroke: '#00FFFF', strokeWidth: 1.5 }} />}
          >
            <Typography>How do I see the details for a radar contact?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              Click or tap on a radar contact and the details will be displayed
              in the information panel.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#00FFFF', fontSize: '2rem', stroke: '#00FFFF', strokeWidth: 1.5 }} />}
          >
            <Typography>What do I do if an error message appears?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              If you encounter an error or are unable to complete a report, view
              a scenario, or see feedback, please click or tap the arrow at the
              bottom of the screen and complete the form. Include as much detail
              as possible, such as the time (including timezone), specific error
              details, and the action you were attempting when the error
              occurred.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#00FFFF', fontSize: '2rem', stroke: '#00FFFF', strokeWidth: 1.5 }} />}
          >
            <Typography>Why is there data missing from my feedback?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              If you do not provide all the required information in the report
              form, we will be unable to give you complete feedback. Please note
              that you will not receive a reminder if any section of your report
              is incomplete. To ensure comprehensive feedback every time, make
              sure to double-check that your report form is fully filled out.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default Help;

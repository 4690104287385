import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

type noReportsAlertDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleClickReportForm: () => void;
};

export function NoReportsAlertDialog(props: noReportsAlertDialogProps) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography
            variant='h6'
            fontWeight={'bold'}
            sx={{ color: 'warning.main' }}
          >
            {`Failed to submit`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <p>You must create at least 1 report.</p>
            <Button onClick={props.handleClickReportForm}>Start Report</Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

// define an HTTP client that fetchs from the API Gateway endpoint

import userEvent from '@testing-library/user-event';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { request } from 'http';
import { DiffWordResult } from '../components/recite-practice/types';
import { UUID } from 'crypto';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// function to get the access token from the current session
// if the current session is null, return null
// else return the access token
export const getAccessToken = async () => {
  try {
    const session = await Auth.currentSession();
    if (session === null) {
      return null;
    } else {
      return session.getAccessToken().getJwtToken();
    }
  } catch (error) {
    console.log(error);
    throw new Error('User is not signed in');
  }
};

// GET scenario
export const getScenario = async (body: any) => {
  try {
    const token = await getAccessToken();

    console.log('sending: ', body);
    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/scenarios/scenario',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: body
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// send scenario answer
export const sendScenarioAnswer = async (answer: any, message: any) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/scenarios/answer',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: { answer: answer, scenarioViewerSnapShot: message }
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ListUserClasses = async (
  role: string = 'all',
  userId: string | undefined = undefined
) => {
  try {
    const token = await getAccessToken();

    let params: any = {
      role: role
    };
    if (userId) {
      params['userId'] = userId;
    }

    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/users/classes',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: params
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// TODO - ListUserTasks with specified student ID for instructors
export const ListUserTasks = async (
  user_id: string = '',
  class_id: string = ''
) => {
  try {
    const token = await getAccessToken();

    console.log('user_id: ', user_id);
    console.log('class_id: ', class_id);

    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/users/tasks',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: {
        user_id: user_id,
        class_id: class_id
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const ListStudentsInClass = async (classID: string) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url:
        process.env.REACT_APP_API_URL +
        '/users/classes/' +
        classID +
        '/students',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const ListInstructorsInClass = async (classID: string) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url:
        process.env.REACT_APP_API_URL +
        '/users/classes/' +
        classID +
        '/instructors',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const SetUserCurrentClass = async (
  user_id: string,
  class_id: string
) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/users/class/current',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: { user_id, class_id }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const DeleteUserClass = async (user_id: string, class_code: string) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'delete',
      url: process.env.REACT_APP_API_URL + '/users/class',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: { user_id, class_code }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const RegisterUserClass = async (
  user_id: string,
  class_code: string,
  class_role: string = 'student'
) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/users/class',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: { user_id, class_code, class_role }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetClassDetails = async (classCode: string) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/users/classes/' + classCode,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Get feedback and response data using scenario_repsonse_id

interface FeedbackData {
  organizedDetails: any;
  reportRules: any;
  reportStatements: any;
  scenarioResponse: any;
  rulesApplicationFeedback: any;
  explanation: any;
}

export const getFeedbackFromResponseID = async (
  responseId: string
): Promise<FeedbackData> => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/feedback/${responseId}/scenario`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      throw new Error(`Request failed with status: ${response.status}`);
    }
  } catch (error: any) {
    console.error('Error fetching feedback:', error.message);
    throw error;
  }
};

//  declare parameter for getFeedbackSummary
interface FeedbackSummaryParams {
  pageSize?: number;
  page?: number;
  context?: string;
  environment?: string;
  started_at?: string;
  ended_at?: string;
  user_id?: string;
}

// Get feedback summary with optional pageSize, offset, context and environment variables
export const getFeedbackSummary = async (params: FeedbackSummaryParams) => {
  const {
    pageSize,
    page,
    context,
    environment,
    started_at,
    ended_at,
    user_id
  } = params;
  try {
    const token = await getAccessToken();

    let requestParams = {};
    if (pageSize !== undefined) {
      requestParams = { ...requestParams, page_size: pageSize };
    }
    if (page !== undefined) {
      requestParams = { ...requestParams, page: page };
    }
    if (context !== undefined) {
      requestParams = { ...requestParams, context: context };
    }
    if (environment !== undefined) {
      requestParams = { ...requestParams, environment: environment };
    }
    if (started_at !== undefined) {
      requestParams = { ...requestParams, started_at: started_at };
    }
    if (ended_at !== undefined) {
      requestParams = { ...requestParams, ended_at: ended_at };
    }
    if (user_id !== undefined && user_id !== '') {
      requestParams = { ...requestParams, user_id: user_id };
    }

    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/feedback/summaries',
      params: requestParams,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getFeedbackSummaryStatistics = async (
  params: FeedbackSummaryParams
) => {
  const { context, environment, started_at, ended_at, user_id } = params;
  try {
    const token = await getAccessToken();

    let requestParams = {};
    if (context !== undefined) {
      requestParams = { ...requestParams, context: context };
    }
    if (environment !== undefined) {
      requestParams = { ...requestParams, environment: environment };
    }
    if (started_at !== undefined) {
      requestParams = { ...requestParams, started_at: started_at };
    }
    if (ended_at !== undefined) {
      requestParams = { ...requestParams, ended_at: ended_at };
    }
    if (user_id !== undefined && user_id !== '') {
      requestParams = { ...requestParams, user_id: user_id };
    }

    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/feedback/summaries/statistics',
      params: requestParams,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

type DiffResultArray = DiffWordResult[];

export const GetOrganisationByAdmin = async () => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/organisation/admin',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetClassesByOrgId = async (org_id: string) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/organisation/${org_id}/classes`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const GetUsersByOrgId = async (org_id: string) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/organisation/${org_id}/users`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const ResetUserPassword = async (
  org_id: string,
  username: string,
  password: string
) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/organisation/${org_id}/user/${username}/reset-password`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        password: password
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const AddClassToOrg = async (org_id: string, classCode: string) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/organisation/${org_id}/class`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        classCode
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const UpdateClass = async (
  org_id: string,
  classId: string,
  classCode: string
) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/organisation/${org_id}/class/${classId}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        classCode
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export interface NewUserModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  classId: string;
  role: string;
  admin: boolean;
}

export const CreateNewUser = async (org_id: string, newUser: NewUserModel) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/organisation/${org_id}/user`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        password: newUser.password,
        classId: newUser.classId,
        role: newUser.role,
        admin: newUser.admin
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const postReciteFeedback = async (
  ruleID: string,
  diffResult: DiffResultArray,
  accuracy: number,
  cloze_percentage: number
) => {
  try {
    const token = await getAccessToken();

    // Create the request body
    let requestBody = {};
    if (ruleID !== undefined) {
      requestBody = { ...requestBody, rule_id: ruleID };
    }
    if (diffResult !== undefined) {
      requestBody = { ...requestBody, diff_result: diffResult };
    }
    if (accuracy !== undefined) {
      requestBody = { ...requestBody, accuracy: accuracy };
    }

    if (cloze_percentage !== undefined) {
      requestBody = { ...requestBody, cloze_percentage: cloze_percentage };
    }

    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/feedback/recite',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: requestBody
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateReciteFeedback = async (
  id: UUID,
  diffResult: DiffResultArray,
  accuracy: number
) => {
  try {
    const token = await getAccessToken();

    // Create the request body
    let requestBody = {};
    if (diffResult !== undefined) {
      requestBody = { ...requestBody, diff_result: diffResult };
    }
    if (accuracy !== undefined) {
      requestBody = { ...requestBody, accuracy: accuracy };
    }

    const response = await axios({
      method: 'patch',
      url: process.env.REACT_APP_API_URL + '/feedback/recite/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: requestBody
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getReciteFeedbackSummary = async (
  ruleId: string,
  cloze_percentage: number
) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/feedback/recite/summary/',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: {
        rule_id: ruleId,
        cloze_percentage: cloze_percentage
      }
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const connectToTranscriptionWebSocket = async (): Promise<WebSocket> => {
  return new Promise(async (resolve, reject) => {
    const ws: WebSocket = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/rule/transcribe`
    );

    ws.onerror = (event: Event): void => {
      console.error('WebSocket error:', event);
      reject(new Error('WebSocket error'));
    };

    ws.onclose = (event: CloseEvent): void => {
      console.log('WebSocket connection closed:', event);
      console.log('Close code:', event.code);
      console.log('Close reason:', event.reason);
    };

    resolve(ws);
  });
};

/// Shake Down Game API
export const getStepQuestion = async (
  stepId: UUID | undefined,
  attemptToken: string | null
) => {
  try {
    const token = await getAccessToken();
    console.log(attemptToken);
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/steps/${stepId}/question`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        token: attemptToken
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getGame = async () => {
  try {
    const token = await getAccessToken();
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const postAnswer = async (
  attemptId: UUID,
  questionId: UUID,
  answers: any
) => {
  try {
    const token = await getAccessToken();
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/attempts/${attemptId}/answer/${questionId}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        answers: answers
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getBeginStep = async (
  stepId: UUID | undefined,
  attemptToken: string | null
) => {
  try {
    const token = await getAccessToken();
    console.log(attemptToken);
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/steps/${stepId}/intro`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        token: attemptToken
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getMode = async (stepId: UUID | undefined) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/steps/${stepId}/mode`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const stepCompleted = async (
  attemptId: UUID | undefined,
  timeTakenSecs: Number | undefined
) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/attempts/${attemptId}/complete`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        timeTakenSecs: timeTakenSecs
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getScoreBoard = async (stepId: UUID | undefined) => {
  try {
    const token = await getAccessToken();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/steps/${stepId}/scoreboard`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStepAttempt = async (attemptId: UUID | undefined) => {
  try {
    const token = await getAccessToken();
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/attempts/${attemptId}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const getProgress = async () => {
  try {
    const token = await getAccessToken();
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/games/shakedown/progress`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Button, Stack, Tooltip } from '@mui/material';
import FlagCircleTwoToneIcon from '@mui/icons-material/FlagCircleTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import Badge from '@mui/material/Badge';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarExplanationModal from './StarExplainationModal';

interface GameSectionBodyProps {
  steps: Array<Step>;
  starCount: number;
}

interface CompletionStatus {
  completed: boolean;
  completed_at: string;
}

interface Step {
  id: string;
  name: string;
  completionStatus: CompletionStatus;
}

const renderStars = (starCount: number) => {
  const shadowStyle = {
    filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6))'
  };
  const colors = {
    1: '#E09663',
    2: '#D9D9D9',
    3: '#FCE56F'
  };

  switch (starCount) {
    case 0:
      return [
        <StarBorderIcon key='star1' />,
        <StarBorderIcon key='star2' />,
        <StarBorderIcon key='star3' />
      ];
    case 1:
      return [
        <StarIcon key='star1' style={{ ...shadowStyle, color: colors[1] }} />,
        <StarBorderIcon key='star2' />,
        <StarBorderIcon key='star3' />
      ];
    case 2:
      return [
        <StarIcon key='star1' style={{ ...shadowStyle, color: colors[1] }} />,
        <StarIcon key='star2' style={{ ...shadowStyle, color: colors[2] }} />,
        <StarBorderIcon key='star3' />
      ];
    case 3:
      return [
        <StarIcon key='star1' style={{ ...shadowStyle, color: colors[1] }} />,
        <StarIcon key='star2' style={{ ...shadowStyle, color: colors[2] }} />,
        <StarIcon key='star3' style={{ ...shadowStyle, color: colors[3] }} />
      ];
    default:
      return [
        <StarBorderIcon key='star1' />,
        <StarBorderIcon key='star2' />,
        <StarBorderIcon key='star3' />
      ];
  }
};

const renderSteps = (
  steps: Array<Step>,
  starCount: number,
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleCloseModal: () => void
) => {
  return steps.map((step, i) => {
    const isFinalStep = i === steps.length - 1;
    const allPreviousCompleted = steps
      .slice(0, i)
      .every((prevStep) => prevStep.completionStatus.completed);

    // Determine the margin for alternating steps
    let marginStyle = {};
    if (i !== 0 && !isFinalStep) {
      // Exclude first and last step
      marginStyle =
        i % 2 === 0 ? { marginLeft: '40px' } : { marginRight: '40px' };
    }

    const stepContent = (
      <>
        {isFinalStep ? (
          allPreviousCompleted ? (
            <Stack spacing={2} alignItems='center'>
              <Link
                to={`/games/shake-down/steps/${step.id}/intro`}
                key={i}
                style={{
                  textDecoration: 'none',
                  ...marginStyle
                }}
              >
                <IconButton
                  key={i}
                  sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    ...marginStyle
                  }}
                >
                  <EmojiEventsTwoToneIcon
                    sx={{
                      fontSize: 60,
                      color: step.completionStatus.completed
                        ? '#1bc8f8bc'
                        : 'grey'
                    }}
                  />
                </IconButton>
              </Link>
              <div
                style={{
                  marginLeft: '8px',
                  display: 'flex',
                  flexWrap: 'nowrap'
                }}
              >
                <StarExplanationModal
                  open={isModalOpen}
                  onClose={handleCloseModal}
                />
                {renderStars(starCount)}
                <Tooltip title='How do star achievements work?' arrow>
                  <InfoOutlinedIcon
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                    style={{ color: 'whitesmoke', marginLeft: '10px' }}
                  />
                </Tooltip>
              </div>
              <Button
                variant='contained'
                color='primary'
                startIcon={<LeaderboardIcon />}
                component={Link}
                to={`/games/shake-down/scoreboard/${step.id}`}
                sx={{
                  textDecoration: 'none',
                  backgroundColor: '#1bc8f8bc', // Use a theme color or a color that stands out
                  '&:hover': {
                    backgroundColor: '#147c9e' // Darker shade on hover for better interaction feedback
                  }
                }}
              >
                View Scoreboard
              </Button>
            </Stack>
          ) : (
            <Tooltip
              title='Unlock by completing all previous training sessions'
              placement='top'
              arrow
            >
              <LockTwoToneIcon
                sx={{
                  fontSize: 60,
                  color: 'grey'
                }}
              />
            </Tooltip>
          )
        ) : (
          <Link
            to={`/games/shake-down/steps/${step.id}/intro`}
            key={i}
            style={{
              textDecoration: 'none',
              ...marginStyle
            }}
          >
            <IconButton
              key={i}
              sx={{ marginTop: '10px', marginBottom: '10px', ...marginStyle }}
            >
              <Tooltip title={step.name} arrow>
                <FlagCircleTwoToneIcon
                  sx={{
                    fontSize: 60,
                    color: step.completionStatus.completed
                      ? '#1bc8f8bc'
                      : 'grey'
                  }}
                />
              </Tooltip>
            </IconButton>
          </Link>
        )}
      </>
    );

    return <React.Fragment key={i}>{stepContent}</React.Fragment>;
  });
};

const GameSectionBody: React.FC<GameSectionBodyProps> = ({
  steps,
  starCount
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        marginTop='10px'
      >
        {renderSteps(
          steps,
          starCount,
          isModalOpen,
          setIsModalOpen,
          handleCloseModal
        )}
      </Box>
    </div>
  );
};

export default GameSectionBody;
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Intentions,
  ReportDetails,
  alterCrseValues,
  alterSpeedValues,
  aspectSideValues,
  bearingDirectionValues,
  cpaPosValues,
  relColourValues,
  typeVisValues,
  Agreement,
  Rereport,
  ScenarioAnswer,
  SavedReport
} from './common';
import SpeechToText from './SpeechToText';
import { ReportDetailsPanel } from './ReportDetailsPanel';
import { IntentionsPanel } from './IntentionsPanel';
import { AlertDialogSlide } from './AlertDialog';
import { DeleteReportAlertDialog } from './DeleteReportAlertDialog';
import { StageProgressionAlertDialog } from './StageProgressionAlertDialog';
import { NoReportsAlertDialog } from './noReportsAlertDialog';
import { useTheme } from '@mui/material/styles';

// define a type that has a field "rule" that is string, and another field called subrules that is an array of strings
// need a rule number, a rule name, and a list of subrules
type Rule = {
  rule: string;
  shortId: string;
  fullyQualifiedId: string;
  checked: boolean;
  subrules: Rule[];
  expanded: boolean; //whether section is expanded, only applicable if subrules is not empty
};

var rules: Rule[] = [
  {
    rule: 'Narrow Channels',
    shortId: '9.',
    fullyQualifiedId: '9',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: 'A vessel proceeding along ...',
        shortId: '(a)',
        fullyQualifiedId: '9-a',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel of less ...',
        shortId: '(b)',
        fullyQualifiedId: '9-b',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel engaged in ...',
        shortId: '(c)',
        fullyQualifiedId: '9-c',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel shall not ...',
        shortId: '(d)',
        fullyQualifiedId: '9-d',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: '',
        shortId: '(e)',
        fullyQualifiedId: '9-e',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'In a narrow channel...',
            shortId: '(i)',
            fullyQualifiedId: '9-e-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'This Rules does not...',
            shortId: '(ii)',
            fullyQualifiedId: '9-e-ii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'A vessel nearing a...',
        shortId: '(f)',
        fullyQualifiedId: '9-f',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel shall...',
        shortId: '(g)',
        fullyQualifiedId: '9-g',
        checked: false,
        expanded: false,
        subrules: []
      }
    ]
  },
  {
    rule: 'Traffic Separation Schemes',
    shortId: '10.',
    fullyQualifiedId: '10',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: 'This Rule applies to ...',
        shortId: '(a)',
        fullyQualifiedId: '10-a',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel using ...',
        shortId: '(b)',
        fullyQualifiedId: '10-b',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'Proceed in the appropriate ...',
            shortId: '(i)',
            fullyQualifiedId: '10-b-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'So far as is practicable ...',
            shortId: '(ii)',
            fullyQualifiedId: '10-b-ii',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'Normally join or leave a ...',
            shortId: '(iii)',
            fullyQualifiedId: '10-b-iii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'A vessel shall ...',
        shortId: '(c)',
        fullyQualifiedId: '10-c',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: '',
        shortId: '(d)',
        fullyQualifiedId: '10-d',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'A vessel shall not...',
            shortId: '(i)',
            fullyQualifiedId: '10-d-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'Notwithstanding subparagraph (d)(i)...',
            shortId: '(ii)',
            fullyQualifiedId: '10-d-ii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'A vessel other than...',
        shortId: '(e)',
        fullyQualifiedId: '10-e',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'in case of emergency...',
            shortId: '(i)',
            fullyQualifiedId: '10-e-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'to engage in fishing',
            shortId: '(ii)',
            fullyQualifiedId: '10-e-ii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'A vessel navigating...',
        shortId: '(f)',
        fullyQualifiedId: '10-f',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel shall...',
        shortId: '(g)',
        fullyQualifiedId: '10-g',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel not using...',
        shortId: '(h)',
        fullyQualifiedId: '10-h',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel engaged in...',
        shortId: '(i)',
        fullyQualifiedId: '10-i',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel of less...',
        shortId: '(j)',
        fullyQualifiedId: '10-j',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel restricted...',
        shortId: '(k)',
        fullyQualifiedId: '10-k',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel restricted...',
        shortId: '(l)',
        fullyQualifiedId: '10-l',
        checked: false,
        expanded: false,
        subrules: []
      }
    ]
  },
  {
    rule: 'Sailing Vessels',
    shortId: '12.',
    fullyQualifiedId: '12',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: 'When two sailing vessels ...',
        shortId: '(a)',
        fullyQualifiedId: '12-a',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'when each has the ...',
            shortId: '(i)',
            fullyQualifiedId: '12-a-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'when both have the...',
            shortId: '(ii)',
            fullyQualifiedId: '12-a-ii',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'if a vessel with ...',
            shortId: '(iii)',
            fullyQualifiedId: '12-a-iii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'For the purposes of ...',
        shortId: '(b)',
        fullyQualifiedId: '12-b',
        checked: false,
        expanded: false,
        subrules: []
      }
    ]
  },
  {
    rule: 'Overtaking',
    shortId: '13.',
    fullyQualifiedId: '13',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: 'Notwithstanding anything contained ...',
        shortId: '(a)',
        fullyQualifiedId: '13-a',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel shall be ...',
        shortId: '(b)',
        fullyQualifiedId: '13-b',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'When a vessel is in ...',
        shortId: '(c)',
        fullyQualifiedId: '13-c',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'Any subsequent alteration of ...',
        shortId: '(d)',
        fullyQualifiedId: '13-d',
        checked: false,
        expanded: false,
        subrules: []
      }
    ]
  },
  {
    rule: 'Head-on',
    shortId: '14.',
    fullyQualifiedId: '14',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: 'When two power-driven ...',
        shortId: '(a)',
        fullyQualifiedId: '14-a',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'Such a situation shall ...',
        shortId: '(b)',
        fullyQualifiedId: '14-b',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'When a vessel is in ...',
        shortId: '(c)',
        fullyQualifiedId: '14-c',
        checked: false,
        expanded: false,
        subrules: []
      }
    ]
  },
  {
    rule: 'Crossing Situation',
    shortId: '15.',
    fullyQualifiedId: '15',
    checked: false,
    expanded: false,
    subrules: []
  },
  {
    rule: 'Action by giveway vessel',
    shortId: '16.',
    fullyQualifiedId: '16',
    checked: false,
    expanded: false,
    subrules: []
  },
  {
    rule: 'Action by stand-on vessel',
    shortId: '17.',
    fullyQualifiedId: '17',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: '',
        shortId: '(a)',
        fullyQualifiedId: '17-a',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'Where one of two ...',
            shortId: '(i)',
            fullyQualifiedId: '17-a-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'The latter vessel may ...',
            shortId: '(ii)',
            fullyQualifiedId: '17-a-ii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'When, from any cause ... ',
        shortId: '(b)',
        fullyQualifiedId: '17-b',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A power-driven vessel ...',
        shortId: '(c)',
        fullyQualifiedId: '17-c',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'This Rule does not  ...',
        shortId: '(d)',
        fullyQualifiedId: '17-d',
        checked: false,
        expanded: false,
        subrules: []
      }
    ]
  },
  {
    rule: 'Responsibilities between vessels',
    shortId: '18.',
    fullyQualifiedId: '18',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: 'A power-driven vessel underway ...',
        shortId: '(a)',
        fullyQualifiedId: '18-a',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A sailing vessel underway ... ',
        shortId: '(b)',
        fullyQualifiedId: '18-b',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel engaged in fishing ...',
        shortId: '(c)',
        fullyQualifiedId: '18-c',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: '',
        shortId: '(d)',
        fullyQualifiedId: '18-d',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'Any vessel other than ...',
            shortId: '(i)',
            fullyQualifiedId: '18-d-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'A vessel constrained ...',
            shortId: '(ii)',
            fullyQualifiedId: '18-d-ii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'A seaplane on the ...',
        shortId: '(e)',
        fullyQualifiedId: '18-e',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: '',
        shortId: '(f)',
        fullyQualifiedId: '18-f',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'A WIG craft shall ...',
            shortId: '(i)',
            fullyQualifiedId: '18-f-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'A WIG craft operating ...',
            shortId: '(ii)',
            fullyQualifiedId: '18-f-ii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      }
    ]
  },
  {
    rule: 'Restricted Visibilty',
    shortId: '19.',
    fullyQualifiedId: '19',
    checked: false,
    expanded: false,
    subrules: [
      {
        rule: 'This Rule applies to  ...',
        shortId: '(a)',
        fullyQualifiedId: '19-a',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'Every vessel shall proceed ... ',
        shortId: '(b)',
        fullyQualifiedId: '19-b',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'Every vessel shall have ...',
        shortId: '(c)',
        fullyQualifiedId: '19-c',
        checked: false,
        expanded: false,
        subrules: []
      },
      {
        rule: 'A vessel which detects ... ',
        shortId: '(d)',
        fullyQualifiedId: '19-d',
        checked: false,
        expanded: false,
        subrules: [
          {
            rule: 'an alteration of course ...',
            shortId: '(i)',
            fullyQualifiedId: '19-d-i',
            checked: false,
            expanded: false,
            subrules: []
          },
          {
            rule: 'an alteration of course ...',
            shortId: '(ii)',
            fullyQualifiedId: '19-d-ii',
            checked: false,
            expanded: false,
            subrules: []
          }
        ]
      },
      {
        rule: 'Except where it has ...',
        shortId: '(e)',
        fullyQualifiedId: '19-e',
        checked: false,
        expanded: false,
        subrules: []
      }
    ]
  }
];

// define props for Answer component
interface AnswerProps {
  targets: string[];
  openDialog: boolean;
  scenarioId: string;
  setOpenDialog: (open: boolean) => void;
  submitAnswer: (answer: ScenarioAnswer) => void;
  resVis: boolean;
}

const makeDefaultReportDetails = (numbersUndefined: boolean): ReportDetails => {
  const defaultNumber = numbersUndefined ? undefined : 0;
  return {
    relColour: undefined,
    relBearingCont: '',
    range: '',
    typeVis: undefined,
    visual: false,
    radar: false,
    aspectBearing: '',
    aspectSide: undefined,
    bearingDirection: undefined,
    cpa: '',
    cpaPos: undefined,
    tcpa: ''
  };
};

const makeDefaultIntentions = (): Intentions => {
  return {
    alterCrse: undefined,
    crseChange: '',
    alterSpeed: undefined,
    speedChange: '',
    rereports: [],
    agreements: []
  };
};

const makeDefaultAgreement = (): Agreement => {
  return {
    contact: undefined,
    noCloserThan: undefined
  };
};

const makeDefaultRereport = (): Rereport => {
  return {
    contact: undefined,
    at: undefined
  };
};

const makeScenarioAnswer = (
  intentions: Intentions,
  reports: SavedReport[]
): ScenarioAnswer => {
  return {
    intentions: intentions,
    reports: reports
  };
};

//TODO - on new scenario, we should clear all saved reports and reset the step to 0
export const Answer = ({
  openDialog,
  targets,
  scenarioId,
  setOpenDialog,
  submitAnswer,
  resVis
}: AnswerProps) => {
  const [speechToTextData, setSpeechToTextData] = useState({});

  const [activeStep, setActiveStep] = useState(0);
  const [targetShip, setTargetShip] = useState('');
  const [displayedRules, setDisplayedRules] = useState(rules);
  const [savedReports, setSavedReports] = useState<SavedReport[]>([]);
  const [checkedRules, setCheckedRules] = useState<string[]>([]);
  const [displayedReportDetails, setDisplayedReportDetails] =
    useState<ReportDetails>(makeDefaultReportDetails(true));
  const [expandReportDetails, setExpandReportDetails] = useState(false);
  const [expandApplyRules, setExpandApplyRules] = useState(false);
  const [displayedIntentions, setDisplayedIntentions] = useState<Intentions>(
    makeDefaultIntentions()
  );
  const [unsavedReport, setUnsavedReport] = useState(false);
  const [openSaveAlert, setOpenSaveAlert] = useState(false);
  const [openNoReportsAlert, setOpenNoReportsAlert] = useState(false);
  const [newTarget, setNewTarget] = useState('');
  const [openDeleteReportAlert, setOpenDeleteReportAlert] = useState(false);
  const [shipToDelete, setShipToDelete] = useState('');
  const [openStageProgressionAlert, setOpenStageProgressionAlert] =
    useState(false);

  // save alert handlers
  const handleSaveAlertClose = () => setOpenSaveAlert(false);

  const smallScreen = useMediaQuery('(max-width: 899px)');

  // report details handlers
  const handleReportDetailsExpandChange = () =>
    setExpandReportDetails(!expandReportDetails);
  const handleApplyRulesExpandChange = () =>
    setExpandApplyRules(!expandApplyRules);

  const handleReportDetailsNumberFieldChange = (event: any) => {
    const id = event.target.id;

    // Custom validation to allow decimal input
    const newValue = event.target.value;
    console.log('newValue: ' + newValue);

    let isValid = false;

    // relBearingCont and aspectBearing are integers and must be between 0 and 180
    // range is a positive number with at most 1 decimal point
    // CPA 1 dp always positive, TCPA 1 dp can be positive or negative.
    switch (id) {
      case 'relBearingCont':
      case 'aspectBearing':
        isValid = /^(?:|0|[1-9][0-9]?|1[0-7][0-9]|180)$/.test(newValue);
        break;

      case 'range':
      case 'cpa':
        isValid = /^(?:\d{1,5}(\.\d{0,1})?)?$/.test(newValue);
        break;

      case 'tcpa':
        isValid = /^-?\d{0,5}(\.\d{0,1})?$|^99999\.9$/.test(newValue);
        break;

      default:
        break;
    }

    console.log('isValid: ' + isValid);
    if (isValid) {
      setDisplayedReportDetails({
        ...displayedReportDetails,
        [id]: event.target.value
      });
      setUnsavedReport(true);
    }
  };

  const handleReportDetailsNumberFieldBlur = (value: any, field: any) => {
    // cast the field to a number
    const numValue = Number(value);
    console.log(`User left the text field ${field} with ${value}(${numValue})`);
    // if the value is not a number, set it to 0
    if (isNaN(numValue) || value === '' || value === undefined) {
      setDisplayedReportDetails({
        ...displayedReportDetails,
        [field]: ''
      });
    } else {
      // if the value is a number, round it to 1 decimal place
      setDisplayedReportDetails({
        ...displayedReportDetails,
        [field]: numValue
      });
    }
  };

  const handleReportDetailsSelectionChange = (event: any) => {
    const id = event.target.name;
    setDisplayedReportDetails({
      ...displayedReportDetails,
      [id]: event.target.value
    });
    setUnsavedReport(true);
  };

  const handleReportDetailsCheckboxClick = (event: any) => {
    const id = event.target.id;
    setDisplayedReportDetails({
      ...displayedReportDetails,
      [id]: event.target.checked
    });
    setUnsavedReport(true);
  };
  const handleSpeechToTextData = (newData: ReportDetails | null) => {
    console.log('New data from SpeechToText:', newData);

    if (newData) {
      setSpeechToTextData(newData);
      setDisplayedReportDetails({ ...displayedReportDetails, ...newData });
    }
  };

  // intentions screen handlers
  const handleIntentionsSelectionChange = (event: any) => {
    const id = event.target.name;
    setDisplayedIntentions({
      ...displayedIntentions,
      [id]: event.target.value
    });
  };

  const handleIntentionsNumberFieldChange = (event: any) => {
    const id = event.target.id;

    // Custom validation
    const newValue = event.target.value;
    // console.log("newValue: " + newValue);

    let isValid = false;
    // crseChange is an integer and must be between 0 and 180
    // alterSpeed is a positive integer
    let oldValue: any = 0;
    switch (id) {
      case 'crseChange':
        isValid = /^(?:|0|[1-9][0-9]?|1[0-7][0-9]|180)$/.test(newValue);
        oldValue = displayedIntentions.crseChange;
        break;

      case 'speedChange':
        isValid = /^(?!0\d)[0-9]\d{0,4}$|^$/.test(newValue);
        oldValue = displayedIntentions.speedChange;
        break;
    }
    if (isValid) {
      setDisplayedIntentions({
        ...displayedIntentions,
        [id]: event.target.value
      });
    }
  };

  const handleIntentionsNumberFieldBlur = (value: any, field: any) => {
    // console.log(`User left the text field ${field}`);
    // cast the field to a number
    const numValue = Number(value);
    // if the value is not a number, set it to 0
    setDisplayedIntentions({
      ...displayedIntentions,
      [field]: isNaN(numValue) || value === '' ? '' : numValue
    });
  };

  const handleIntentionsAddRereport = () => {
    const newRereport = makeDefaultRereport();
    const updatedRereports = displayedIntentions.rereports.concat([
      newRereport
    ]);
    setDisplayedIntentions({
      ...displayedIntentions,
      rereports: updatedRereports
    });
  };

  const handleIntentionsDeleteRereport = (index: number) => () => {
    // remove the rereport at the given index
    const updatedRereports = displayedIntentions.rereports.filter(
      (_, i) => i !== index
    );
    setDisplayedIntentions({
      ...displayedIntentions,
      rereports: updatedRereports
    });
  };

  const handleRereportContactSelectionChange = (event: any, index: number) => {
    const updatedRereports = displayedIntentions.rereports.map(
      (rereport, i) => {
        if (i === index) {
          return {
            ...rereport,
            contact: event.target.value
          };
        }
        return rereport;
      }
    );
    setDisplayedIntentions({
      ...displayedIntentions,
      rereports: updatedRereports
    });
  };

  // allow a positive number with at most 1 decimal point
  const handleIntentionsRereportNumberFieldChange = (
    event: any,
    index: number
  ) => {
    const newValue = event.target.value;
    console.log('newValue: ' + newValue);

    const isValid = /^(?:\d{1,5}(\.\d{0,1})?)?$/.test(newValue);
    console.log('isValid: ' + isValid);

    // update the agreement at field at the given index
    const updatedRereports = displayedIntentions.rereports.map(
      (rereport, i) => {
        if (i === index) {
          return {
            ...rereport,
            at: isValid ? event.target.value : rereport.at
          };
        }
        return rereport;
      }
    );
    setDisplayedIntentions({
      ...displayedIntentions,
      rereports: updatedRereports
    });
  };

  const handleIntentionsRereportNumberFieldBlur = (
    value: any,
    index: number
  ) => {
    // cast the field to a number
    const numValue = Number(value);
    const isValid = !isNaN(numValue);
    console.log(
      `User left rereport index ${index}, value ${value}, isValid ${isValid}`
    );
    // update the agreement at field at the given index
    const updatedRereports = displayedIntentions.rereports.map(
      (rereport, i) => {
        if (i === index) {
          return {
            ...rereport,
            at: isValid && value !== '' ? numValue : ''
          };
        }
        return rereport;
      }
    );
    setDisplayedIntentions({
      ...displayedIntentions,
      rereports: updatedRereports
    });
  };

  const handleIntentionsAddAgreement = () => {
    const newAgreement = makeDefaultAgreement();
    const updatedAgreements = displayedIntentions.agreements.concat([
      newAgreement
    ]);
    setDisplayedIntentions({
      ...displayedIntentions,
      agreements: updatedAgreements
    });
  };

  const handleIntentionsDeleteAgreement = (index: number) => () => {
    // remove the agreement at the given index
    const updatedAgreements = displayedIntentions.agreements.filter(
      (_, i) => i !== index
    );
    // console.log(updatedAgreements);
    setDisplayedIntentions({
      ...displayedIntentions,
      agreements: updatedAgreements
    });
  };

  const handleAgreementContactSelectionChange = (
    event: any,
    agreement: Agreement
  ) => {
    agreement.contact = event.target.value;
    const newAgreements = displayedIntentions.agreements.map((a) =>
      a === agreement ? agreement : a
    );
    setDisplayedIntentions({
      ...displayedIntentions,
      agreements: newAgreements
    });
  };

  const handleIntentionsAgreementNumberFieldChange = (
    event: any,
    index: number
  ) => {
    const newValue = event.target.value;
    console.log('newValue: ' + newValue);

    const isValid = /^(?:\d{1,5}(\.\d{0,1})?)?$/.test(newValue);
    console.log('isValid: ' + isValid);

    // update the agreement noCloserThan field at the given index
    const updatedAgreements = displayedIntentions.agreements.map(
      (agreement, i) => {
        if (i === index) {
          return {
            ...agreement,
            noCloserThan: isValid ? event.target.value : agreement.noCloserThan
          };
        }
        return agreement;
      }
    );
    // console.log(updatedAgreements);
    setDisplayedIntentions({
      ...displayedIntentions,
      agreements: updatedAgreements
    });
  };

  const handleIntentionsAgreementNumberFieldBlur = (
    value: any,
    index: number
  ) => {
    console.log(`User left pass no closer than agreement index ${index}`);
    // cast the field to a number
    const numValue = Number(value);
    const isValid = !isNaN(numValue);
    // update the agreement noCloserThan field at the given index
    const updatedAgreements = displayedIntentions.agreements.map(
      (agreement, i) => {
        if (i === index) {
          return {
            ...agreement,
            noCloserThan: isValid && value !== '' ? numValue : ''
          };
        }
        return agreement;
      }
    );
    // console.log(updatedAgreements);
    setDisplayedIntentions({
      ...displayedIntentions,
      agreements: updatedAgreements
    });
  };

  const handleSubmit = () => {
    //generate an answer object and submit it to the server
    //Check if saved reports has a report
    if (savedReports.length > 0) {
      const answer = makeScenarioAnswer(displayedIntentions, savedReports);
      submitAnswer(answer);
    } else setOpenNoReportsAlert(true);
  };

  // stepper handlers
  const handleNextWithConfirmation = () => {
    if (unsavedReport) {
      setOpenStageProgressionAlert(true);
    } else {
      handleNext();
    }
  };

  const handleNext = () => {
    handleSaveReport();
    setOpenStageProgressionAlert(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepWithConfirmation = (step: number) => () => {
    if (unsavedReport) {
      setOpenStageProgressionAlert(true);
    } else {
      handleStep(step)();
    }
  };

  const handleStep = (step: number) => () => {
    handleSaveReport();
    setOpenStageProgressionAlert(false);
    setActiveStep(step);
  };

  const handleStageProgressionAlertClose = () => {
    setOpenStageProgressionAlert(false);
  };

  interface RuleListProps {
    rules: Rule[];
    selectedOnly: boolean;
  }

  const handleSaveReport = () => {
    if (!unsavedReport) return;
    // save report to reportedTargets if targetShip is not in reportedTargets
    if (targetShip.length > 0) {
      if (!savedReports.map((i) => i.target).includes(targetShip)) {
        setSavedReports(
          [
            ...savedReports,
            {
              target: targetShip,
              rules: checkedRules,
              details: structuredClone(displayedReportDetails)
            }
          ].sort((a, b) => a.target.localeCompare(b.target))
        );
      } else {
        const updatedReport = savedReports.map((i) => {
          if (i.target === targetShip) {
            return {
              ...i,
              rules: checkedRules,
              details: structuredClone(displayedReportDetails)
            };
          }
          return i;
        });
        setSavedReports(updatedReport);
      }
    }
    setUnsavedReport(false);
  };

  const handleDeleteSavedReportWithConfirmation = (target: string) => () => {
    // if the target is in the intention's rereport or no closer than list, pop up a confirmation dialog
    if (
      displayedIntentions.rereports.map((i) => i.contact).includes(target) ||
      displayedIntentions.agreements.map((i) => i.contact).includes(target)
    ) {
      setOpenDeleteReportAlert(true);
      setShipToDelete(target);
    } else {
      handleDeleteSavedReport(target)();
    }

    if (target === targetShip) {
      return;
    }
  };

  const handleDeleteSavedReport = (target: string) => () => {
    const updatedReports = savedReports.filter((i) => i.target !== target);
    // also delete rereport and agreement if they are in the deleted report
    const updatedRereports = displayedIntentions.rereports.filter(
      (i) => i.contact !== target
    );
    const updatedAgreements = displayedIntentions.agreements.filter(
      (i) => i.contact !== target
    );
    setDisplayedIntentions({
      ...displayedIntentions,
      rereports: updatedRereports,
      agreements: updatedAgreements
    });
    setSavedReports(updatedReports);
    setOpenDeleteReportAlert(false);

    // if the deleted report is the current target, reset the report
    if (target === targetShip) {
      setDisplayedRules(rules);
      setCheckedRules([]);
      setDisplayedReportDetails(makeDefaultReportDetails(false));
      setExpandReportDetails(false);
      setExpandApplyRules(false);
      setUnsavedReport(false);
      setTargetShip('');
    }
  };

  const handleDeleteReportAlertClose = () => {
    setOpenDeleteReportAlert(false);
  };
  const handleNoReportsAlertClose = () => {
    setOpenNoReportsAlert(false);
  };

  const handleLoadSavedReportWithConfirmation = (target: string) => () => {
    console.log('handleLoadSavedReportWithConfirmation', target);
    if (target === targetShip) {
      return;
    }
    if (unsavedReport) {
      setNewTarget(target);
      setOpenSaveAlert(true);
    } else {
      handleLoadNewOrSavedReport(target)();
    }
  };

  const handleLoadNewOrSavedReport = (target: string) => () => {
    setOpenSaveAlert(false);
    setTargetShip(target);
    // if target is in savedReports, load it
    console.log('handleLoadNewOrSavedReport', target);
    console.log('savedReports', savedReports);
    if (!savedReports.map((i) => i.target).includes(target)) {
      setDisplayedRules(rules);
      setCheckedRules([]);
      setDisplayedReportDetails(makeDefaultReportDetails(false));
      setExpandReportDetails(true);
      setExpandApplyRules(true);
      setUnsavedReport(false);
      return;
    }
    const report = savedReports.filter((i) => i.target === target)[0];
    console.log('report', report);
    var updatedRules = rules;
    report.rules.forEach((i) => {
      updatedRules = updateRules(updatedRules, true, i);
    });
    setDisplayedRules(updatedRules);
    setCheckedRules(report.rules);
    console.log('setDisplayedReportDetails', report.details);
    setDisplayedReportDetails(structuredClone(report.details));
    setExpandReportDetails(true);
    setExpandApplyRules(true);
    setUnsavedReport(false);
  };

  const handleTargetShipChange = (event: any) => {
    console.log('handleTargetShipChange', event.target.value);
    const newTarget = event.target.value;
    if (newTarget === targetShip) {
      return;
    }
    if (unsavedReport) {
      setNewTarget(newTarget);
      setOpenSaveAlert(true);
    } else {
      handleLoadNewOrSavedReport(newTarget)();
    }
  };

  const handleRuleCheckboxClick = (fullyQualifiedId: string) => () => {
    const updatedRules = updateRules(displayedRules, true, fullyQualifiedId);
    setDisplayedRules(updatedRules);
    setUnsavedReport(true);
  };
  const RenderRuleList = ({ rules, selectedOnly }: RuleListProps) => {
    const thing = true;
    return (
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          position: 'sticky'
        }}
        dense={true}
        component='nav'
        aria-labelledby='nested-list-subheader'
        //   subheader={
        //     <ListSubheader component="div" id="nested-list-subheader">
        //       Nested List Items
        //     </ListSubheader>
        //   }
      >
        {selectedOnly
          ? displayedRules.map((rule) => <RenderCheckedRule rule={rule} />)
          : displayedRules.map((rule) => <RenderRule rule={rule} />)}
      </List>
    );
  };

  // return target ship title
  const AccordianShipTitle = () => {
    return (
      <>
        {targetShip.length > 0 ? (
          <Typography sx={{ color: 'text.secondary' }}>
            Target {targetShip}
          </Typography>
        ) : (
          <Typography sx={{ color: 'red' }}>Select Target Ship</Typography>
        )}
      </>
    );
  };

  interface RenderRuleProps {
    rule: Rule;
  }
  const RenderRule = ({ rule }: RenderRuleProps) => {
    return (
      <>
        <ListItem
          key={rule.fullyQualifiedId}
          secondaryAction={
            <Checkbox
              edge='end'
              onChange={handleRuleCheckboxClick(rule.fullyQualifiedId)}
              checked={rule.checked}
              inputProps={{
                'aria-labelledby': `checkbox-list-secondary-label-${rule.rule}`
              }}
            />
          }
          disablePadding
        >
          <ListItemButton onClick={handleRuleExpandClick(rule)}>
            <ListItemText primary={rule.shortId + ' ' + rule.rule} />
            {rule.subrules.length > 0 ? (
              rule.expanded ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : (
              <></>
            )}
          </ListItemButton>
        </ListItem>
        {rule.subrules.length > 0 ? (
          <Collapse in={rule.expanded} timeout='auto' unmountOnExit>
            <List component='div' disablePadding sx={{ pl: 2 }} dense={true}>
              {rule.subrules.map((subrule) => (
                <RenderRule rule={subrule} />
              ))}
            </List>
          </Collapse>
        ) : (
          <></>
        )}
      </>
    );
  };

  const RenderCheckedRule = ({ rule }: RenderRuleProps) => {
    return (
      <>
        {rule.checked ? (
          <ListItem key={rule.fullyQualifiedId} disablePadding>
            <ListItemButton onClick={handleRuleExpandClick(rule)}>
              <ListItemText
                primary={rule.fullyQualifiedId + '. ' + rule.rule}
              />
            </ListItemButton>
          </ListItem>
        ) : (
          <></>
        )}
        {rule.subrules.length > 0 ? (
          <List component='div' disablePadding dense={true}>
            {rule.subrules.map((subrule) => (
              <RenderCheckedRule rule={subrule} />
            ))}
          </List>
        ) : (
          <></>
        )}
      </>
    );
  };

  const updateRules = (
    rules: Rule[],
    updateCheck: boolean,
    fullyQualifiedId: string
  ) => {
    const updatedRules = rules.map((i) => {
      var updatedRule = { ...i };
      if (i.fullyQualifiedId === fullyQualifiedId) {
        if (updateCheck) {
          updatedRule = { ...i, checked: !i.checked };
          if (updatedRule.checked) {
            //add updatedRule.fullyQualifiedId to checkedRules if it's not already there
            if (!checkedRules.includes(updatedRule.fullyQualifiedId)) {
              setCheckedRules([...checkedRules, updatedRule.fullyQualifiedId]);
            }
          } else {
            //removed upatedRule.fullyQualifiedId from checkedRules
            const updatedCheckedRules = checkedRules.filter(
              (i) => i !== updatedRule.fullyQualifiedId
            );
            setCheckedRules(updatedCheckedRules);
          }
        } else {
          updatedRule = { ...i, expanded: !i.expanded };
        }
        // console.log(updatedRule);
        return updatedRule;
      }
      if (updatedRule.subrules.length > 0) {
        updatedRule.subrules = updateRules(
          updatedRule.subrules,
          updateCheck,
          fullyQualifiedId
        );
      }

      return updatedRule;
    });
    // console.log(updatedRules);
    return updatedRules;
  };

  const handleRuleExpandClick = (rule: Rule) => () => {
    const updatedRules = updateRules(
      displayedRules,
      false,
      rule.fullyQualifiedId
    );
    setDisplayedRules(updatedRules);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  // Reset all answers, rules, intentions if the scenario ID changes
  useEffect(() => {
    setCheckedRules([]);
    setDisplayedReportDetails(makeDefaultReportDetails(true));
    setDisplayedRules(rules);
    setDisplayedIntentions(makeDefaultIntentions());
    setSavedReports([]);
    setExpandApplyRules(false);
    setExpandReportDetails(false);
    setTargetShip('');
    setActiveStep(0);
    setUnsavedReport(false);
  }, [scenarioId]);

  const steps = ['Reports', 'Intentions'];
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            minHeight: '100%',
            // maxHeight: "100%",
            top: '3vh'
          }
        }}
        onClose={handleClose}
        open={openDialog}
        // fullWidth={true}
        fullScreen={fullScreen}
        maxWidth='lg'
        sx={{ height: 'calc(97vh - 30px)' }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <span>
              Report Form ({activeStep + 1}/{steps.length})
            </span>
            <Box gap={2}>
              <CloseIcon onClick={handleClose} />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{}} dividers>
          {activeStep === 0 ? (
            <Box
              width='100%'
              display='flex'
              // flexDirection="column"
              // justifyContent="center"
              // alignItems="center"
              // bgcolor={"red"} //debug colour
              overflow={'auto'}
              padding={0} // contents take up full box
              // sx={{ height: "calc((80vh - 10px)/2)" }}
              sx={{ height: 'calc(97vh - 200px)' }} // 250px is the height of the dialog title and the stepper
            >
              <Grid
                container
                spacing={0}
                alignContent={smallScreen ? 'flex-start' : 'normal'}
              >
                {/* outer most grid container*/}
                <Grid //left panel
                  item
                  xs={12}
                  md={4}
                  textAlign={'left'}
                  padding={0.5}
                  // bgcolor={"green"} //debug colour
                >
                  <Box
                    overflow={'auto'}
                    maxHeight={'100%'}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Box
                      paddingBottom={2}
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                    >
                      <h4>Target selection</h4>
                      <Box paddingX={5} paddingTop={0} minWidth={'200px'}>
                        <FormControl fullWidth>
                          <InputLabel id='target-select-label'>
                            Target
                          </InputLabel>
                          <Select
                            labelId='target-select-label'
                            id='target-select'
                            value={targetShip}
                            label='Target'
                            onChange={handleTargetShipChange}
                          >
                            {targets.map((ship) => {
                              return (
                                <MenuItem value={ship} dense>
                                  {ship}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box>
                      <Divider sx={{ bgcolor: 'primary.light' }} />
                      <Typography paddingTop={1} fontWeight={'bold'}>
                        Reported Targets
                      </Typography>
                      <Box
                        paddingRight={5}
                        paddingTop={0}
                        overflow={'auto'}
                        maxHeight={'100%'}
                        //   bgcolor={"red"} //debug colour
                      >
                        <List dense={true}>
                          {savedReports.map((savedReport) => {
                            return (
                              <>
                                <ListItem
                                  secondaryAction={
                                    <IconButton
                                      edge='end'
                                      aria-label='delete'
                                      onClick={handleDeleteSavedReportWithConfirmation(
                                        savedReport.target
                                      )}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar>
                                      <DirectionsBoatIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemButton
                                    onClick={handleLoadSavedReportWithConfirmation(
                                      savedReport.target
                                    )}
                                    selected={savedReport.target === targetShip}
                                  >
                                    <ListItemText
                                      primary={savedReport.target}
                                      //   secondary={true ? "Secondary text" : null}
                                    />
                                  </ListItemButton>
                                </ListItem>
                                <Divider variant='inset' />
                              </>
                            );
                          })}
                        </List>
                      </Box>
                      {smallScreen ? (
                        <Divider
                          orientation='horizontal'
                          flexItem
                          sx={{ bgcolor: 'primary.light' }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                </Grid>
                <Grid // right panel
                  item
                  xs={12}
                  md={8}
                  textAlign={'left'}
                  // bgcolor={"blue"} //debug colour
                  display={'flex'}
                  flexDirection={'row'}
                  margin={0}
                  padding={0}
                  sx={{ flex: 1 }}
                >
                  {smallScreen ? null : (
                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{ bgcolor: 'primary.light' }}
                    />
                  )}
                  {/* box takes up rest of horizontal space */}
                  <Box
                    marginLeft={0}
                    marginTop={1}
                    padding={1}
                    width={'100%'}
                    //   bgcolor={"lightseagreen"} //debug colour
                  >
                    {' '}
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'flex-end'}
                      marginTop={2}
                      marginBottom={2}
                    >
                      <Button
                        variant='contained'
                        onClick={handleSaveReport}
                        disabled={!unsavedReport}
                      >
                        Save Report
                      </Button>
                    </Box>
                    <Accordion
                      disabled={targetShip.length == 0}
                      expanded={expandReportDetails}
                      onChange={handleReportDetailsExpandChange}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography
                          sx={{ width: '33%', flexShrink: 0 }}
                          fontWeight={'bold'}
                        >
                          Report Details
                        </Typography>
                        <AccordianShipTitle />
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <SpeechToText
                          onUpdateReportDetails={handleSpeechToTextData}
                          resVis={resVis}
                        /> */}

                        <ReportDetailsPanel
                          reportDetails={displayedReportDetails}
                          resVis={resVis}
                          numberFieldChangeHandler={
                            handleReportDetailsNumberFieldChange
                          }
                          numberFieldBlurHandler={
                            handleReportDetailsNumberFieldBlur
                          }
                          selectionChangeHandler={
                            handleReportDetailsSelectionChange
                          }
                          checkboxChangeHandler={
                            handleReportDetailsCheckboxClick
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disabled={targetShip.length == 0}
                      expanded={expandApplyRules}
                      onChange={handleApplyRulesExpandChange}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Typography
                          sx={{ width: '33%', flexShrink: 0 }}
                          fontWeight={'bold'}
                        >
                          Apply rules
                        </Typography>
                        <AccordianShipTitle />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          spacing={1}
                          marginTop={-2}
                          //   bgcolor={"lightcoral"} //debug background colour
                        >
                          <Grid item xs={12} md={6}>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              padding={1}
                            >
                              <Typography
                                variant='subtitle2'
                                gutterBottom
                                fontWeight={'bold'}
                              >
                                Select rules to apply
                              </Typography>
                              <Paper>
                                <RenderRuleList
                                  rules={displayedRules}
                                  selectedOnly={false}
                                />
                              </Paper>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              padding={1}
                            >
                              <Typography
                                variant='subtitle2'
                                gutterBottom
                                fontWeight={'bold'}
                              >
                                Selected rules
                              </Typography>
                              <Paper>
                                <Box bgcolor={'lightgray'}>
                                  <RenderRuleList
                                    rules={displayedRules}
                                    selectedOnly={true}
                                  />
                                </Box>
                              </Paper>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {/* a right aligned button */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : null}
          {activeStep === 1 ? (
            <IntentionsPanel
              displayedIntentions={displayedIntentions}
              savedReports={savedReports}
              targets={targets}
              handleIntentionsSelectionChange={handleIntentionsSelectionChange}
              handleIntentionsNumberFieldChange={
                handleIntentionsNumberFieldChange
              }
              handleIntentionsNumberFieldBlur={handleIntentionsNumberFieldBlur}
              handleAddRereport={handleIntentionsAddRereport}
              handleDeleteRereport={handleIntentionsDeleteRereport}
              handleIntentionsRereportNumberFieldChange={
                handleIntentionsRereportNumberFieldChange
              }
              handleIntentionsRereportNumberFieldBlur={
                handleIntentionsRereportNumberFieldBlur
              }
              handleRereportContactSelectionChange={
                handleRereportContactSelectionChange
              }
              handleIntentionsAgreementNumberFieldChange={
                handleIntentionsAgreementNumberFieldChange
              }
              handleIntentionsAgreementNumberFieldBlur={
                handleIntentionsAgreementNumberFieldBlur
              }
              handleAgreementContactSelectionChange={
                handleAgreementContactSelectionChange
              }
              handleAddAgreement={handleIntentionsAddAgreement}
              handleDeleteAgreement={handleIntentionsDeleteAgreement}
              handleSubmit={handleSubmit}
            />
          ) : null}
          <Box
            width='100%'
            height='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            // bgcolor={"gray"} //debug colour
            sx={{ height: '60px' }}
          >
            {/* the bottom of the answers dialog with the stepper component */}
            <Grid container spacing={2} marginX={0} marginTop={-1}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden' // hide overflow content
                }}
              >
                {/* iconbutton ArrowBackIosIcon */}
                <IconButton
                  onClick={handleBack}
                  color='primary'
                  disabled={activeStep === 0}
                  aria-label='back'
                  sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }} // smaller icon on xs screens
                >
                  <ArrowBackIosIcon />
                </IconButton>

                {smallScreen ? null : (
                  <Button
                    variant='text'
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  ></Button>
                )}

                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  sx={{
                    width: { xs: '100%', sm: 500 }, // make it flexible based on the screen width
                    '.MuiStepLabel-label': {
                      fontSize: { xs: '0.8rem', sm: '1rem' } // adjust font size of the stepper labels
                    }
                  }}
                >
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepButton
                          color='inherit'
                          onClick={handleStepWithConfirmation(index)}
                        >
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>

                {/* iconbutton ArrowForwardIosIcon */}
                {smallScreen ? null : (
                  <Button
                    variant='text'
                    onClick={handleNextWithConfirmation}
                    disabled={activeStep === steps.length - 1}
                  ></Button>
                )}

                <IconButton
                  onClick={handleNextWithConfirmation}
                  color='primary'
                  disabled={activeStep === steps.length - 1}
                  aria-label='next'
                  sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }} // smaller icon on xs screens
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <AlertDialogSlide
        handleClickProceed={handleLoadNewOrSavedReport(newTarget)}
        handleClose={handleSaveAlertClose}
        open={openSaveAlert}
        targetShip={targetShip}
        newTargetShip={newTarget}
      />
      <DeleteReportAlertDialog
        handleClickProceed={handleDeleteSavedReport(shipToDelete)}
        handleClose={handleDeleteReportAlertClose}
        open={openDeleteReportAlert}
        targetShip={shipToDelete}
      />
      <StageProgressionAlertDialog
        handleClickProceed={handleNext}
        handleClose={handleStageProgressionAlertClose}
        open={openStageProgressionAlert}
        targetShip={targetShip}
      />
      <NoReportsAlertDialog
        handleClose={handleNoReportsAlertClose}
        open={openNoReportsAlert}
        handleClickReportForm={() => {
          handleBack();
          handleNoReportsAlertClose();
        }}
      />
    </>
  );
};

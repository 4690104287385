import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';

import IFrame from './components/iframe/iframe';
import Quiz from './components/game/game-play/Quiz';
import BeginStep from './components/game/game-play/BeginStep';
import Quizpath from './components/game/game-play/ShakeDownHome';
import GameplayFAQ from './components/game/GameplayFAQ';
import GameFormatPage from './components/game/GameExplanation';
import Nav from './components/header/nav';
import Drawing from './components/drawing/drawing';
import Home from './components/home/Home';
import SignInCallback from './components/sign-in-callback/sign-in-callback';
import { Amplify, Auth, Hub } from 'aws-amplify';
import Footer from './components/footer/footer';
import Practice from './components/practice/practice';
import RecitePractice from './components/recite-practice/RecitePracticeV2';
import Feedback from './components/feedback/Feedback';
import FeedbackSummary from './components/feedbacks/feedbackSummary';
import Help from './components/help/Help';
import Profile from './components/user/profile';
import Instructor from './components/instructor/Instructor';
import * as amplitude from '@amplitude/analytics-browser';
import { Experiment as AmplitudeExperiment } from '@amplitude/experiment-js-client';
import Hotjar from '@hotjar/browser';
import Tasks from './components/tasks/Tasks';
import Administrator from './components/admin/Administrator';
import ProtectedRoute from './components/common/ProtectedRoute';
import ShakeDownHome from './components/game/game-play/ShakeDownHome';
import GameOver from './components/game/game-play/GameOver';
import ScoreBoardDisplay from './components/game/game-play/ScoreBoardDisplay';
import ProgressTracker from './components/game/ProgressTracker';

// https://www.thelambdablog.com/using-the-cognito-hosted-ui-for-authentication-with-the-amplify-client-side-library-in-react/
Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_OATH_DOMAIN || '',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: process.env.REACT_APP_SIGNIN_CALLBACK || '',
      redirectSignOut: process.env.REACT_APP_SIGNOUT_CALLBACK || '',
      responseType: 'code'
    }
  }
});

function App() {
  const [reciteFeatureFlag, setReciteFeatureFlag] = useState(false);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          // signin actions
          Auth.currentSession().then((user) => console.log(user)); // redirect to default page

          break;
        case 'cognitoHostedUI':
          console.log('Authenticated...');

          break;
        case 'signIn_failure':
          console.log('Error - signIn_failure', data);
          break;
        case 'cognitoHostedUI_failure':
          console.log('Error - cognitoHostedUI_failure', data);
          break;
      }
    });
    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || '');
      Auth.currentAuthenticatedUser().then((user) => {
        // AMPLITUDE
        amplitude.setUserId(user.username);
        // Initialize the experiment client with Amplitude Analytics.
        if (
          process.env.REACT_APP_AMPLITUDE_DEPLOYMENT_KEY &&
          process.env.REACT_APP_AMPLITUDE_FLAG_KEY
        ) {
          let experimentClient: any = AmplitudeExperiment.initialize(
            process.env.REACT_APP_AMPLITUDE_DEPLOYMENT_KEY || ''
          );

          const expUser = {
            user_id: user.username
          };

          experimentClient.fetch(expUser).then(() => {
            const flagKey = process.env.REACT_APP_AMPLITUDE_FLAG_KEY || '';
            const variant = experimentClient.variant(flagKey);
            if (variant.value === 'on') setReciteFeatureFlag(true);
            else setReciteFeatureFlag(false);
          });
        }
        if (process.env.REACT_APP_HOTJAR_ID) {
          // HOTJAR
          const siteId = parseInt(process.env.REACT_APP_HOTJAR_ID || '');
          const hotjarVersion = parseInt(
            process.env.REACT_APP_HOTJAR_VERSION || ''
          );
          Hotjar.init(siteId, hotjarVersion);
          Hotjar.identify(user.username, {});
        }
      });
    }
  }, []);

  function Navigation() {
    const location = useLocation();
    const parts = location.pathname.split('/').filter(Boolean);
    const firstPart = parts[0];
    return firstPart === 'games' ? null : (
      <Nav reciteFeatureFlag={reciteFeatureFlag} />
    );
  }
  return (
    <div className='App'>
      <BrowserRouter>
        <Navigation />

        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/home' Component={Home} />
          <Route
            path='games/shake-down/quiz/:stepId'
            element={
              <ProtectedRoute>
                <Quiz />
              </ProtectedRoute>
            }
          />
          <Route
            path='games/shake-down/tracker'
            element={
              <ProtectedRoute>
                <ProgressTracker />
              </ProtectedRoute>
            }
          />
          <Route
            path='/games/shake-down/steps/:stepId/intro'
            element={
              <ProtectedRoute>
                <BeginStep />
              </ProtectedRoute>
            }
          />
          <Route
            path='/shake-down/faq'
            element={
              <ProtectedRoute>
                <GameplayFAQ />
              </ProtectedRoute>
            }
          />
          <Route
            path='/shake-down/explanation'
            element={
              <ProtectedRoute>
                <GameFormatPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/games/shake-down/game-over/:attemptId'
            element={
              <ProtectedRoute>
                <GameOver />
              </ProtectedRoute>
            }
          />

          <Route
            path='/games/shake-down/scoreboard/:stepId'
            element={
              <ProtectedRoute>
                <ScoreBoardDisplay />
              </ProtectedRoute>
            }
          />

          <Route
            path='/practice'
            element={
              <ProtectedRoute>
                <Practice />
              </ProtectedRoute>
            }
          />
          <Route
            path='/practice/recite/:ruleId?/:subRuleId?'
            element={
              <ProtectedRoute>
                <RecitePractice />
              </ProtectedRoute>
            }
          />
          <Route
            path='/games/shake-down'
            element={
              <ProtectedRoute>
                <ShakeDownHome />
              </ProtectedRoute>
            }
          />
          <Route
            path='/practice/:paramID?/:trafficLvl?/:env?'
            Component={Practice}
          />
          <Route
            path='/feedback/:id'
            element={
              <ProtectedRoute>
                <FeedbackSummary />
              </ProtectedRoute>
            }
          />
          <Route
            path='/feedback'
            element={
              <ProtectedRoute>
                <FeedbackSummary />
              </ProtectedRoute>
            }
          />
          <Route
            path='/tasks'
            element={
              <ProtectedRoute>
                <Tasks />
              </ProtectedRoute>
            }
          />
          <Route
            path='/instructor'
            element={
              <ProtectedRoute>
                <Instructor />
              </ProtectedRoute>
            }
          />
          <Route
            path='/administrator'
            element={
              <ProtectedRoute>
                <Administrator />
              </ProtectedRoute>
            }
          />
          <Route path='/help' Component={Help} />
          <Route path='/callback' Component={SignInCallback} />
          <Route
            path='/profile'
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

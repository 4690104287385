import React from "react";

const Footer = () => {
  return (
    <footer
      // style={{
      //   position: "fixed",
      //   left: 0,
      //   bottom: 0,
      //   width: "100%",
      //   backgroundColor: "#f8f8f8",
      //   padding: "10px 0",
      // }}
      className="footer"
    >
      <div className="container">
        <p>&copy; {new Date().getFullYear()} Navity</p>
      </div>
    </footer>
  );
};

export default Footer;

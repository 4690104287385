export type ReportDetails = {
  relColour?: string;
  relBearingCont?: number | string;
  range?: number | string;
  typeVis?: string;
  visual: boolean;
  radar: boolean;
  aspectBearing?: number | string;
  aspectSide?: string;
  bearingDirection?: string;
  cpa?: number | string;
  cpaPos?: string;
  tcpa?: number | string;
};

export type Agreement = {
  contact?: string;
  noCloserThan?: number | string;
};

export type Rereport = {
  contact?: string;
  at?: number | string;
};

export type Intentions = {
  alterCrse?: string;
  crseChange?: number | string;
  alterSpeed?: string;
  speedChange?: number | string;
  rereports: Rereport[];
  agreements: Agreement[];
};

export type SavedReport = {
  target: string;
  rules: string[];
  details: ReportDetails;
};

export type ScenarioAnswer = {
  intentions: Intentions;
  reports: SavedReport[];
};

export const relColourValues = ['green', 'red', "ship's head", 'astern'];

export const typeVisValues = [
  'PDV',
  'SV',
  'VEIF',
  'CBD',
  'RAM',
  'NUC',
  'unknown',
];

export const vesselTypeMappings = {
  PDV: 'power driven vessel',
  SV: 'sailing vessel',
  VEIF: 'vessel engaged in fishing',
  CBD: 'vessel constrained by her draught',
  RAM: 'restricted in her ability to manoeuvre',
  NUC: 'not under command',
};
export const vesselTypeResVisMappings = {
  'PDV - making way': 'power driven vessel making way',
  'PDV - underway not making way':
    'power driven vessel underway not making way',
  'NUC,RAM,CBD,SV,VEIF,Towing/Pushing': 'lame duck',
  'Anchored less than 100M': 'anchored less than 100 meters',
  'Anchored more than 100M': 'anchored more than 100 meters',
  Aground: 'aground',
  'Pilot vessel': 'pilot vessel',
};
export const typeResVisValues = [
  'PDV making way',
  'PDV underway not making way',
  'NUC, RAM, CBD, SV, VEIF, Towing or Pushing',
  'Anchored less than 100M',
  'Anchored more than 100M',
  'Aground',
  'Pilot vessel',
  'Unknown',
];

export const aspectSideValues = ['port', 'starboard', "ship's head", 'stern'];

export const cpaPosValues = [
  "ship's head",
  'port bow',
  'starboard bow',
  'port beam',
  'starboard beam',
  'port quarter',
  'starboard quarter',
  'stern',
];

export const bearingDirectionValues = ['steady', 'left', 'right'];

export const alterCrseValues = ['maintain', 'port', 'stb'];

export const alterSpeedValues = ['maintain', 'increase', 'decrease'];

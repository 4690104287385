import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LeaderboardTwoToneIcon from '@mui/icons-material/LeaderboardTwoTone';

interface HighestLeaderBoardPositionProps {
  position: number | null;
}

const HighestLeaderBoardPosition: React.FC<HighestLeaderBoardPositionProps> = ({
  position
}) => {
  if (position !== null && position > 0) {
    return (
      <div
        style={{ display: 'flex', alignItems: 'flex-end', marginLeft: '10px' }}
      >
        <Tooltip title='Highest Leaderboard Position' placement='top'>
          <IconButton>
            <LeaderboardTwoToneIcon
              sx={{
                color: '#7F63E0',
                fontSize: '1em',
                lineHeight: '0px'
              }}
            />
          </IconButton>
        </Tooltip>
        <span
          style={{
            lineHeight: '40px',
            fontSize: '1em',
            textWrap: 'nowrap'
          }}
        >
          <strong>{position}#</strong>
        </span>
      </div>
    );
  } else {
    return null;
  }
};

export default HighestLeaderBoardPosition;

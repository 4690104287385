import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Collapse,
  IconButton,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { create } from "domain";
import { isFocusable } from "@testing-library/user-event/dist/utils";

interface Props {
  action_course?: alterCrseObject;
  action_speed?: alterSpeedObject;
}

interface alterCrseObject {
  alterCrse?: string;
  crseChange?: number;
}

interface alterSpeedObject {
  alterSpeed?: string;
  speedChange?: number;
}

const Actions: React.FC<Props> = ({ action_course, action_speed }) => {
  const [actionsString, setActionsString] = useState<string | null>(null);
  const { crseStrng, speedStrng } = createActionStrings(
    action_course,
    action_speed
  );

  function createActionStrings(
    action_course?: alterCrseObject | null,
    action_speed?: alterSpeedObject | null
  ): { crseStrng: string; speedStrng: string } {
    let crseStrng: string = "";
    let speedStrng: string = "";

    // Speed
    if (
      !action_speed ||
      !action_speed.alterSpeed ||
      ((action_speed.alterSpeed === "increase" ||
        action_speed.alterSpeed === "decrease") &&
        !action_speed.speedChange)
    )
      speedStrng = "not included in report";
    else if (action_speed && action_speed.alterSpeed === "maintain")
      speedStrng = "maintain";
    else if (
      action_speed &&
      action_speed.speedChange &&
      (action_speed.alterSpeed === "increase" ||
        action_speed.alterSpeed === "decrease")
    )
      speedStrng = `${action_speed.alterSpeed
        .charAt(0)
        .toUpperCase()}${action_speed.alterSpeed.slice(1)} by ${
        action_speed.speedChange
      } Kts`;

    // Course
    if (
      !action_course ||
      !action_course.alterCrse ||
      ((action_course.alterCrse === "port" ||
        action_course.alterCrse === "stb") &&
        !action_course.crseChange)
    )
      crseStrng = "not included in report";
    else if (action_course && action_course.alterCrse === "maintain")
      crseStrng = "maintain";
    else if (
      action_course &&
      action_course.crseChange &&
      (action_course.alterCrse === "port" || action_course.alterCrse === "stb")
    )
      crseStrng = `Alter to ${action_course.alterCrse} by ${action_course.crseChange}°`;

    return { crseStrng, speedStrng };
  }
  return (
    <Card
      variant="outlined"
      sx={{ backgroundColor: "#161A1Dff", width: "100%" }}
    >
      <CardContent>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems="center"
        >
          <Grid
            item
            xs={6}
            justifyContent={"center"}
            alignItems={"center"}
            // bgcolor={"yellow"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Typography variant="h6" gutterBottom color={"white"}>
              Course
            </Typography>
            <Typography color={"white"}>{crseStrng}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            justifyContent={"center"}
            alignItems={"center"}
            // bgcolor={"yellow"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Typography variant="h6" gutterBottom color={"white"}>
              Speed
            </Typography>
            <Typography color={"white"}>{speedStrng}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default Actions;

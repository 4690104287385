import React, { useRef, useEffect } from 'react';
import * as paper from 'paper';

interface PaperCanvasProps {
  width: number;
  height: number;
  drawFunction?: (scope: paper.PaperScope) => void;
}

const PaperCanvas: React.FC<PaperCanvasProps> = ({
  width,
  height,
  drawFunction,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const paperScope = useRef<paper.PaperScope | null>(null);

  // Setup canvas only once
  useEffect(() => {
    if (canvasRef.current && !paperScope.current) {
      paperScope.current = new paper.PaperScope();
      paperScope.current.setup(canvasRef.current);
    }
  }, []);

  // Draw on canvas whenever drawFunction changes
  useEffect(() => {
    if (paperScope.current && drawFunction) {
      drawFunction(paperScope.current);
    }
  }, [drawFunction]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ backgroundColor: 'black', borderRadius: '4px' }}
    />
  );
};

export default PaperCanvas;
